import React from 'react';
import { Visibility, VisibilityOff, MoreHoriz } from '@material-ui/icons';
import { Tooltip } from '@material-ui/core';
import { connect } from 'react-redux';
import { Dispatch, bindActionCreators } from 'redux';
import { IRootState, OverlaysActions, TemplateActions, OverlayData, EditorSettingsActions } from '../../../../../../redux';

import './styles/OverlayProps.scss';

export interface OverlayPropsData {
    index:number,           // the index of the overlay inside the array of OverlayArray
    updateOvClasses:any,
    cssHeight: number,
    //-----------------------------------------------------
    template: any,
    editorSettings: any,
    overlaysInScene: Array<OverlayData>,
    currentScene: number,
    //-----------------------------------------------------
    updateTemplate: typeof TemplateActions.actionCreators.updateTemplate,
    updateOverlayJson: typeof TemplateActions.asyncActionCreators.updateOverlay,
    updateOverlay: typeof OverlaysActions.OverlaysActions.updateOverlay,
    overlayToEdit: typeof EditorSettingsActions.EditorSettingsActions.overlayToEdit
}

const mapStateToProps = (state: IRootState) => {
    return {
      template: state.template.present.json,
      overlaysInScene: state.overlaysInScene.current,
      editorSettings: state.editorSettings,
      currentScene: state.template.present.currentScene
    }
}

const mapDispatchToProps = (dispatch:Dispatch): Pick<OverlayPropsData, 'updateTemplate' | 'updateOverlay' | 'updateOverlayJson' | 'overlayToEdit'  >  => ({
    updateTemplate: bindActionCreators(TemplateActions.actionCreators.updateTemplate, dispatch),
    updateOverlayJson: bindActionCreators(TemplateActions.asyncActionCreators.updateOverlay, dispatch),
    updateOverlay: bindActionCreators(OverlaysActions.OverlaysActions.updateOverlay, dispatch),
    overlayToEdit: bindActionCreators(EditorSettingsActions.EditorSettingsActions.overlayToEdit, dispatch),
});


class OverlayProps extends React.Component<OverlayPropsData> {
    state:any;
    changeDetected: boolean;

    constructor(props:any) {
        super(props);
        this.state = {
            currentOverlay: this.props.template.scenes[this.props.currentScene].overlays[this.props.index],
            overlayVisible: true,
            mouseDown: false
        };

        this.changeDetected = false;

        this.toggleOverlay = this.toggleOverlay.bind(this);
        this.editOverlay = this.editOverlay.bind(this);
    }

    componentDidUpdate(prevProps: any) {
        if(this.props.template.scenes[this.props.currentScene].overlays[this.props.index] !== undefined){
            if(prevProps !== this.props) {
                this.setState(() => {
                    return {
                        ...this.state,
                        currentOverlay: this.props.template.scenes[this.props.currentScene].overlays[this.props.index],
                        //overlayVisible: this.props.overlaysInScene[this.props.index].visible       
                    }
                });
            }
        }
    }

    toggleOverlay() {
        // get the value that will toggle the visibility of the overlay
        const ovVisible = !this.state.overlayVisible;
        // we update the visibility of the overlay in the 'currentOverlay' object in the local state
        let c:OverlayData = this.state.currentOverlay;
        c.visible = ovVisible;
        // we dispatch the 'updateOverlay' action simply passing the updated value of the overlay.visible property
        this.props.updateOverlay({
            index: this.props.index,
            visible: ovVisible,
            startPoint: this.state.currentOverlay.start.time,
            endPoint: this.state.currentOverlay.end.time,
            width:c.width
        });

        if(ovVisible) {
            this.props.updateOvClasses('overlay');
        }
        else {
            this.props.updateOvClasses('overlay inactive');
        }

        this.setState(() => {
            return {
                overlayVisible: ovVisible,
                currentOverlay: c
            }
        });
    }

    editOverlay() {
        // here we dispatch the 'editOverlay' action of the 'EditorSettingsActions'
        // passing the index of the overlay to edit
        this.props.overlayToEdit( {
            index: this.props.index, 
            editorVisible: true
        } );
    }

    render() {
        return(
            <>
            {this.props.template.scenes[this.props.currentScene].overlays[this.props.index] ? 
                <div className="overlayProps-container" style={{ height: this.props.cssHeight + 'px' }}>
                    <div className={this.state.overlayVisible ? "overlayProps active" : "overlayProps" }>
                        <div className="overlay-name noselect">
                            <span className="activeOverlayIndicator"></span>
                            {this.props.template.scenes[this.props.currentScene].overlays[this.props.index].content.embeddedId}
                        </div>
                        <Tooltip title="Toggle Visibility" aria-label="ToggleVisibility">
                            <div className="toggleOverlay" onClick={this.toggleOverlay} >
                                {this.state.overlayVisible ?  <Visibility/> :  <VisibilityOff  onMouseDown={this.toggleOverlay}/>}
                            </div>
                        </Tooltip>
                        <Tooltip title="Edit Overlay" aria-label="EditOverlay">
                            <div  className="overlaySettings"onClick={this.editOverlay}>
                                <MoreHoriz />
                            </div>
                        </Tooltip>
                        <div className="empty-right"></div>
                    </div> 
                </div>
            : <></> }
            </>
        );
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(OverlayProps);