import { actionCreatorFactory } from 'typescript-fsa';
import { CampaignInfo } from '../models';

enum Type {
  GetInfo = 'GET_INFO',
  InfoReceived = 'INFO_RECEIVED',
  ResetStore = 'RESET_STORE'
}

const create = actionCreatorFactory('CAMPAIGN');

export const CampaignActions = {
  getInfo: create<CampaignInfo>(Type.GetInfo),
  infoReceived: create<any>(Type.InfoReceived),
  resetStore: create<any>(Type.ResetStore)
}