import React from 'react';

import { Info } from '@material-ui/icons';

import './styles/EditorLegendBtn.scss';

export interface EditorLegendBtnProps {
    visible:boolean,
    showHideLegend:any
}

const EditorLegendBtn = (props:EditorLegendBtnProps) => {
    return (
        <div
            className="editor-legend-btn noselect"
            onClick={props.showHideLegend}
            style={ !props.visible ? { display:'none ' } : { display:'block' } }
        >
            <Info />
        </div>
    )
}

export default EditorLegendBtn;