declare global {
  interface Window {
    REACT_APP_ACCOUNT_API: any;
    REACT_APP_ACCOUNT_CLIENT_ID: any;
    REACT_APP_PLAYER_API: any;
    REACT_APP_CAMPAIGNMANAGER_API: any;
  }
}

let account_api = window.REACT_APP_ACCOUNT_API;
let account_client_id = window.REACT_APP_ACCOUNT_CLIENT_ID;
let player_api = window.REACT_APP_PLAYER_API;
let campaign_manager_api= window.REACT_APP_CAMPAIGNMANAGER_API
export { account_api, account_client_id, player_api, campaign_manager_api };
