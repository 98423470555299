import React from 'react';
import { FastForward, FastRewind, PlayArrow, Pause, VolumeUp, VolumeOff } from '@material-ui/icons';
import './styles/timelineControls.scss';

export interface TimelineControlsProps { 
    currentFrame:number,
    totalFrames: number,
    currentTime:string,
    videoDuration: number,
    goToPreviousFrame:any,
    goToNextFrame:any,
    playVideo:any,
    pauseVideo:any,
    muteVideo: any,
    unmuteVideo: any,
    refreshTopBar:any,
    readyTopBar:boolean
};

export interface TimelineControlsState {
    classes:string,
    playing:boolean
}

class TimelineControls extends React.Component<TimelineControlsProps, TimelineControlsState> {
    state: any;
    //playing:boolean;
    muted:boolean;
    videoTotalDuration:string;

    constructor(props:any){
        super(props);

        // we store the classes that we add or remove when clicking play / pause / mute / unmute
        this.state = {
            classes: 'timeline-controls',
            playing: false
        };
        
        //this.playing = false;
        this.muted = false;

        this.videoTotalDuration = '00:00:00:00';

        this.updateInitialValues = this.updateInitialValues.bind(this);
        this.minTwoDigits = this.minTwoDigits.bind(this);
        this.updateClasses = this.updateClasses.bind(this);
        this.handlePlay = this.handlePlay.bind(this);
        this.handlePause = this.handlePause.bind(this);
        this.handleMute = this.handleMute.bind(this);
        this.handleUnmute = this.handleUnmute.bind(this);
    }

    updateInitialValues() {
        // New format:   0:03:20:02   hours:minutes:seconds:miliseconds
        let hours = Math.floor(this.props.videoDuration / 3600);
        let minutes = Math.floor(this.props.videoDuration / 60);
        let seconds = Math.floor(this.props.videoDuration % 60);
        let miliseconds = Math.floor(((this.props.videoDuration % 60) - seconds) * 100);
        // here we give format to the time that will be displayed accordingly to video.currentTime 
        const newVideoTotalDuration = this.minTwoDigits(hours) + ':' + this.minTwoDigits(minutes) + ':' + this.minTwoDigits(seconds) + ':' + this.minTwoDigits(miliseconds);

        this.videoTotalDuration = newVideoTotalDuration;
        
        this.props.refreshTopBar({ readyTopBar: true });
    }

    componentDidMount() { 
        this.updateInitialValues();
    }

    componentDidUpdate(prevProps:any) {
        if(this.props.currentFrame >= this.props.totalFrames && this.state.playing) {
            //this.playing = false;
            this.setState(() => {
                return {
                    playing: false
                }
            });
        }

        if(!this.props.readyTopBar && (prevProps.readyTopBar === false)) {
            this.updateInitialValues();
        }
    }

    minTwoDigits(n) {
        return (n < 10 ? '0' : '') + n;
    }

    updateClasses(newClasses:any) {        
        this.setState(() => {
            return {
                classes: newClasses
            }
        });
    }

    handlePlay(){
        this.props.playVideo();
        //this.playing = true;
        this.setState(() => {
            return {
                playing:true
            }
        });
    }

    handlePause(){
        
        this.props.pauseVideo();
        //this.playing = false;
        this.setState(() => {
            return {
                playing:false
            }
        });
    }

    handleMute(){
        this.props.muteVideo();
        let newClasses = this.state.classes += ' muted';
        this.updateClasses(newClasses);
        this.muted = true;
    }

    handleUnmute(){
        this.props.unmuteVideo();
        let newClasses = this.state.classes.replace(' muted', '');
        this.updateClasses(newClasses);

        this.muted = false;
    }

    render() {
        return (
            <div className={this.state.classes}>
                {this.state.playing ? 
                    <div className="btn-with-border pauseBtn" onClick={this.handlePause}>
                        <Pause />
                    </div> :
                    <div className="btn-with-border playBtn" onClick={this.handlePlay}>
                        <PlayArrow />
                    </div>
                }
                <div className="btn-with-border rewindBtn" onClick={this.props.goToPreviousFrame}>
                    <FastRewind />
                </div>

                <div className="time-display noselect">
                    <div className="time-left">
                        <span className="frame">{this.props.currentFrame}</span>
                        <span className="time">{this.props.currentTime}</span>
                    </div>
                    <div className="time-right">
                        <span className="frame">{this.props.totalFrames}</span>
                        <span className="time">{this.videoTotalDuration}</span>
                    </div>
                </div>
                
                <div className="btn-with-border forwardBtn" onClick={this.props.goToNextFrame}>
                    <FastForward />
                </div>

                {this.muted ?
                    <div className="btn-with-border volumeOff" onClick={this.handleUnmute}>
                        <VolumeOff />
                    </div> : 
                    <div className="btn-with-border volumeOn" onClick={this.handleMute}>
                        <VolumeUp />
                    </div>
                }
            </div>
        );
    }
};

export default TimelineControls;