import React, { useEffect } from 'react';

import './styles/thumbnail.scss';

export interface thumbnailProps {
    leftPosition:number,
    thumbnailVisible:boolean,
    videoSrc:string,
    vidCurrentTime:number
};

const Thumbnail = (props:thumbnailProps) => { 
    const videoRef:any = React.createRef();    
    
    useEffect(() => {
        videoRef.current.currentTime = props.vidCurrentTime;
    });
    
    return (
        <div 
            className={ props.thumbnailVisible ? "thumbnail-container visible" 
                                               : "thumbnail-container"}
        >
            <div 
                className="thumbnail-video-container" 
                style={{ left: props.leftPosition }}
            >
                <video 
                    className='videoThumb' 
                    src={props.videoSrc} 
                    controls={false} 
                    ref={videoRef}
                />
            </div>
        </div>
    );
}

export default Thumbnail;