import { UserManager } from 'oidc-client';
import { applyMiddleware, createStore, Store } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import thunk from 'redux-thunk';
import { IRootState } from '../models';
import { createRootReducer } from '../reducers';

export function configureStore(userManager: UserManager, initialState?: IRootState): Store<IRootState> {
  //let middleware = applyMiddleware(thunk, logger, routerMiddleware(history));
  let middleware = applyMiddleware(thunk);

  if (process.env.NODE_ENV !== 'production') {
    middleware = composeWithDevTools(middleware);
  }

  const rootReducer = createRootReducer();
 
  const store = createStore(rootReducer,initialState, middleware) as Store<IRootState>;
 // const store = createStore(rootReducer,initialState, middleware) as Store<IRootState>;
  // loadUser(store, userManager);
  return store;
}


