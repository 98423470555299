export const getRelativeCoordinates = (event: MouseEvent, element: HTMLElement) => {
  const position = {
    x: event.pageX,
    y: event.pageY
  };

  const offset = {
    left: element.offsetLeft,
    top: element.offsetTop
  };

  let reference = element.offsetParent as HTMLElement;

  while (reference != null) {
    offset.left += reference.offsetLeft;
    offset.top += reference.offsetTop;
    reference = reference.offsetParent as HTMLElement;
  };

  return { 
    x: position.x - offset.left,
    y: position.y - offset.top,
  }; 
};