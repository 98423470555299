import { OverlaysArray, OverlayData } from "../models/OverlaysData";
import { OverlaysActions } from "../actions/OverlaysActions";
import { reducerWithInitialState } from "typescript-fsa-reducers";
import { jsonTryParse } from '../../utils/jsonTryParse';


const initialOverlays:OverlaysArray= {    
    current:[],   
};

export const overlaysReducer = reducerWithInitialState(initialOverlays)
    .case(OverlaysActions.InitializeOverlayFromJson, ( state, { payload } ) => {  
        let newOvsInScene:OverlaysArray = { ...state };
        const currentTemplate:any = jsonTryParse(payload);
        let overlaysInScene:any = currentTemplate.json.scenes[0].overlays;
        const vidDuration = Number(document.getElementsByTagName('video')[0].duration.toFixed(3));
        
        newOvsInScene = { current: [] };
        overlaysInScene.forEach( (overlay:any, index:number) => {
            let start;
            let end;
            if(overlay.end !== undefined){
                end = overlay.end.time;
            }else{
                end = vidDuration;
            }
            if(overlay.start !== undefined){
                start = overlay.start.time
            }else{
                start = 0
            }
            const newwidth = Number(((end - start )*100 / vidDuration).toFixed(3));
            const overlayToPush:OverlayData = {
                index: index,
                startPoint: start,
                endPoint: end,
                width: newwidth + '%',
                //videoDuration: vidDuration,
                visible: true,
                classes: 'overlay'
            }
            newOvsInScene.current.push(overlayToPush);
        });
        return { ...newOvsInScene };
    })
    .case(OverlaysActions.addOverlay, ( state, { startPoint, endPoint } ) => {
        const finalTemplate: OverlaysArray = {
            ...state as OverlaysArray
        }
        const vidDuration = Number(document.getElementsByTagName('video')[0].duration.toFixed(3));
        let start;
        let end;
        if(startPoint !== "" && startPoint !== undefined && startPoint !== null){
            start = startPoint;
        }else{
            start = 0 ;
        }
        if(endPoint !== "" && endPoint !== undefined && endPoint !== null){
            end = endPoint;
        }else{
            end = vidDuration;
        }
        const newwidth = Number(((end - start )*100 / vidDuration).toFixed(3));

        const overlayToPush:OverlayData = {
            //embeddedId: embeddedId? embeddedId : '',
            index: finalTemplate.current.length,
            startPoint: start,
            endPoint: end,
            width: newwidth + '%',
            //videoDuration: vidDuration,
            visible: true,
            classes: 'overlay'
        }

        finalTemplate.current.push(overlayToPush);
        
        return finalTemplate;
    })
    .case(OverlaysActions.deleteOverlay, ( state, index ) => {
        // we make a copy of the current 'overlaysInScene' to use it for deleting the indicated overlay
        let newOvArray = JSON.parse(JSON.stringify(state));
        const na = newOvArray.current.filter((ov, i) => {
            // first update the value of index for each overlay to be equal to its new position inside the updated array
            // this value for index, will match the number in text for the overlay in the canvas element
            i < index ? ov.index = i : ov.index = i -1;
            return ov !== newOvArray.current[index]; 
        });
        newOvArray.current = na;
        return newOvArray;
    })
    .case(OverlaysActions.updateOverlay, ( state, { index, startPoint, endPoint, width, visible } ) => {
        //let ovs = JSON.parse(JSON.stringify(state));
        let ovs:OverlaysArray = { ...state };
        const vidDuration = Number(document.getElementsByTagName('video')[0].duration.toFixed(3));
        // the properties specified are passed them to the overlay in the redux store
        if (startPoint !== undefined) {
            ovs.current[index].startPoint = startPoint;
        }else{
            ovs.current[index].startPoint = 0;
        }
        if (endPoint !== undefined) {
            ovs.current[index].endPoint = endPoint;
        }
        else{
            ovs.current[index].endPoint = vidDuration;
        }
        if (width !== undefined) {
            ovs.current[index].width = width;
        }
        if (visible !== undefined) {
            ovs.current[index].visible = visible;
            visible ?
                ovs.current[index].classes = "overlay"
                : ovs.current[index].classes = "overlay inactive"
        }
      
        return { ...ovs };
    })
    .case(OverlaysActions.updateOverlayFromJson, ( state,{ currentScene, payload }) => {

        let newOvsInScene:OverlaysArray = { ...state };
        const currentTemplate:any = jsonTryParse(payload);
        const vidDuration = Number(document.getElementsByTagName('video')[0].duration.toFixed(3));
            let overlaysInScene:any = currentTemplate.json.scenes[currentScene].overlays;              
            newOvsInScene = { current: [] };
            overlaysInScene.forEach( (overlay:any, index:number) => {
                let start;
                let end;
                if(overlay.end !== undefined){
                    end = overlay.end.time;
                }else{
                    end = vidDuration;
                }
                if(overlay.start !== undefined){
                    start = overlay.start.time
                }else{
                    start = 0
                }
                const newwidth = Number(((end - start )*100 / vidDuration).toFixed(3));
                const overlayToPush:OverlayData = {
                    index: index,
                    startPoint: start,
                    endPoint: end,
                    width: newwidth + '%',
                    //videoDuration: vidDuration,
                    visible: true,
                    classes: 'overlay'
                }
                newOvsInScene.current.push(overlayToPush);
            });
          
        return { ...newOvsInScene };
    })
    .case(OverlaysActions.resetOverlaysInScene, ( state) => {
        //let newOvsInScene = { ...state };

        return { ...initialOverlays }
    })
    .case(OverlaysActions.resetStore, () => {
        return { ...initialOverlays }
    });
    
