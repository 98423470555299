import { Grid } from '@material-ui/core';
import React from 'react';
import EditorSettings from './EditorSettings';
import CodeButton from './CodeButton';
import SaveTemplate from './SaveTemplate';
import {connect} from 'react-redux';
import { Dispatch, bindActionCreators } from 'redux';
import { IRootState } from '../../../redux';
import { EditorSettingsActions } from '../../../redux';
import UndoRedo from './UndoRedo';
import { ActionCreators as UndoActionCreators } from 'redux-undo';

import './style/statusbar.scss';
import PosterButton from './PosterButton';
  
export interface IStatusBarProps {
  fulltemplate:any,
  onUndo: typeof UndoActionCreators.undo,
  onRedo: typeof UndoActionCreators.redo,
  refreshOverlays:typeof EditorSettingsActions.EditorSettingsActions.refreshOverlays
}

const mapStateToProps = (state:IRootState) => {
  return {
    fulltemplate: state.template 
  }
}

const mapDispatchToProps = (dispatch:Dispatch): Pick<IStatusBarProps, 'onUndo'| 'onRedo' | 'refreshOverlays' > => ({
  refreshOverlays: bindActionCreators(EditorSettingsActions.EditorSettingsActions.refreshOverlays, dispatch),
  onUndo: bindActionCreators(UndoActionCreators.undo,dispatch),
  onRedo: bindActionCreators(UndoActionCreators.redo,dispatch),
});

class StatusBar extends React.Component<IStatusBarProps>{
  constructor(props:any) {
    super(props);

    this.handleUndo = this.handleUndo.bind(this);
    this.handleRedo = this.handleRedo.bind(this);
  }


  handleUndo() {
    if(this.props.fulltemplate.past.length > 1){
      this.props.onUndo();
      this.props.refreshOverlays({});
    }
  }

  handleRedo() {
    this.props.onRedo();
    this.props.refreshOverlays({});
  }

  render() {    
      return (
      <div className="toolBar">
        <Grid container alignItems={"center"} justify={"flex-end"}>
          <PosterButton/>
          <EditorSettings />
          <UndoRedo 
            handleUndo = {this.handleUndo} 
            handleRedo = {this.handleRedo} 
          />
          <CodeButton />
          <SaveTemplate /> 
        </Grid>
      </div>
    );
  }    
};

export default connect(mapStateToProps, mapDispatchToProps)(StatusBar);
