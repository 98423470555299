import * as React from 'react';
import { Route, RouteComponentProps, Switch, withRouter } from 'react-router';

import Editor from '../areas/editor';
import { SignInCallback, SignInRedirect } from '../areas/login';
import TemplateList from '../areas/templates/components/TemplateList';
import TemplateVersionList from '../areas/templates/components/TemplateVersionList';
import PrivateRoute from './PrivateRoute';
import { Dispatch, bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { OidcActions } from '../redux/actions';
import { userManager } from '../services';

import NavBar from '../areas/navigation/navBar';

export interface IRoutesProps extends RouteComponentProps {
  updateUserState: typeof OidcActions.OidcActions.updateUserState;
}

// ********************************************************************************
const mapDispatchToProps = ( dispatch: Dispatch ): Pick<IRoutesProps, 'updateUserState'> => ({
  updateUserState: bindActionCreators( OidcActions.OidcActions.updateUserState, dispatch )
});
// ********************************************************************************

const Routes: React.FC<IRoutesProps> = (props: IRoutesProps) => {
  React.useEffect(() => {
    userManager.getUser().then(user => {
      props.updateUserState({
        user: user == null ? undefined : user,
        isLoadingUser: false
      });
    });
  });

  return (
    <>
      <NavBar history={props.history} />
      <Switch location={props.location}>
        <Route path="/signin-videosmart" component={SignInCallback} />
        <PrivateRoute 
          path="/templates/:templateId/versions/:templateVersion/edit"
          //path="/templates/:templateId/edit"
          component={Editor}
        />
        <PrivateRoute
          path="/templates/:templateId/versions/:templateVersion"
          component={TemplateVersionList}
        />
        <PrivateRoute
          path="/templates/:templateId/versions"
          component={TemplateVersionList}
        />
        <PrivateRoute   path="/" exact component={TemplateList} />
        <PrivateRoute  path="/login" exact component={SignInRedirect} />
      </Switch>
    </>
  );
};

export default connect(null, mapDispatchToProps)(withRouter(Routes));
