import React from "react";
import { RouteComponentProps, withRouter } from "react-router";
import { connect } from 'react-redux';
import { Dispatch, bindActionCreators } from 'redux';
import { CampaignActions } from "../../../redux/actions";
import { IRootState } from "../../../redux";

import { Create, Save } from '@material-ui/icons';

import { TemplateVersionSummary } from "../models/TemplateVersionSummary";

export interface TemplateVersionProps extends RouteComponentProps {
  version: TemplateVersionSummary;
}  

export interface InfoProps extends TemplateVersionProps {
  getInfo: typeof CampaignActions.CampaignActions.getInfo
}

const mapStateToProps = (state:IRootState) => {
  return {
    campaignInfo: state.campaign
  }
}
const mapDispatchToProps = (dispatch:Dispatch): Pick<InfoProps, 'getInfo'> => ({
  getInfo: bindActionCreators(CampaignActions.CampaignActions.getInfo, dispatch)
});

class TemplateVersion extends React.Component<InfoProps> {
  constructor(props:InfoProps) {
    super(props);
    
    this.handleOnClick = this.handleOnClick.bind(this);
  }

  handleOnClick() {
    // this.props.getInfo({ name:'', version:this.props.version.versionNumber })
    // this.props.history.push("/templates/" + this.props.version.templateId + "/versions/" + this.props.version.versionNumber + "/edit");
  };

  render() {
    return (
      <div className="infoPanel">
        <div className="prevImage">
          <img src="https://picsum.photos/200/100" alt="preview" onClick={() => console.log('preview image clicked')} />
        </div>
        <div className="rowInfo">
          <label>Version</label>
          <textarea className="nameDetails" disabled value={this.props.version.versionNumber}></textarea>
        </div>
        <div className="rowInfo">
          <label>Comment</label>
          <textarea className="nameDetails" value={this.props.version.comment} disabled></textarea>
        </div>
        <div className="rowInfo">
          <label>Created</label>
          <textarea className="nameDetails" value={this.props.version.createdAt} disabled></textarea>
        </div>
        <button className="btn-with-border" onClick={this.handleOnClick}>
          <span>Edit</span> <Create className="editBtn" /><Save className="saveBtn" />
        </button>
      </div>
    );
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(TemplateVersion));
