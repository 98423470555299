import React from 'react';
import { IRootState } from '../../../../../redux';
import { connect } from 'react-redux';
import { Dispatch, bindActionCreators } from 'redux';
import { EditorSettingsActions } from '../../../../../redux/actions';
import { Add } from '@material-ui/icons';
import { Tooltip } from '@material-ui/core';

import './styles/ScenesTabs.scss';

export interface TabsProps {
    scenesLength:number,
    updateTabs:any,
    tabs:any,
    showAddScene: typeof EditorSettingsActions.EditorSettingsActions.showAddScene,
    changeScene: typeof EditorSettingsActions.EditorSettingsActions.changeScene,
    editingScene: typeof EditorSettingsActions.EditorSettingsActions.editingScene
}

export interface ScenesTabsState {
    tabs:any
}

const mapStateToProps = (state:IRootState) => {
    return {
        scenesLength: state.template.present.json.scenes.length
    }
}

const mapDispatchToProps = (dispatch:Dispatch): Pick<TabsProps, 'editingScene' | 'changeScene' | 'showAddScene' > => ({ 
    showAddScene: bindActionCreators(EditorSettingsActions.EditorSettingsActions.showAddScene, dispatch),
    changeScene: bindActionCreators(EditorSettingsActions.EditorSettingsActions.changeScene, dispatch),
    editingScene: bindActionCreators(EditorSettingsActions.EditorSettingsActions.editingScene, dispatch)
});

class ScenesTabs extends React.Component<TabsProps, ScenesTabsState> {
    state:any;

    constructor(props:TabsProps) {
        super(props);
        
        this.state = {
            tabs: this.props.tabs
        }

        this.handleOnClick = this.handleOnClick.bind(this);
    }

    componentDidUpdate(prevProps:any) {
         if(prevProps.scenesLength !== this.props.scenesLength) {
            this.props.updateTabs();
         }
    }

    handleOnClick() {
        this.props.editingScene({ editingSceneInfo: false });
        this.props.changeScene({ changeToNewScene: true }); 
        this.props.showAddScene({ showAddSceneModal:true });
    }
    
    render() {
        return (
            <div className="scenes-bar noselect">               
                {this.props.tabs}                
                <Tooltip title="Add New Scene" aria-label="add-scene">
                    <Add 
                        className="add-scene-btn"
                        onClick={this.handleOnClick}
                    />
                </Tooltip> 
            </div>
        )
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(ScenesTabs);