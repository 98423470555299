
import React from 'react';
import { connect } from 'react-redux';
import { Dispatch, bindActionCreators } from 'redux';
import { EditorSettingsActions } from '../../../redux';
import { Edit } from '@material-ui/icons';
import { Tooltip } from '@material-ui/core';

import './style/statusbar.scss';

export interface IEditorSettingsProps {
  editingScene: typeof EditorSettingsActions.EditorSettingsActions.editingScene;
  showAddScene: typeof EditorSettingsActions.EditorSettingsActions.showAddScene;
}

const mapDispatchToProps = (dispatch:Dispatch): Pick<IEditorSettingsProps, 'showAddScene' | 'editingScene'> => ({ 
  editingScene: bindActionCreators(EditorSettingsActions.EditorSettingsActions.editingScene, dispatch),
  showAddScene: bindActionCreators(EditorSettingsActions.EditorSettingsActions.showAddScene, dispatch)
});

const EditorSettings = (props:IEditorSettingsProps) => {
  return (
    <>
      <Tooltip title="Edit Scene" aria-label="edit">
        <div 
          className="buttons btn-with-border" 
          onClick={ () => { 
            props.editingScene( { editingSceneInfo: true } ); 
            props.showAddScene( { showAddSceneModal: true,  deletedLastScene:false } ); 
          } } 
        >
          <Edit style={{height:"20px"}} />
        </div>
      </Tooltip>
    </>
  );
};

export default connect(null, mapDispatchToProps)(EditorSettings);