import React from 'react';
import TimelineControls, { TimelineControlsProps } from './timelineControls';
import StatusBar from '../../../Toolbar/StatusBar';

import './styles/topBar.scss';

const TopBar = (props:TimelineControlsProps) => {
    return (
        <div className="timelineTopBar">                
            <TimelineControls 
                currentFrame={props.currentFrame}
                totalFrames={props.totalFrames} 
                currentTime={props.currentTime}
                videoDuration={props.videoDuration}
                goToNextFrame={props.goToNextFrame} 
                goToPreviousFrame={props.goToPreviousFrame}
                playVideo={props.playVideo}
                pauseVideo={props.pauseVideo}
                muteVideo={props.muteVideo}
                unmuteVideo={props.unmuteVideo}
                refreshTopBar={props.refreshTopBar}
                readyTopBar={props.readyTopBar}
            />                
            <StatusBar></StatusBar>
        </div>
    );
}

export default TopBar;
