import { createStyles, Theme, WithStyles, withStyles } from '@material-ui/core';
import React from 'react';
import { connect } from "react-redux";
import { RouteComponentProps } from "react-router";
import { Dispatch, bindActionCreators } from "redux";
import BasicLayout from './layouts/BasicLayout';
import { TemplateActions } from "../../redux";
import { CampaignActions } from '../../redux/actions/CampaignActions';
import { PlayerApiActions } from '../../api/actions';
import { IDictionary } from '../../api/models/IDictionary';
import { IRequest } from '../../api/models/requests/IRequest';
import { LoadingScreen } from '../../components';
import { Typography } from '@material-ui/core';
import {IRootState} from '../../redux';
import { TemplateSummary } from '../templates/models/TemplateSummary';

const styles = (theme: Theme) => createStyles({
  root: {
    height: 'Calc(100% - 57px)',
    width: '100%',
    display: 'flex',
    flexDirection: 'column'
  },
  layout: {
    flexGrow: 1,
    maxHeight: '100%'
  }
});

export interface IEditorProps extends WithStyles<typeof styles>,RouteComponentProps<{templateId:string, templateVersion:string}>  {  
}

export interface IEditorState {
  isLoading:boolean
}

export interface InfoProps extends IEditorProps {
  initLoaded:any;
  templateJson:any;
  currentScene:any;
  CampaignInfo:any;
  initializeTemplate: typeof TemplateActions.actionCreators.InitializeTemplate;
  getTemplateVersion: typeof PlayerApiActions.actionCreators.getTemplateVersion;
  infoReceived: typeof CampaignActions.infoReceived;
  getInfo: typeof CampaignActions.getInfo;
  getTemplates: typeof PlayerApiActions.actionCreators.getTemplates;
}

// ********************************************************************************
const mapStateToProps = (state: IRootState) => {
  return {
    initLoaded: state.template.present.initLoaded, 
    templateJson: state.template.present.json,
    currentScene: state.template.present.currentScene,
    CampaignInfo: state.campaign
  }
}

const mapDispatchToProps = (dispatch: Dispatch): Pick<InfoProps, "initializeTemplate" | "getTemplateVersion" | 'infoReceived' | 'getInfo' | 'getTemplates' > => ({
  initializeTemplate: bindActionCreators(TemplateActions.actionCreators.InitializeTemplate,dispatch),
  getTemplateVersion: bindActionCreators(PlayerApiActions.actionCreators.getTemplateVersion,dispatch),
  getTemplates: bindActionCreators(PlayerApiActions.actionCreators.getTemplates,dispatch),
  infoReceived: bindActionCreators(CampaignActions.infoReceived, dispatch),
  getInfo: bindActionCreators(CampaignActions.getInfo, dispatch),
});
// ********************************************************************************

class Editor extends React.Component<InfoProps, IEditorState> {
  constructor(props:any){
    super(props);

    this.state = {
      isLoading: true      
    };
  }
  
  async componentDidMount(){
    let templates:TemplateSummary[];
    let updatedOverlays: TemplateSummary[];
    const params : IDictionary<string> ={"templateId":this.props.match.params.templateId,"versionNumber":this.props.match.params.templateVersion};
    const request : IRequest<any> = {params};
    const templateVersionResponse = await this.props.getTemplateVersion(request);
    if (templateVersionResponse !== null && templateVersionResponse.ok&& templateVersionResponse.value !== null) {
      this.props.initializeTemplate(templateVersionResponse.value);
      if(this.props.initLoaded){
        if(this.props.CampaignInfo.name === ""){
          const templatesResponse = await this.props.getTemplates();
          if (templatesResponse !== null && templatesResponse.templates !== null) {
            templates = templatesResponse.templates;
            updatedOverlays = templates.filter((ov:any) => {
              return ov.id === this.props.match.params.templateId;
            });
            this.props.getInfo({ 
              name: updatedOverlays[0].name, 
              templateid:this.props.match.params.templateId, 
              version: Number(this.props.match.params.templateVersion), 
              newTemplate: false
            });
          }         
        }
        if(this.props.templateJson.scenes[this.props.currentScene] && (this.props.templateJson.scenes[this.props.currentScene].videoSource.source !== "")){
          this.setState(() => {
            return { 
              isLoading: false 
            }
          });
        }else{
          this.props.infoReceived({newTemplate:true}); 
          this.setState(() => {
            return { 
              isLoading: false 
            }
          });
        }          
      }
    }
  }

  componentWillUnmount() {
    // we make sure to remove any existing video from the DOM when loading a new template
    if(document.getElementById('backVideo') !== null){
      document.body.removeChild(document.getElementById('backVideo') as Node);
    }
  }

  public render = () => {
    const { classes } = this.props;

    return ( 
      <>
      {this.state.isLoading ?
        <>
          <LoadingScreen>
            <Typography>Loading...</Typography>
          </LoadingScreen>
        </>
        :
        <>
          <div className={classes.root}>
            <div className={classes.layout}>
              <BasicLayout />
            </div>
          </div>
        </>       
      }
      </>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Editor));