import { Template, TrackerType } from '@videosmart/player-template';

import { Tracker } from '../../areas/editor/preview/models/Tracker';

const selectTracker = (template: Template, sceneId: number, frame: number): Tracker[] => {
  //const scene = template.scenes.find((scene) => scene.id === sceneId);
  const scene = template.scenes[sceneId];
  if (scene && scene.overlays) {
    return scene.overlays.map((overlay, index) => ({
      index: index,
      type: overlay.tracker ? overlay.tracker.type : TrackerType.ScaleAndTranslate,
      name: index.toString(),
      points: overlay.tracker ? overlay.tracker.trackData.frames[frame].points : []
    }));
  }

  return [];
};

export { selectTracker };
