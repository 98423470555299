import React from "react";
import { connect } from 'react-redux';
import { Dispatch, bindActionCreators } from 'redux';
import { IRootState } from "../../../redux";
import { TemplateVersionSummary } from "../models/TemplateVersionSummary";
import {IDictionary} from '../../../api/models/IDictionary';
import {IRequest} from '../../../api/models/requests/IRequest';
import {PlayerApiActions} from '../../../api/actions';
import '../styles/template.scss';
export interface TemplateVersionModalProps {   
    campaignInfo:any;
}  
export interface InfoModalProps extends TemplateVersionModalProps {
    getTemplateVersions: typeof PlayerApiActions.actionCreators.getTemplateVersions
}
const mapStateToProps = (state:IRootState) => {
  return {
    campaignInfo: state.campaign
  }
}
const mapDispatchToProps = (dispatch:Dispatch): Pick<InfoModalProps, 'getTemplateVersions'> => ({
  getTemplateVersions: bindActionCreators(PlayerApiActions.actionCreators.getTemplateVersions, dispatch)
});

class TemplateVersion extends React.Component<InfoModalProps> {
    state:any;
    constructor(props:InfoModalProps) {
    super(props); 
        this.state = {     
            versions: []
        };  
    }
    public componentDidMount = async () => {
      let versions:TemplateVersionSummary[];
      const params : IDictionary<string> ={"templateId":this.props.campaignInfo.templateid};
      const request : IRequest<any> = {params};
      const templateVersionsResponse = await this.props.getTemplateVersions(request); 
      if (templateVersionsResponse !== null && templateVersionsResponse.ok&& templateVersionsResponse.versions !== null) {
        versions = templateVersionsResponse.versions;
      } 
      this.setState(() => ({versions: versions}));
    };
    
    render() {
        return (
                <div>
                    {this.state.versions.length > 0 ? (
                    this.state.versions.map((version: TemplateVersionSummary, index: number) => (
                        <React.Fragment key={index}>
                        
                        <div>
                            <p>Version: {version.versionNumber} | Comment: {version.comment}</p>
                         
                        </div>                       
                        </React.Fragment>
                    ))
                    ) : (            
                    <div className="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
                    )}
                
                </div>
        );
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(TemplateVersion);
