
import React from 'react';
import { connect } from 'react-redux';
import { Dispatch, bindActionCreators } from 'redux';
import { IRootState, TemplateActions, EditorSettingsActions } from '../../../../../redux';
import { Subtitles } from '@material-ui/icons';

export interface SubtitleButtonData {
    scenes:any,
    addSubtitleLanguageToJson:typeof TemplateActions.asyncActionCreators.addSubtitleLanguageToJson,
    subtitlesPainted:typeof EditorSettingsActions.EditorSettingsActions.subtitlesPainted
}
const mapStateToProps = (state: IRootState) => {
    return {
        scenes: state.template.present 
    }
}

const mapDispatchToProps = (dispatch:Dispatch): Pick<SubtitleButtonData, 'subtitlesPainted' | 'addSubtitleLanguageToJson'>  => ({
    addSubtitleLanguageToJson: bindActionCreators(TemplateActions.asyncActionCreators.addSubtitleLanguageToJson, dispatch),
    subtitlesPainted: bindActionCreators(EditorSettingsActions.EditorSettingsActions.subtitlesPainted, dispatch)
});


class AddSubtitleBtn extends React.Component<SubtitleButtonData> {
    constructor(props:any) {
        super(props);

        this.handleOnClick = this.handleOnClick.bind(this);
    }
    
    handleOnClick(){
        this.props.addSubtitleLanguageToJson({ currentScene: this.props.scenes.currentScene });
        this.props.subtitlesPainted({ subtitlesReady:false });
    }
    render() {
        return(
            <div className="btn-with-border noselect" id="addSubtitle" onClick={this.handleOnClick} >
                Add Subtitle <Subtitles  />
            </div>
        );
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(AddSubtitleBtn);
