import { actionCreatorFactory } from 'typescript-fsa';
import { UserState } from 'redux-oidc';

enum Type {
    UpdateUserState = 'UPDATE_USER_STATE',
    GetUser = 'GET_USER_STATE',
    SigninSilent = 'SIGNIN_SILENT'
  }
  
  const create = actionCreatorFactory('OIDC');
  
  export const OidcActions = {
    updateUserState: create<UserState>(Type.UpdateUserState),
    getUser: create<UserState>(Type.GetUser)
  }