import { CssBaseline } from '@material-ui/core';
import { MuiThemeProvider } from '@material-ui/core/styles';
import { createBrowserHistory, } from 'history';
import React from 'react';

import { Provider } from 'react-redux';
import { Router} from 'react-router';

import { configureStore } from './redux/store';
import Routes from './routes';
import { userManager } from './services/userManager';
import { darkTheme } from './theme';

const history = createBrowserHistory();
//{forceRefresh: true}
const store = configureStore(userManager);
const theme = darkTheme;

const App: React.FC = () => {

  return (
    <Provider store={store}>
      {/* <OidcProvider store={store} userManager={userManager}> */}
      <MuiThemeProvider theme={theme}>
        <React.Fragment>
          <CssBaseline />
          <Router history={history}>
            <Routes />
          </Router>
        </React.Fragment>
      </MuiThemeProvider>
      {/* </OidcProvider>  */}
    </Provider>
  );
}

export default App;
