import React from 'react';
import { connect } from 'react-redux';
import { Dispatch, bindActionCreators } from 'redux';
import { IRootState, EditorSettingsActions, TemplateActions } from '../../../../redux';
import { ArrowBack } from '@material-ui/icons';
import Variable from './Variable/Variable';
import { Tooltip, Button } from '@material-ui/core';
import SimpleBar from 'simplebar-react';

import 'simplebar/dist/simplebar.min.css';
import './VariablesEditor.scss';

export interface VariablesEditorProps {
    template: any,
    templateJson: any,
    VariableList:any,
    showVariablesEditor:typeof EditorSettingsActions.EditorSettingsActions.showVariablesEditor,
    deleteVariable:typeof TemplateActions.asyncActionCreators.deleteVariable,
    applyVariableValue:typeof TemplateActions.asyncActionCreators.applyVariableValue,
    refreshOverlays: typeof EditorSettingsActions.EditorSettingsActions.refreshOverlays,
    refreshCanvas: typeof EditorSettingsActions.EditorSettingsActions.refreshCanvas,
    deleteAllVariable: typeof TemplateActions.asyncActionCreators.deleteAllVariable,
    applyAllVariable: typeof TemplateActions.actionCreators.applyAllVariable
}

const mapStateToProps = (state:IRootState) => {
    return {
        template: state.template.present.text,
        templateJson: state.template.present.json,
        VariableList: state.template.present.VariableArray
    }
}

const mapDispatchToProps = (dispatch:Dispatch): Pick<VariablesEditorProps, 'refreshCanvas' | 'refreshOverlays' | 'showVariablesEditor' | 'deleteVariable' | 'applyVariableValue' | 'deleteAllVariable' | 'applyAllVariable'> => ({
    showVariablesEditor: bindActionCreators(EditorSettingsActions.EditorSettingsActions.showVariablesEditor, dispatch),
    deleteVariable: bindActionCreators(TemplateActions.asyncActionCreators.deleteVariable, dispatch),
    applyVariableValue: bindActionCreators(TemplateActions.asyncActionCreators.applyVariableValue, dispatch),
    refreshOverlays: bindActionCreators(EditorSettingsActions.EditorSettingsActions.refreshOverlays, dispatch),
    refreshCanvas: bindActionCreators(EditorSettingsActions.EditorSettingsActions.refreshCanvas, dispatch),
    deleteAllVariable: bindActionCreators(TemplateActions.asyncActionCreators.deleteAllVariable, dispatch),
    applyAllVariable: bindActionCreators(TemplateActions.actionCreators.applyAllVariable, dispatch)
});


class VariablesEditor extends React.Component<VariablesEditorProps> {
    state:any;
    ApplyAllVar:Array<any>;

    constructor(props:VariablesEditorProps) {
        super(props);

        this.state = {
            varContent: [],
            applyBoolean: true
        }

        this.ApplyAllVar = [];

        this.handleDeleteVariable = this.handleDeleteVariable.bind(this);
        this.handleApplyChanges = this.handleApplyChanges.bind(this);
        this.InitializeSavedVariable = this.InitializeSavedVariable.bind(this);
        this.handleApplyAll = this.handleApplyAll.bind(this);
        this.handleDeleteAll = this.handleDeleteAll.bind(this);
    }

    componentDidMount() {
       this.InitializeSavedVariable();
    }
    componentDidUpdate(prevProps:any){
        if(prevProps !== this.props){
            if(!this.state.applyBoolean){
                this.InitializeSavedVariable();
            }
        }
    }

    InitializeSavedVariable(){
        let newArray:any[];
        let varContentHTML:any = this.state.varContentToPrint;
        if(this.props.VariableList.length > 0) {
            newArray = [];
            this.props.VariableList.forEach((valueV:any, index:any) => {
                newArray.push(
                    <Variable 
                        key={Math.floor(Math.random() * 1000 + this.state.varContent.length)}
                        value={valueV.name}
                        result ={valueV.value}
                        index={this.state.varContent.length} 
                        deleteVariable = {this.handleDeleteVariable}
                        applyChanges = {this.handleApplyChanges}
                    />                
                );
            });          
            varContentHTML = newArray.map((variableEl:any) => {
                return (
                    variableEl
                );
            });
        }
         
        this.setState((prevState:any) => {
            return {
                ...this.state,
                varContent: varContentHTML
            }
        });
    }   
    handleDeleteVariable(value:number) {
        this.props.deleteVariable({variable: value});
        this.setState(() => {
            return {
                ...this.state,
                applyBoolean: false
            }
        });     
    }
    handleApplyChanges(value:string, resultV:any) {      
        this.props.applyVariableValue({
            variable: value,
            result: resultV
        });
        this.props.refreshOverlays({});
        this.props.refreshCanvas({ refreshCanvas: true });
        this.setState(() => {
            return {
                ...this.state,
                applyBoolean: true
            }
        });
    }   
    handleApplyAll(){
        if(this.props.VariableList.length > 0){
            this.props.applyAllVariable(this.props.template);
            this.props.refreshOverlays({});
            this.props.refreshCanvas({ refreshCanvas: true });
        }
    }
    handleDeleteAll(){
        if(this.props.VariableList.length > 0){
            this.props.deleteAllVariable({allvar:this.props.VariableList})
            this.setState(() => {
                return {
                    ...this.state,
                    applyBoolean: false
                }
            }); 
            this.props.refreshOverlays({});
            this.props.refreshCanvas({ refreshCanvas: true });  
        }  
    }

    render() {
        return (
            <div className="variablesContainer">
                <SimpleBar style={{maxHeight:'100%'}}>
                    <div className="variables-inner-container">
                        <Tooltip title="Back to Code" aria-label="back">
                            <ArrowBack 
                                className="var-back-btn"
                                onClick={ () => { this.props.showVariablesEditor({ VariablesEditor:false }); } }
                            />
                        </Tooltip>
                        <br /><br />
                        <div className="varPanelTitle">Variables in Template</div>
                        <br />
                        { this.state.varContent ?
                        <>
                            <Button className="applyVarBtn" onClick={this.handleApplyAll}>Apply All</Button>
                            <Button className="delVarBtn" onClick={this.handleDeleteAll}>Delete All</Button>
                        </>
                        : <></>
                        }
                        <br /><br />  
                        <div className="varInnerContainer">                            
                            { this.state.varContent 
                                ? this.state.varContent
                                : <></>
                            }
                        </div>
                    </div>
                </SimpleBar>
            </div>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(VariablesEditor);