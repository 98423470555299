import { CampaignInfo } from "../models/CampaignInfo";
import { CampaignActions } from "../actions/CampaignActions";
import { reducerWithInitialState } from "typescript-fsa-reducers";

const initialInfo:CampaignInfo = {
   name: "",
   templateid: "",
   version: -1,
   newTemplate: false
}

export const campaignReducer =  reducerWithInitialState(initialInfo)
    .case(CampaignActions.getInfo, ( state, info ) => {
        let c = state;

        info.name !== '' ?  c.name = info.name : c.name = state.name;
        info.templateid !== ''? c.templateid = info.templateid: c.templateid = state.templateid;
        info.version !== -1 ?  c.version = info.version : c.version = state.version;

        return { ...c }
    })
    .case(CampaignActions.infoReceived, ( state , { newTemplate } ) => {
        let c:CampaignInfo = { ...state };

        c.newTemplate = newTemplate;

        return { ...c }
    })
    .case(CampaignActions.resetStore, () => {
        return { ...initialInfo }
    });

