import { combineReducers } from 'redux';
import undoable from 'redux-undo';
import { IRootState } from '../models';
import { templateReducer } from '../reducers/templateReducer';
import { campaignReducer } from './campaignReducer';
import { editorSettingsReducer } from './editorSettingsReducer';
import {oidcReducer} from './oidcReducer';
import { overlaysReducer } from './overlaysReducer';

// undoable allow for the specifica state to be treated to de redux-undo
const createRootReducer = () => combineReducers<IRootState>({
  oidc: oidcReducer,
  template:  undoable(templateReducer),
  overlaysInScene: overlaysReducer,
  campaign:campaignReducer,
  editorSettings: editorSettingsReducer
});

//  const rootReducer = (state:IRootState) => {
//    return createRootReducer(state)
//  }

export { createRootReducer };



  