import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Dispatch, bindActionCreators } from 'redux';
import { EditorSettingsActions } from '../../../../../redux';

import './styles/elementsBar.scss';

export interface ElemsProps {
    showSubtitlesEdition: typeof EditorSettingsActions.EditorSettingsActions.showSubtitlesEdition,
    showOverlaysEdition: typeof EditorSettingsActions.EditorSettingsActions.showOverlaysEdition
}

const mapDispatchToProps = (dispatch:Dispatch): Pick<ElemsProps, 'showSubtitlesEdition' | 'showOverlaysEdition' > => ({
    showSubtitlesEdition: bindActionCreators(EditorSettingsActions.EditorSettingsActions.showSubtitlesEdition, dispatch),
    showOverlaysEdition: bindActionCreators(EditorSettingsActions.EditorSettingsActions.showOverlaysEdition, dispatch)
});

const ElementsBar = (props:ElemsProps) => {
    const [activeClass, changeActiveClass] = useState("el1");

    return (
        <div className={"elements-bar noselect " + activeClass}>
            <button
                onClick={ () => { changeActiveClass("el1"); props.showOverlaysEdition({}); } }
            >
                Overlays
            </button>
            {/* <button
                onClick={ () => { changeActiveClass("el2"); props.showSubtitlesEdition({}); } }
            >
                Subtitles
            </button>
            <button
                onClick={ () => { changeActiveClass("el3"); } }
            >
                Events
            </button> */}
        </div>
    )
}

export default connect(null,mapDispatchToProps)(ElementsBar);