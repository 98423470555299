import { actionCreatorFactory } from 'typescript-fsa';
import { IUpdateTrackerPayload } from '../models/IUpdateTrackerPayload';

enum Type {
  UpdateTemplate = 'UPDATE_TEMPLATE',
  UpdateTracker = 'UPDATE_TRACKER',
  AddOverlayToJson = 'ADD_OVERLAY',
  AddSceneToJson = 'ADD_Scene',
  AddSubtitleLanguageToJson = 'ADD_SUBTITLE_LANGUAGE',
  RemoveOverlayFromJson = "REMOVE_OVERLAY",
  UpdateOverlay = 'UPDATE_OVERLAY',
  InitializeTemplate = 'INITIALIZE_TEMPLATE',
  UpdateSubtitleLanguage = "UPDATE_SUBTITLE_LANGUAGE",
  changeScene = 'CHANGE_SCENE',
  SetSceneBasicInfo = 'SET_SCENE_BASIC_INFO',
  DeleteSceneFromTemplate = 'DELETE_SCENE_FROM_TEMPLATE',
  AddVariable = 'ADD_VARIABLE',
  DeleteVariable = 'DELETE_VARIABLE',
  DeleteAllvariable = 'DELETE_ALL_VARIABLE',
  ApplyVariableValue = 'APPLY_VARIABLE_VALUE',
  ResetStore = 'RESET_STORE',
  InitializeVariable = 'INITIALIZE_VARIABLE',
  UpdateVariableEditor = 'UPDATE_VARIABLE_EDITOR',
  ApplyAllvariable = 'APPLY_ALL_VARIABLE',
  AddPoster = 'ADD_POSTER',
  DeletePoster = 'DELETE_POSTER',
  TemplateSaved = 'TEMPLATE_SAVED'
}

const create = actionCreatorFactory('TEMPLATE');

// Async Action Creators
export const asyncActionCreators = {
  addOverlayToJson: create<any>(Type.AddOverlayToJson),
  addSceneToJson: create<any>(Type.AddSceneToJson),
  addSubtitleLanguageToJson: create<any>(Type.AddSubtitleLanguageToJson),
  removeOverlayFromJson: create<any>(Type.RemoveOverlayFromJson),
  updateOverlay: create<any>(Type.UpdateOverlay),
  updateSubtitleLanguage: create<any>(Type.UpdateSubtitleLanguage),
  changeScene: create<any>(Type.changeScene),
  setSceneBasicInfo: create<any>(Type.SetSceneBasicInfo),
  deleteSceneFromTemplate: create<any>(Type.DeleteSceneFromTemplate),
  addVariable: create<any>(Type.AddVariable),
  deleteVariable: create<any>(Type.DeleteVariable),
  applyVariableValue: create<any>(Type.ApplyVariableValue),
  deleteAllVariable: create<any>(Type.DeleteAllvariable),
  addPoster: create<any>(Type.AddPoster),
  deletePoster: create<any>(Type.DeletePoster),
  updateVariableEditor: create<any>(Type.UpdateVariableEditor)
};

// Action Creators
export const actionCreators = {
  updateTemplate: create<string>(Type.UpdateTemplate), 
  updateTracker: create<IUpdateTrackerPayload>(Type.UpdateTracker),
  InitializeTemplate: create<any>(Type.InitializeTemplate),
  resetStore: create<any>(Type.ResetStore),
  initializeVariable: create<any>(Type.InitializeVariable),
  applyAllVariable: create<any>(Type.ApplyAllvariable),
  templateSaved: create<any>(Type.TemplateSaved)
};