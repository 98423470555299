import React from 'react';
import { ReflexContainer, ReflexElement, ReflexSplitter } from 'react-reflex';
import JsonEditor from '../JsonEditor/jsonEditor';
import Preview from '../preview';
import Timeline from '../timeline';
import OverlayEditor from '../OverlayEditor/OverlayEditor';
// ****************************************************
import { connect } from 'react-redux';
import { IRootState } from '../../../redux';
// ****************************************************
import { LoadingScreen } from '../../../components';
import './reflex.scss';
import VariablesEditor from '../JsonEditor/VariablesEditor/VariablesEditor';
import AddSceneModal from '../AddSceneModal/AddSceneModal';

export interface LayoutProps {
  editorVisible: boolean,
  codeEditorVisible: boolean,
  loadingData: boolean,
  newTemplate: boolean,
  variablesEditor: boolean
}

const mapStateToProps = (state: IRootState) => {
  return {
    editorVisible: state.editorSettings.editorVisible,
    codeEditorVisible: state.editorSettings.codeEditor,
    loadingData: state.editorSettings.loading,
    newTemplate: state.campaign.newTemplate,
    variablesEditor: state.editorSettings.variablesEditor
  }
}


const BasicLayout = (props:LayoutProps) => {
  document.getElementsByTagName('nav')[0].classList.remove('templates');

  return (
    <>
    { props.newTemplate ? 
      <AddSceneModal />
      :  
        <> 
        { props.loadingData ? 
          <div className="loading-container">
            <LoadingScreen />
          </div>
        : 
        <>
        </>
        } 
      <>
          <AddSceneModal />
          <div className="staticInterface">
          <ReflexContainer orientation="horizontal">
            <ReflexElement>
              <ReflexContainer>
                <ReflexElement flex={0.6} style={{zIndex:200}}>
                  <ReflexContainer orientation="vertical">
                    <ReflexElement propagateDimensions >
                      <Preview />
                    </ReflexElement>
                  </ReflexContainer>
                </ReflexElement>
              {/* ------------------------------------------ */}
                <ReflexSplitter />
              {/* ------------------------------------------ */}
                <ReflexElement flex={0.4} maxSize={450} minSize={16}>
                  <ReflexContainer orientation="vertical">
                    <ReflexElement>
                      <Timeline />
                    </ReflexElement>
                  </ReflexContainer>       
                </ReflexElement>
              </ReflexContainer>
            </ReflexElement>
          </ReflexContainer>
          </div>
          <div className={props.editorVisible || props.codeEditorVisible ? "dynamicElements visible" : "dynamicElements"}>
            <div className="adminComponent">
              <JsonEditor />
              {props.variablesEditor ?
                <VariablesEditor />
                : <></>
              }
            </div>
            {props.editorVisible ?
              <OverlayEditor />
            : 
              <></>
            }
          </div>
          </>
       
      </>
      }
    </>
  );
}

export default connect(mapStateToProps)(BasicLayout);


