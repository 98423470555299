import React from 'react';
import { connect } from 'react-redux';
import { Dispatch, bindActionCreators } from 'redux';
import { IRootState } from '../../../../../redux';
import { TemplateActions, OverlaysActions } from '../../../../../redux/actions';
import Thumbnail from "../video/thumbnail";
import AddOverlayBtn from '../addOverlayBtn/AddOverlayBtn';
import PositionIndicator from '../../components/PositionIndicator/PositionIndicator';
import './styles/timelineBar.scss';
import AddSubtitleBtn from '../addSubtitleBtn/AddSubtitleBtn';

export interface DurationState { 
  leftPosition: number,
  leftThumbnail: number,
  thumbnailVisible: boolean,
  videoSrc: string,
  videoDuration: number,
  // ----------------------------
  handleClickOnTimeline: any,
  handleHoverOnTimeline: any,
  handleHoverOutTimeline: any,
  // ----------------------------
  template: string,
  overlaysInTemplateArray: any, 
  overlayEditing: any,
  currentScene:number,
  // ----------------------------
  removeOverlayFromJson: typeof TemplateActions.asyncActionCreators.removeOverlayFromJson,
  deleteOverlay:typeof OverlaysActions.OverlaysActions.deleteOverlay
};

// ********************************************************************************
const mapStateToProps = (state:IRootState) => {
  return {
    template: state.template.present.text,
    overlaysInTemplateArray: state.template.present.json.scenes,
    overlayEditing: state.editorSettings,
    currentScene: state.template.present.currentScene
  }
}
const mapDispatchToProps = (dispatch:Dispatch): Pick<DurationState, 'removeOverlayFromJson' | 'deleteOverlay'> => ({
  removeOverlayFromJson: bindActionCreators(TemplateActions.asyncActionCreators.removeOverlayFromJson, dispatch) ,
  deleteOverlay: bindActionCreators(OverlaysActions.OverlaysActions.deleteOverlay, dispatch),  
});

class TimelineBar extends React.Component<DurationState> {
  refer:any;
  state:any;
  constructor(props:any) {
    super(props);

    this.state = { 
      x: 0,
      container: {},
      vidThumbCurrentTime: 0,
      mouseDown: false,
      heightDrag: 10, 
      temp: this.props.template
    };

    this.refer = React.createRef();
    
    this._onMouseClick = this._onMouseClick.bind(this);
    this._onMouseHover = this._onMouseHover.bind(this);
    this.handleMouseOut = this.handleMouseOut.bind(this);
    this.handleMouseUp = this.handleMouseUp.bind(this);

  }

  componentDidMount() {
    this.setState(() => {
      return {
        container: this.refer.current.getBoundingClientRect(),
        heightDrag: this.refer.current.offsetHeight
      }
    });
  }

  componentDidUpdate(prevProps: any) {
    const cw = this.refer.current.getBoundingClientRect();
    if (prevProps !== this.props) {
      this.setState(() => {
        return {
          temp: this.props.template,
          container: cw,
        }
      });
    }
  }
  
  _onMouseClick(e:any) { 
    let newX = e.nativeEvent.offsetX;
    if ( (this.state.x !== newX) && (newX !== -0) ) {
      this.props.handleClickOnTimeline( newX, this.state.container.width );
    }

    this.setState( (prevState) => {
      return {
        ...prevState,
        x: newX,
        mouseDown: true
      }
    });
  }

  _onMouseHover(e:any) {
    // getting the x coordinate of the mouse that will be used for the leftPosition of the videoThumbnail
    let newX = e.nativeEvent.offsetX;

    // handleHoverOnTimeline will calculate the new 'leftPosition' and will set 'thumbnailVisible' to true
    this.props.handleHoverOnTimeline( newX + 8, this.state.container.width );

    // here we calculate the time for the video Thumbnail depending on the position of the cursor 
    let vd = Number( ( ( ( (newX * 100) / this.state.container.width ) * this.props.videoDuration ) / 100 ).toFixed(3) );

    // and we update the state with the new value
    this.setState((prevState) => {
      return {
        ...prevState,
        vidThumbCurrentTime: vd
      }
    });

    // here we check if the user wants to change the currentTime of the video by dragging
    if (this.state.mouseDown) {      
      if ( (this.state.x !== newX) && (newX !== -0) ) {
        this.props.handleClickOnTimeline( newX, this.state.container.width );
      }
    }
  }

  handleMouseOut() {
    this.handleMouseUp();
    this.props.handleHoverOutTimeline();
  }

  handleMouseUp() {
    this.setState((prevState) => {
      return {
        ...prevState,
        mouseDown: false
      }
    });
  }

  
  render() {    
    return (
      <> 
        <div className="timelineBar">
          <div className="timeline-left">
            {/* -------   here we control the Add Overlay button   --------- */} 
              { !this.props.overlayEditing.editingSubtitles 
                ? <AddOverlayBtn />
                : <AddSubtitleBtn />
              }
              <div className="empty-right"></div>
          </div>
          
          <div className="timeline-right">
            {/* ------------------------ Video Thumbnail ------------------- */}
            <Thumbnail 
              leftPosition={this.props.leftThumbnail} 
              thumbnailVisible={this.props.thumbnailVisible}
              videoSrc={this.props.videoSrc}
              vidCurrentTime={this.state.vidThumbCurrentTime}
            />
            <div className="timeline-container">
              {/* ------------------------ Video Timeline bar ------------------- */}
              <div className="timeline" 
                  onMouseMove={this._onMouseHover}
                  onMouseDown={this._onMouseClick}
                  onMouseLeave={this.handleMouseOut}
                  onMouseUp={this.handleMouseUp}
                  ref={this.refer}    
              >
              </div>
              <PositionIndicator
                leftPosition={this.props.leftPosition}
                numOverlays={this.props.overlaysInTemplateArray[this.props.currentScene].overlays ? this.props.overlaysInTemplateArray[this.props.currentScene].overlays.length : 0}
                editingOverlays={this.props.overlayEditing.editingOverlays}
              />
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(TimelineBar);
