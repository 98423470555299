import { Create, Save, AddPhotoAlternate } from '@material-ui/icons';
import React from "react";
import { RouteComponentProps, withRouter } from "react-router";
import { TemplateSummary } from "../models/TemplateSummary";
import { TemplateVersionSummary } from "../models/TemplateVersionSummary";
import { connect } from 'react-redux';
import { Dispatch, bindActionCreators } from 'redux';
import { CampaignActions } from "../../../redux/actions";
import { IAccountsummary, ICampaignSummary} from '../../../redux/models/CampaignInfo';
import { IRootState, EditorSettingsActions } from "../../../redux";
import { PlayerApiActions } from '../../../api/actions';
import {IDictionary} from '../../../api/models/IDictionary';
import {IRequest} from '../../../api/models/requests/IRequest';

const version: TemplateVersionSummary[] = []
export interface TemplateProps  extends RouteComponentProps<{ templateId: string }> {
  template: TemplateSummary;
  Account: IAccountsummary[];
  Campaign:ICampaignSummary[];
}

export interface TemplateState {
  accountName:string;
  campaignName: string;
}

export interface InfoProps extends TemplateProps {
  infoReceived:typeof CampaignActions.CampaignActions.infoReceived;
  getInfo: typeof CampaignActions.CampaignActions.getInfo;
  getTemplateVersions: typeof PlayerApiActions.actionCreators.getTemplateVersions;
  postTemplateVersion: typeof PlayerApiActions.actionCreators.postTemplateVersion;
  showCampaignInfo: typeof EditorSettingsActions.EditorSettingsActions.showCampaignInfo;
}

const mapStateToProps = (state:IRootState) => {
  return {
    campaignInfo: state.campaign
  }
}
const mapDispatchToProps = (dispatch:Dispatch): Pick<InfoProps, 'infoReceived' | 'getInfo' | 'getTemplateVersions' | 'postTemplateVersion'| 'showCampaignInfo'> => ({
  infoReceived: bindActionCreators(CampaignActions.CampaignActions.infoReceived, dispatch),
  getInfo: bindActionCreators(CampaignActions.CampaignActions.getInfo, dispatch),
  getTemplateVersions: bindActionCreators(PlayerApiActions.actionCreators.getTemplateVersions,dispatch),
  postTemplateVersion : bindActionCreators(PlayerApiActions.actionCreators.postTemplateVersion, dispatch),
  showCampaignInfo: bindActionCreators(EditorSettingsActions.EditorSettingsActions.showCampaignInfo,dispatch)
});

class Template extends React.Component <InfoProps, TemplateState> {
  constructor(props:InfoProps) {
    super(props);
    this.state = {   
      accountName:'',
      campaignName: ''
    }

    this.handleOnClick = this.handleOnClick.bind(this);
    this.changePreviewImg = this.changePreviewImg.bind(this);
  }

  async handleOnClick() {
    let params : IDictionary<string>;
    let request : IRequest<any>
    this.props.history.length = 0
    let newversions = version;
    this.props.showCampaignInfo({showCampaignInfo: false})
    //Version part
    params = {"templateId":this.props.template.id};
    request  = {params};
    const templateVersionsResponse = await this.props.getTemplateVersions(request);
    if (templateVersionsResponse !== null && templateVersionsResponse.ok&& templateVersionsResponse.versions !== null) { 
      newversions =  templateVersionsResponse.versions.slice(-1)
      this.props.history.push(`/templates/${this.props.template.id}/versions/${newversions[0].versionNumber}/edit`,null);
      this.props.getInfo({ 
        name:this.props.template.name,
        templateid:this.props.template.id, 
        version: newversions[0].versionNumber,  
        newTemplate: false
      });
    }   
  };

  changePreviewImg() {
    alert("change preview image");
  }
  
  render() {
    return (
      <div className="infoPanel">
        <div className="prevImage" onClick={this.changePreviewImg}>
          <div className="background-div"></div>
          <AddPhotoAlternate className="uploadImgBtn" />
        </div>
        <div className="rowInfo">
          <label>Name</label>
          <textarea className="nameDetails" disabled value={this.props.template.name}></textarea>
        </div>
        <div className="rowInfo">
          <label>Account</label>
          <textarea className="nameDetails" value={this.props.template.accountId ? this.props.template.accountId : "-"} disabled></textarea>
        </div>
        <div className="rowInfo">
          <label>Campaign</label>
          <textarea className="nameDetails" value={this.props.template.campaignId ?  this.props.template.campaignId  : "-"} disabled></textarea>
        </div>
        <div className="rowInfo">
          <label>Created</label>
          <textarea className="nameDetails" value={this.props.template.createdAt} disabled></textarea>
        </div>
        <button className="btn-with-border" onClick={this.handleOnClick}>
          <span>Edit</span> <Create className="editBtn" /><Save className="saveBtn" />
        </button>
      </div>
    );
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Template));
