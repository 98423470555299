import React from 'react';
import { connect } from 'react-redux';
import { Dispatch, bindActionCreators } from 'redux';
import { IRootState, EditorSettingsActions } from '../../../../redux';

import './styles/VariablesBtn.scss';

export interface VariablesBtnProps {
    codeEditorVisible:boolean,
    showVariablesEditor:typeof EditorSettingsActions.EditorSettingsActions.showVariablesEditor
}

const mapStateToProps = (state:IRootState) => {
    return {
        codeEditorVisible: state.editorSettings.codeEditor
    }
}

const mapDispatchToProps = (dispatch:Dispatch): Pick<VariablesBtnProps, 'showVariablesEditor' > => ({
    showVariablesEditor: bindActionCreators(EditorSettingsActions.EditorSettingsActions.showVariablesEditor, dispatch)
});

const VariablesBtn = (props:VariablesBtnProps) => {
    return (
        <div 
            className="variables-btn noselect"
            onClick={ () => { props.showVariablesEditor({ variablesEditor:true }); } }
            style={ !props.codeEditorVisible ? {display:'none'} : {display:'block'} }
        >
            &nbsp;&#123; var &#125;
        </div>
    )
}

export default connect(mapStateToProps, mapDispatchToProps)(VariablesBtn);