import { IVariableEditorState } from "../models/IVariableEditorState";
import { EditorSettingsActions } from "../actions/EditorSettingsActions";
import { reducerWithInitialState } from "typescript-fsa-reducers";

const initialSettings:IVariableEditorState = {
    index: -1,
    deletefunction: false,
    editorVisible: false,
    addNewfunction: false,
    readyToPaint: false,
    readyTopBar: false,
    editingSubtitles: false,
    editingOverlays: true,
    subtitlesReady: false,
    codeEditor: false,
    loading: true,
    variablesEditor: false,
    secondSave: false,
    refreshCanvas: false,
    showAddSceneModal: false,
    deletedLastScene:false,
    changeToNewScene: false,
    editingSceneInfo: false,
    showCampaignInfo: false
}

export const editorSettingsReducer = reducerWithInitialState(initialSettings)
    .case(EditorSettingsActions.overlayToEdit, ( state, { index, editorVisible } ) => {
        let c:IVariableEditorState = { ...state };

        c.index = index;
        c.deletefunction = false;
        c.editorVisible = editorVisible;
        c.addNewfunction = false;
        c.readyToPaint = false;      

        return { ...c }
    })
    .case(EditorSettingsActions.overlayToDelete, ( state ) => {
        let c:IVariableEditorState = { ...state };

        if(c.index >= 0) {            
            c.deletefunction = true;
            c.editorVisible = false;
            c.addNewfunction = false;
            c.readyToPaint = false;
        }

        return { ...c }
    })
    .case(EditorSettingsActions.overlayToAddNew, ( state ) => {
        let c:IVariableEditorState = { ...state };

        c.index = -1;
        c.addNewfunction = true;
        c.editorVisible = true;
        c.readyToPaint = false;

        return { ...c }
    })
    .case(EditorSettingsActions.closeSettings, ( state ) => {
        let c:IVariableEditorState = { ...state };

        c.index = -1;
        c.deletefunction = false;
        c.addNewfunction = false;
        c.editorVisible = false;
        c.readyToPaint = false;

        return { ...c }
    })
    .case(EditorSettingsActions.refreshOverlays, ( state ) => {
        let c:IVariableEditorState = { ...state };

        c.readyToPaint = true;

        return { ...c }
    })
    .case(EditorSettingsActions.showSubtitlesEdition, ( state ) => {
        let c:IVariableEditorState = { ...state };

        c.editingOverlays = false;
        c.editingSubtitles = true;

        return { ...c }
    })
    .case(EditorSettingsActions.showOverlaysEdition, ( state ) => {
        let c:IVariableEditorState = { ...state };

        c.editingSubtitles = false;
        c.editingOverlays = true;

        return { ...c }
    })
    .case(EditorSettingsActions.subtitlesPainted, ( state, { subtitlesReady } ) => {
        let c:IVariableEditorState = { ...state };

        c.subtitlesReady = subtitlesReady;

        return { ...c }
    })
    .case(EditorSettingsActions.showCodeEditor, ( state , { codeEditor } ) => {
        let c:IVariableEditorState = { ...state };

        c.codeEditor = codeEditor;

        return { ...c }
    })
    .case(EditorSettingsActions.refreshTopBar, ( state, { readyTopBar } ) => {
        let c:IVariableEditorState = { ...state };

        c.readyTopBar = readyTopBar;

        return { ...c }
    })
    .case(EditorSettingsActions.loadingData, ( state , { loading } ) => {
        let c:IVariableEditorState = { ...state };

        c.loading = loading;

        return { ...c }
    })
    .case(EditorSettingsActions.showVariablesEditor, ( state, { variablesEditor }) => {
        let c:IVariableEditorState = { ...state };

        c.variablesEditor = variablesEditor;

        return { ...c }
    })
    .case(EditorSettingsActions.resetStore, () => {
        return { ...initialSettings }
    })
    .case(EditorSettingsActions.autoSave, (state, { secondSave }) => {
        return {
            ...state,
            secondSave: secondSave
        }
    })
    .case(EditorSettingsActions.refreshCanvas, ( state, { refreshCanvas } ) => {
        return {
            ...state,
            refreshCanvas: refreshCanvas
        }
    })
    .case(EditorSettingsActions.showAddScene, (state, { showAddSceneModal, deletedLastScene }) => {
        return {
            ...state,
            showAddSceneModal:showAddSceneModal,
            deletedLastScene:deletedLastScene
        }
    })
    .case(EditorSettingsActions.changeScene, (state, { changeToNewScene }) => {
        return {
            ...state,
            changeToNewScene: changeToNewScene
        }
    })
    .case(EditorSettingsActions.editingScene, ( state, { editingSceneInfo }) => {
        return {
            ...state,
            editingSceneInfo: editingSceneInfo
        }
    })
    .case(EditorSettingsActions.showCampaignInfo,(state,{showCampaignInfo}) =>{
        return {
            ...state,
            showCampaignInfo: showCampaignInfo
        }
    });
