type JSONTryParseResult = {
  isParsed: true;
  json: any;
} | {
  isParsed: false;
  validationError: string;
};

export const jsonTryParse = (text: string, reviver?: (key: any, value: any) => any): JSONTryParseResult => {
  try {
    const json = JSON.parse(text, reviver);
    return { isParsed: true, json };
  } catch (validationError) {
    return {
      isParsed: false,
      validationError: validationError.message
    };
  }
}