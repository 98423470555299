import { createStyles, Grid, Theme, WithStyles, withStyles } from '@material-ui/core';
import schema from'@videosmart/player-template/lib/schema.json';
import * as monacoEditor from 'monaco-editor/esm/vs/editor/editor.api';
import React from 'react';
import MonacoEditor from 'react-monaco-editor';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { TemplateActions, OverlaysActions, EditorSettingsActions } from '../../../redux/actions';
import { IRootState } from '../../../redux/models';
import CloseEditorBtn from './CloseJsonEditorBtn/CloseJsonEditorBtn';
import VariablesBtn from './VariablesBtn/VariablesBtn';
import EditorLegendBtn from './EditorLegendBtn/EditorLegendBtn';

import './styles/monacoEditor.scss';

const styles = (theme: Theme) => createStyles({
  root: {
    width: '100%',
    height: '100%'
  },
  editor: {
    position: 'relative',
    borderLeft: '1px solid #000000'
  },
});

// Register player template schema
monacoEditor.languages.json.jsonDefaults.setDiagnosticsOptions({
  validate: true,
  schemas: [{
    uri: 'http://player.videosmart.com/schema',
    fileMatch: ['*player.template.json'],
    schema: schema
  }]
});

export interface IAdvancedEditorProps extends WithStyles<typeof styles> {
  template: string;
  variableArray: any;
  Alltemplate: any;
  codeEditor: boolean;
  updateTemplate: typeof TemplateActions.actionCreators.updateTemplate;
  addVariable: typeof TemplateActions.asyncActionCreators.addVariable;
  updateOverlay: typeof OverlaysActions.OverlaysActions.updateOverlay;
  updateOverlayFromJson:typeof OverlaysActions.OverlaysActions.updateOverlayFromJson;
  showVariablesEditor:typeof EditorSettingsActions.EditorSettingsActions.showVariablesEditor;
  refreshOverlays:typeof EditorSettingsActions.EditorSettingsActions.refreshOverlays;
}

export interface OverlayState {
  options:any;
  showLegend:boolean;
}

const mapStateToProps = (state: IRootState) => {
  return {
    template: state.template.present.text,
    variableArray: state.template.present.VariableArray,
    Alltemplate: state.template.present,
    codeEditor: state.editorSettings.codeEditor
  }
}

const mapDispatchToProps = (dispatch: Dispatch): Pick<IAdvancedEditorProps, 'updateTemplate' | 'updateOverlay' | 'showVariablesEditor' | 'addVariable' | 'updateOverlayFromJson' | 'refreshOverlays' > => ({
  updateTemplate: bindActionCreators(TemplateActions.actionCreators.updateTemplate, dispatch),
  addVariable: bindActionCreators(TemplateActions.asyncActionCreators.addVariable, dispatch),
  updateOverlay: bindActionCreators(OverlaysActions.OverlaysActions.updateOverlay, dispatch),
  updateOverlayFromJson: bindActionCreators(OverlaysActions.OverlaysActions.updateOverlayFromJson, dispatch),
  showVariablesEditor: bindActionCreators(EditorSettingsActions.EditorSettingsActions.showVariablesEditor, dispatch),
  refreshOverlays: bindActionCreators(EditorSettingsActions.EditorSettingsActions.refreshOverlays, dispatch)
});

class JsonEditor extends React.Component<IAdvancedEditorProps, OverlayState> {
  model:any;
  //classes:any;
  editorRef:any;
  newValue:any;
  newEvent:any;
  newTextChange:string;

  constructor(props: IAdvancedEditorProps) {
    super(props);
   
    if (this.model !== undefined){
      this.model = monacoEditor.editor.createModel(
      props.template,
      "json" 
      //monacoEditor.Uri.parse("inmemory://model/player.template.json"));
      //monacoEditor.Uri.parse("http://player.videosmart.com/schema"));
      );
      this.model.updateOptions({ tabSize: 2 });    
    }

    this.state = {
      options: {
        automaticLayout: true,
        model: this.model,
        readOnly: false,
        selectOnLineNumbers: true,
        wordBasedSuggestions: false,
      },
      showLegend: false
    }

    this.newTextChange = '';
    this.editorRef = React.createRef();

    this.saveVarInTemplate = this.saveVarInTemplate.bind(this);
    this.addVariableArray = this.addVariableArray.bind(this);
    this.handleOnChange = this.handleOnChange.bind(this);
    this.showHideLegend = this.showHideLegend.bind(this);
  }

  componentDidMount() {    
    document.addEventListener('contextmenu', function(event){ event.preventDefault(); });

    this.editorRef.current.editor.addCommand(monacoEditor.KeyCode.Enter, () => {
      this.saveVarInTemplate();
    });
  }

  saveVarInTemplate() {
    this.props.updateTemplate(this.newValue);
    this.props.updateOverlayFromJson(
      { 
        currentScene:this.props.Alltemplate.currentScene,
        payload: this.newValue
      }
    ); 
    this.props.refreshOverlays( { } );
  }

  addVariableArray(e:any, value:any){
    let first: any;

    first = e.event.target.innerText.substring(
      e.event.target.innerText.lastIndexOf('{'), 
      e.event.target.innerText.lastIndexOf('}') + 1
    );

    this.props.addVariable({
      text: first,
      line: e.target.position.lineNumber
    });
    
    this.props.updateTemplate(value);
    this.props.showVariablesEditor({ variablesEditor:true });
  }

  handleOnChange (value:string, event:monacoEditor.editor.IModelContentChangedEvent){
    this.newValue = value;

    this.editorRef.current.editor.onContextMenu( (e) => {
      // here we check the content that has changed is not the same that for the previous call
      // this way we avoid having multiple call one for each time the onChange event was triggered
      if(this.newTextChange !== e.event.browserEvent.toElement.textContent) {
        this.newEvent = e;
        this.addVariableArray(this.newEvent, this.newValue);
        this.newTextChange = e.event.browserEvent.toElement.textContent;
      }
    });
  }

  showHideLegend() {
    this.setState( (prevState:any) => {
      return {
        showLegend: !prevState.showLegend
      }
    });
  }

  render() {
    const { classes } = this.props;

    return (
      <Grid
        className={classes.root}
        container
        direction={"column"}
      >
        <CloseEditorBtn />
        <VariablesBtn />
        <EditorLegendBtn
          visible={this.props.codeEditor}
          showHideLegend={this.showHideLegend}
        />
        <Grid item xs className={classes.editor}>
            <MonacoEditor
              language="json"
              theme="vs-dark"
              value={this.props.template}
              onChange={this.handleOnChange}
              options={this.state.options}
              ref={this.editorRef}            
            />
            {this.props.codeEditor && this.state.showLegend ?
              <div className="editor-legend">
                <div className="inner-legend">
                  <span className="label">when editing:</span>
                  <span>
                    press ENTER to save template<br />
                    RIGHT-CLICK to edit variables
                  </span>
                </div>
              </div>
              : <></> }
        </Grid>
      </Grid>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(JsonEditor));