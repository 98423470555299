import { createApiAction,createApiAction2 } from './createApiAction';
import { thunkToAction } from 'typescript-fsa-redux-thunk';
import { actionCreatorFactory } from 'typescript-fsa';
import {ITemplateVersionResponse, ITemplatesResponse, ITemplateVersionsResponse, ICreateTemplateVersionResponse} from '../models/responses';
import {ICreateTemplateVersionRequest} from '../models/requests/ICreateTemplateVersionRequest';
import configuration from '../../services/configuration';

const create = actionCreatorFactory('PLAYER');
const apiRoot = configuration.playerApiUrl;
const campaignManagerRoot = configuration.campaignManagerApi;

enum Type {
  GetTemplateVersion = 'GET_TEMPLATE_VERSION',
  GetTemplates = 'GET_TEMPLATES',
  GetTemplateVersions = 'GET_TEMPLATE_VERSIONS',
  PostTemplates = 'POST_TEMPLATES',
  PostTemplateVersion = 'POST_TEMPLATE_VERSION',
  GetAccounts = 'GET_ACCOUNTS',
  GetCampaigns = 'GET_CAMPAIGNS'
}

// Async Action Creators
export const asyncActionCreators = {
    getTemplateVersionCreator: createApiAction<any, ITemplateVersionResponse>(
        'GET',
        create,
        Type.GetTemplateVersion,
        `${apiRoot}/templates/{templateId}/versions/{versionNumber}`,
        true
      ),
      getTemplatesCreator: createApiAction<any, ITemplatesResponse>(
        'GET',
        create,
        Type.GetTemplates,
        `${apiRoot}/templates`,
        true
      ),
      postTemplatesCreator: createApiAction<any, any>(
        'POST',
        create,
        Type.PostTemplates,
        `${apiRoot}/templates`,
        true
      ),
      getTemplateVersionsCreator: createApiAction<any, ITemplateVersionsResponse>(
        'GET',
        create,
        Type.GetTemplateVersions,
        `${apiRoot}/templates/{templateId}/versions`,
        true
      ),
      postTemplateVersion: createApiAction<ICreateTemplateVersionRequest, ICreateTemplateVersionResponse>(
      'POST',
        create,
        Type.PostTemplateVersion,
        `${apiRoot}/templates/{templateId}/versions`,
        true
      ),
      getAccountsCreator: createApiAction2<any, any>(
        'GET',
        create,
        Type.GetAccounts,
        `${campaignManagerRoot}/accounts`,
        false
      ),
      getCampaignsCreator: createApiAction2<any, any>(
        'GET',
        create,
        Type.GetCampaigns,
        `${campaignManagerRoot}/campaigns`,
        false
      ),
}


// Action Creators
export const actionCreators = {
    getTemplateVersion: thunkToAction(asyncActionCreators.getTemplateVersionCreator.action),
    getTemplates: thunkToAction(asyncActionCreators.getTemplatesCreator.action),
    postTemplates: thunkToAction(asyncActionCreators.postTemplatesCreator.action),
    getTemplateVersions: thunkToAction(asyncActionCreators.getTemplateVersionsCreator.action),
    postTemplateVersion: thunkToAction(asyncActionCreators.postTemplateVersion.action),
    getAccounts:  thunkToAction(asyncActionCreators.getAccountsCreator.action),
    getCampaigns:  thunkToAction(asyncActionCreators.getCampaignsCreator.action)
}