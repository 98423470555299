import { makeStyles } from '@material-ui/core';
import React from 'react';
import { connect } from 'react-redux';

import { IRootState } from '../../../redux';
import { selectTracker } from '../../../redux/selectors';

import PreviewCanvas from './components/PreviewCanvas';
import { Dimensions } from './models/Dimensions';
import { Tracker } from './models/Tracker';

const useStyles = makeStyles({
  root: {
    width: '100%',
    height: '100%',
    backgroundColor: '#2c2c2c'
  },
});

export interface PreviewProps  {
  dimensions?: Dimensions;
  trackers: Tracker[];
  // *******  added 'videoSource' to get the url of the video from the json template in the redux store
  videoSource: string;
};

const mapStateToProps = (state: IRootState) => ({
  trackers: selectTracker(state.template.present.json, state.template.present.currentScene, 0),
  videoSource: state.template.present.json.scenes[state.template.present.currentScene].videoSource.source
});

const Preview = (props: PreviewProps) => {
  const classes = useStyles();

  const width = (props.dimensions && props.dimensions.width) || 960;
  const height = (props.dimensions && props.dimensions.height) || 540;

  return (
    <div className={classes.root}>
      <PreviewCanvas 
        width={width} 
        height={height} 
        trackers={props.trackers} 
        videoSrc={props.videoSource} 
      />
    </div>
  );
};

export default connect(mapStateToProps)(Preview);
