import React from 'react';
import { connect } from 'react-redux';
import { Dispatch, bindActionCreators } from 'redux';
import { IRootState } from '../../../redux';
import { PlayerApiActions } from '../../../api/actions';
import { TemplateActions, EditorSettingsActions, OverlaysActions, CampaignActions } from '../../../redux/actions';
import { 
  Button,
  Tooltip, 
  Select, 
  MenuItem, 
  InputLabel,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle } from '@material-ui/core';
import Zoom from '@material-ui/core/Zoom';
import { VideoSourceType, S3VideoSourceMessage } from '@videosmart/player-template';
import './styles/AddSceneModal.scss';

export interface AddSceneModalProps {
    editingSceneInfo: boolean,
    newTemplateInfo:boolean,
    template:any,
    pastTemp:any,
    campinfo:any,
    scenes:any,
    currentScene:number,
    changeToNewScene:boolean,
    deletedLastScene:boolean,
    showAddSceneModal:boolean,
    setSceneBasicInfo:typeof TemplateActions.asyncActionCreators.setSceneBasicInfo,
    deleteSceneFromTemplate:typeof TemplateActions.asyncActionCreators.deleteSceneFromTemplate,
    addSceneToJson: typeof TemplateActions.asyncActionCreators.addSceneToJson,
    updateTemplate: typeof TemplateActions.actionCreators.updateTemplate,
    resetOverlaysInScene:typeof OverlaysActions.OverlaysActions.resetOverlaysInScene,
    updateOverlayFromJson:typeof OverlaysActions.OverlaysActions.updateOverlayFromJson,
    infoReceived: typeof CampaignActions.CampaignActions.infoReceived,
    refreshOverlays: typeof EditorSettingsActions.EditorSettingsActions.refreshOverlays,
    showAddScene: typeof EditorSettingsActions.EditorSettingsActions.showAddScene,
    changeScene: typeof EditorSettingsActions.EditorSettingsActions.changeScene,
    refreshCanvas: typeof EditorSettingsActions.EditorSettingsActions.refreshCanvas,
    editingScene: typeof EditorSettingsActions.EditorSettingsActions.editingScene,
    autoSave: typeof EditorSettingsActions.EditorSettingsActions.autoSave,
    postTemplateVersion: typeof PlayerApiActions.actionCreators.postTemplateVersion,
    getInfo: typeof CampaignActions.CampaignActions.getInfo, 
    closeSettings :  typeof EditorSettingsActions.EditorSettingsActions.closeSettings,
    showcampaing: typeof EditorSettingsActions.EditorSettingsActions.showAddScene
}

export interface AddSceneModalState {
    error: boolean,
    itwasdeleted:boolean,
    sceneType: any,
    id: string,
    newScene: any, //UrlVideoSource,
    newSceneS3: S3VideoSourceMessage,
    MissingName:boolean,
    MissingUrl:boolean,
    ValidUrl:boolean,
    InvalidS3Region:boolean,
    MissingKey:boolean,
    MissingBucket:boolean,
    MissingRegion:boolean,
    ValidId: boolean,
    changing: boolean,
    DetectChange:boolean,
    DetectDelete:boolean,
}

interface IAwsRegion {
    name: string,
    code: string
}

const mouseClickEvents:any = ['mousedown', 'click', 'mouseup'];
const local = `${window.location.protocol}//${window.location.hostname}${window.location.port ? `:${window.location.port}` : ''}`;

const mapStateToProps = (state:IRootState) => {
    return {
        deletedLastScene: state.editorSettings.deletedLastScene,
        showAddSceneModal: state.editorSettings.showAddSceneModal,
        changeToNewScene: state.editorSettings.changeToNewScene,
        template: state.template.present,
        pastTemp: state.template.past,
        scenes: state.template.present.json.scenes,
        currentScene: state.template.present.currentScene,
        editingSceneInfo: state.editorSettings.editingSceneInfo,
        newTemplateInfo: state.campaign.newTemplate,
        campinfo: state.campaign
    }
}

const mapDispatchToProps = (dispatch:Dispatch): Pick<AddSceneModalProps, 'editingScene' | 'showcampaing' |'closeSettings' | 'infoReceived' | 'postTemplateVersion' | 'updateTemplate' |'autoSave'| 
'refreshCanvas' | 'resetOverlaysInScene' | 'updateOverlayFromJson' | 'deleteSceneFromTemplate' | 'setSceneBasicInfo' | 'changeScene' | 'showAddScene' | 'addSceneToJson' | 'refreshOverlays' | 'getInfo'> => ({  
    addSceneToJson: bindActionCreators(TemplateActions.asyncActionCreators.addSceneToJson, dispatch),   
    setSceneBasicInfo: bindActionCreators(TemplateActions.asyncActionCreators.setSceneBasicInfo, dispatch),
    updateTemplate: bindActionCreators(TemplateActions.actionCreators.updateTemplate, dispatch),
    deleteSceneFromTemplate: bindActionCreators(TemplateActions.asyncActionCreators.deleteSceneFromTemplate, dispatch),
    updateOverlayFromJson: bindActionCreators(OverlaysActions.OverlaysActions.updateOverlayFromJson, dispatch),
    resetOverlaysInScene: bindActionCreators(OverlaysActions.OverlaysActions.resetOverlaysInScene, dispatch),
    infoReceived: bindActionCreators(CampaignActions.CampaignActions.infoReceived, dispatch),   
    refreshOverlays: bindActionCreators(EditorSettingsActions.EditorSettingsActions.refreshOverlays, dispatch),
    showAddScene: bindActionCreators(EditorSettingsActions.EditorSettingsActions.showAddScene, dispatch),
    showcampaing: bindActionCreators(EditorSettingsActions.EditorSettingsActions.showCampaignInfo, dispatch),
    changeScene: bindActionCreators(EditorSettingsActions.EditorSettingsActions.changeScene, dispatch),
    refreshCanvas: bindActionCreators(EditorSettingsActions.EditorSettingsActions.refreshCanvas, dispatch),
    editingScene: bindActionCreators(EditorSettingsActions.EditorSettingsActions.editingScene, dispatch),
    closeSettings:bindActionCreators(EditorSettingsActions.EditorSettingsActions.closeSettings, dispatch),
    autoSave: bindActionCreators(EditorSettingsActions.EditorSettingsActions.autoSave, dispatch),
    postTemplateVersion: bindActionCreators(PlayerApiActions.actionCreators.postTemplateVersion, dispatch),
    getInfo: bindActionCreators(CampaignActions.CampaignActions.getInfo, dispatch),
});


class AddSceneModal extends React.Component<AddSceneModalProps, AddSceneModalState> {
    VideoSourceTypeArray:any;
   
    constructor(props:AddSceneModalProps) {
        super(props);

        this.state = {
            error: false,
            itwasdeleted: false,
            sceneType: '',
            id: '',
            newScene: {
                url: '',
                frameRate: 25
            },
            newSceneS3: {
                s3Bucket:  '',
                s3Key: '',
                isPrivate: false,
                s3Region: '',
                frameRate: 25,
                type: VideoSourceType.S3
            },
            MissingName:false,
            MissingUrl:false,
            ValidUrl:false,
            InvalidS3Region:false,
            MissingKey:false,
            MissingBucket:false,
            MissingRegion:false,
            ValidId:false,
            changing:false,
            DetectChange:false,
            DetectDelete:false,
        }
    
        this.VideoSourceTypeArray = Object.values(VideoSourceType);
        
        this.EmptyValues = this.EmptyValues.bind(this);
        this.getSceneInitialValues = this.getSceneInitialValues.bind(this);
        this.getNewSceneDetails = this.getNewSceneDetails.bind(this);
        this.cleanModal = this.cleanModal.bind(this);
        this.handleAddScene = this.handleAddScene.bind(this);
        this.handleDeleteScene = this.handleDeleteScene.bind(this);
        this.handleUpdateScene = this.handleUpdateScene.bind(this);
        this.handleCancel = this.handleCancel.bind(this);
        this.validateName = this.validateName.bind(this);
       // this.AutosaveTemplate = this.AutosaveTemplate.bind(this);
    }

    getAwsRegions = (): IAwsRegion[] => {
        const awsRegions = require('aws-regions');
        const namesAndCodes = awsRegions.list().map( x => ({
            name: x.name,
            code: x.code
        } as IAwsRegion));

        return namesAndCodes;
    }

    componentDidMount() {
        let pasttemplate = this.props.pastTemp.slice(-1)
        if(this.props.newTemplateInfo){
            if(pasttemplate[0].json.scenes[this.props.currentScene] && (pasttemplate[0].json.scenes[this.props.currentScene].videoSource.source === "")){
           this.props.showAddScene({ showAddSceneModal: true, deletedLastScene:false});
            }else{
                this.props.showAddScene({ showAddSceneModal: true, deletedLastScene:true});
            }
            this.props.editingScene({ editingSceneInfo: true });
            if(this.props.editingSceneInfo) {

                this.getSceneInitialValues();
            }
            if(!this.props.editingSceneInfo) {  
                this.EmptyValues();
            }
        }
    }

    componentDidUpdate(prevProps:any) {
        
        if(this.props.editingSceneInfo && (prevProps !== this.props)) {
            if(!this.props.newTemplateInfo){
                this.getSceneInitialValues();
               
            }else{
               this.EmptyValues();
            }
        }
        if(!this.props.editingSceneInfo && (prevProps !== this.props)) {  
            this.EmptyValues();
        }
    }
    
    EmptyValues(){
        this.setState( () => {
            return {
                sceneType: '',
                id: '',
                newScene: {
                    url: '',
                    frameRate: 25
                },
                newSceneS3: {
                    s3Bucket:  '',
                    s3Key: '',
                    s3Region: '',
                    isPrivate: false,
                    frameRate: 25,
                    type: VideoSourceType.S3
                }
            }
        });
    }

    getSceneInitialValues() {        
        if(this.props.scenes[this.props.currentScene].videoSource.type === "url") {            
            const newSource = {
                frameRate: this.props.scenes[this.props.currentScene].videoSource.frameRate,
                url: this.props.scenes[this.props.currentScene].videoSource.source
            }
            this.setState( () => {
                return {
                    id: this.props.scenes[this.props.currentScene].id,
                    sceneType: this.props.scenes[this.props.currentScene].videoSource.type,
                    newScene: newSource
                }
            });
        }
        else if (this.props.scenes[this.props.currentScene].videoSource.type === "s3") {
            const newSourceS3 = {
                type: this.props.scenes[this.props.currentScene].videoSource.type,
                s3Bucket: this.props.scenes[this.props.currentScene].videoSource.s3Bucket,
                s3Key: this.props.scenes[this.props.currentScene].videoSource.s3Key,
                s3Region: this.props.scenes[this.props.currentScene].videoSource.s3Region,
                isPrivate: this.props.scenes[this.props.currentScene].videoSource.isPrivate,
                frameRate: this.props.scenes[this.props.currentScene].videoSource.frameRate
            }
            this.setState( () => {
                return {
                    id: this.props.scenes[this.props.currentScene].id,
                    sceneType: this.props.scenes[this.props.currentScene].videoSource.type,                 
                    newSceneS3: newSourceS3
                }
            });
        }
    }
    
    getNewSceneDetails(dataPassed:number, value:any){
        let updatedData:any = this.state.newScene;
        let updatedS3:any = this.state.newSceneS3;
        let updatedID:string = this.state.id;

        switch (dataPassed) {
            case 1: {
                updatedID = value;
                break;
            }
            case 2: {
                //if((value.contains("https://")) && (value.contains(".mp4"))){
                    updatedData.url = value;
               //x      }          
                break;
            }
            case 3: {
                updatedData.frameRate = value;
                break;
            }
            case 4: {
                updatedS3.s3Bucket = value;
                break;
            }
            case 5: {
                updatedS3.s3Key = value;
                break;
            }
            case 6: {
                updatedS3.frameRate = value;
                break;
            }
            case 7: {
                updatedS3.s3Region = value;
                break;
            } 
        }

        switch (dataPassed) {
            case 1: {
                this.setState(() => {
                    return {
                        id: updatedID
                    }
                });
                break;
            }
            case 2:
            case 3: {
                this.setState(() => {
                    return {
                        newScene: updatedData
                    }
                });
                break;
            }
            case 4:
            case 5:
            case 6:
            case 7: {
                this.setState(() => {
                    return {
                        newSceneS3: updatedS3
                    }
                });
            }
        }
    }

    cleanModal() {}

    validateName(){
        if(this.state.changing){
            this.props.template.json.scenes.forEach((infoscene:any)=>{
                if( infoscene.id !== undefined){                  
                    if(this.state.id ===  infoscene.id){
                        this.setState(() => ({ ValidId:true}));                   
                    }                   
                }
            })
        }
    }

    async handleAddScene() {
        const headers: any = {};
        headers['Access-Control-Request-Method'] = 'GET, POST';
        headers['Origin'] = local;

        if(this.state.id === ''){
            this.setState(() => ({ MissingName: true }));
        }     
        if(this.state.newScene.url === ''){
            this.setState(() => ({ MissingUrl: true }));
        }
       
        if(this.state.newSceneS3.s3Key === ''){
            this.setState(() => ({ MissingKey: true })); 
        }
        if(this.state.newSceneS3.s3Bucket === ''){
            this.setState(() => ({ MissingBucket: true })); 
        }
        if(this.state.newSceneS3.s3Region === ''){
            this.setState(() => ({ MissingRegion: true })); 
        }
        if(this.state.sceneType === 'url') {
            if(this.state.id !== ''){
               
                let url = "https://cors-anywhere.herokuapp.com/" + this.state.newScene.url;
               await fetch(url,{
                   method: 'GET',
                   credentials: 'same-origin',
                   headers,
               })
                .then((response) => {
                    if(response.ok) {
                        if(!this.state.ValidId){
                            this.props.addSceneToJson({
                                sourceType: this.state.sceneType,
                                sceneId: this.state.id,
                                videoSource: this.state.newScene,
                                s3Source: this.state.newSceneS3,
                                changeToScene: this.props.changeToNewScene
                            });
                            this.props.infoReceived({ newTemplate: false });
                            this.props.refreshOverlays({});
                            this.props.showAddScene({ showAddSceneModal: false });   
                            this.setState(() => ({ ValidUrl: false, InvalidS3Region: false,  MissingUrl:false, MissingKey:false, MissingBucket:false })); 
                        }                     
                    }
                })              
                .catch((error) => {

                    this.setState(() => ({ ValidUrl: true }));
                })
                
                
            } 
        }else if(this.state.sceneType ==='s3') {
            if( this.state.newSceneS3.s3Key !== '' && this.state.newSceneS3.s3Bucket !== '' && this.state.newSceneS3.s3Region !== ''){
                let video = "https://cors-anywhere.herokuapp.com/https://" + this.state.newSceneS3.s3Bucket + ".s3-" + this.state.newSceneS3.s3Region + ".amazonaws.com/" + this.state.newSceneS3.s3Key;
                await fetch(video,{
                    method: 'GET',
                    credentials: 'same-origin',
                    headers,
                })
                .then((response) => {
                    if(response.ok) {
                        if(!this.state.ValidId){
                            this.props.addSceneToJson({
                                sourceType: this.state.sceneType,
                                sceneId: this.state.id,
                                videoSource: this.state.newScene,
                                s3Source: this.state.newSceneS3,
                                changeToScene: this.props.changeToNewScene
                            });
                            this.props.infoReceived({ newTemplate: false });
                            this.props.refreshOverlays({});
                            this.props.showAddScene({ showAddSceneModal: false }); 
                            this.setState(() => ({ ValidUrl: false, InvalidS3Region: false, MissingUrl:false, MissingKey:false, MissingBucket:false })); 
                        }   
                    }
                })              
                .catch((error) => {
                    this.setState(() => ({ ValidUrl: true }));
                })
               
            }
        }        
    }

    handleDeleteScene() {

        this.setState(() => ({ MissingName: false, MissingUrl: false, ValidUrl:false, InvalidS3Region: false, MissingKey: false ,MissingBucket: false, MissingRegion: false   }));
              
        this.props.deleteSceneFromTemplate({ currentScene: this.props.currentScene});

        if(this.props.scenes.length === 1) {
            this.props.infoReceived({ newTemplate: true });
            this.props.resetOverlaysInScene({});
        }
        else {
            this.props.editingScene({ editingSceneInfo: false });
            this.props.updateOverlayFromJson(
            { 
                currentScene:this.props.currentScene,
                payload:this.props.template.text
            });
        }
        this.props.showAddScene({ showAddSceneModal: false, deletedLastScene:false });
        this.props.refreshOverlays({});

        if(this.props.scenes.length === 1) {

            this.props.editingScene({ editingSceneInfo: false });
            this.props.showAddScene({ showAddSceneModal: true, deletedLastScene:true });

        }

        this.props.refreshCanvas({});
    }

    async handleUpdateScene() {
        const headers: any = {};
        headers['Access-Control-Request-Method'] = 'GET, POST';
        if(this.state.id === ''){
            this.setState(() => ({ MissingName: true }));
        }     
        if(this.state.newScene.url === ''){
            this.setState(() => ({ MissingUrl: true }));
        }
        if(this.state.newScene.url !== '' &&  !this.state.newScene.url.includes("https://")){
            this.setState(() => ({ ValidUrl: true }));
        }
        if(this.state.newSceneS3.s3Key === ''){
            this.setState(() => ({ MissingKey: true })); 
        }
        if(this.state.newSceneS3.s3Bucket === ''){
            this.setState(() => ({ MissingBucket: true })); 
        }
        if(this.state.newSceneS3.s3Region === ''){
            this.setState(() => ({ MissingRegion: true })); 
        }
        if(this.state.sceneType ==='url') {
            if(this.state.newScene.url.includes("https://") && this.state.newScene.url.includes(".mp4")){
                let url = "https://cors-anywhere.herokuapp.com/" + this.state.newScene.url;
                await fetch(url,{
                    method: 'GET',
                    credentials: 'same-origin',
                    headers,
                })              
                .then((response) => {
                    if(response.ok) {
                        if(!this.state.ValidId){
                            if(this.state.DetectChange){
                                if(this.state.sceneType ==='url') {
                                    this.props.setSceneBasicInfo( { sourceType: 'url' , newInfo: this.state.newScene, newId: this.state.id });
                                }
                            
                                if(this.props.newTemplateInfo){
                                    this.props.autoSave({ secondSave: true});
                                } 
                                this.props.refreshCanvas({ refreshCanvas: true });                
                                this.props.infoReceived({ newTemplate: false });
                                this.props.editingScene({ editingSceneInfo: false });
                            }
                            this.props.showAddScene({ showAddSceneModal: false});
                        }
                    }
                })              
                .catch((error) => {
                    this.setState(() => ({ ValidUrl: true }));
                })               
            }
        } else if (this.state.sceneType === 's3') {
            if(this.state.newSceneS3.s3Key !== '' && this.state.newSceneS3.s3Bucket !== '' && this.state.newSceneS3.s3Region !== ''){
                let video = "https://cors-anywhere.herokuapp.com/https://" + this.state.newSceneS3.s3Bucket + ".s3-" + this.state.newSceneS3.s3Region + ".amazonaws.com/" + this.state.newSceneS3.s3Key;

                await fetch(video,{
                    method: 'GET',
                    credentials: 'same-origin',
                    headers,
                })
                .then((response) => {
                    if(response.ok) {
                        if(!this.state.ValidId){
                            if(this.state.DetectChange){
                                if (this.state.sceneType === 's3') {
                                    this.props.setSceneBasicInfo({ sourceType: 's3' , newInfo: this.state.newSceneS3, newId: this.state.id });
                                }
                                if(this.props.newTemplateInfo){
                                    this.props.autoSave({ secondSave: true});
                                } 
                               
                                this.props.refreshCanvas({ refreshCanvas: true });
                                this.props.infoReceived({ newTemplate: false });
                                this.props.editingScene({ editingSceneInfo: false });
                            }
                            this.props.showAddScene({ showAddSceneModal: false});
                        }
                    }
                    else{
                        // When AWS answers with 301 Permanent Redirect because the region is wrong
                        this.setState(() => ({ InvalidS3Region: true }));
                    }
                })              
                .catch((error) => {
                    this.setState(() => ({ ValidUrl: true }));
                })                             
            }
        }
    }

    simulateMouseClick(element:any){
        mouseClickEvents.forEach(mouseEventType =>
            element.dispatchEvent(
            new MouseEvent(mouseEventType, {
                view: window,
                bubbles: true,
                cancelable: true,
                buttons: 1
            })
            )
        );
    }

    handleCancel() {
        let b:any = document.querySelector('#nav-back');
        this.setState(() => ({ MissingName: false, MissingUrl: false, ValidUrl:false , MissingKey: false ,MissingBucket: false  }));
        if(this.props.newTemplateInfo) {
            if(this.state.error) {  
                this.props.showAddScene({ showAddSceneModal:false,deletedLastScene:false  });  
                this.props.editingScene({ editingSceneInfo:false });              
                this.props.closeSettings({});
                this.props.showcampaing({showCampaignInfo: true})  
                this.simulateMouseClick(b);
                this.props.infoReceived({ newTemplate: false });
            }
            this.setState(() => {
                return {
                    error: true
                }
            });
        }
        else {
            this.setState(() => {
                return {
                    error: false
                }
            });
            this.props.showAddScene({ showAddSceneModal:false, deletedLastScene:false });  
            this.props.editingScene({ editingSceneInfo:false });
        }
    }

    render() {
        return (
           <>
                <Dialog
                    fullScreen={false}
                    open={this.props.showAddSceneModal}
                    aria-labelledby="editor-settings-modal-title"
                    className="scene-modal"
                    onClose={this.cleanModal}
                >
                    <DialogTitle id="scene-modal-title">{ this.props.editingSceneInfo ? "Edit Scene Details" : "Add New Scene"}</DialogTitle>
                    <DialogContent>
                        <Tooltip 
                            title='For a better user experience use videos with the same resolution'
                            aria-label="video-url"
                            placement="right-end"
                            TransitionComponent={Zoom}
                            classes={{ tooltip: "videoSrc-tooltip"}}
                        >
                            <div className="scene-modal-content"> 
                                {!this.props.editingSceneInfo 
                                    ? 
                                        <span className="add-scene-message">
                                            Start choosing the type of video source: 
                                        </span>
                                    :
                                        <span className="add-scene-message">
                                            Type of video source: 
                                        </span>
                                }
                                    <Select className="selectInput"
                                        data-id="scenes-types"
                                        name="scene-type"
                                        value={this.state.sceneType}
                                        onChange={ (e) => { this.setState({ DetectChange:true,sceneType: e.target.value }) } }
                                    >
                                        {this.VideoSourceTypeArray.map((value:any, index:any) => (
                                            <MenuItem key={Math.floor(Math.random() * 1000 + index)} value={value}>{value}</MenuItem>                               
                                        ))}
                                    </Select>
                               
                                <br /><br />

                                {this.state.sceneType === "url" || this.state.sceneType === "s3" ?                                
                                    <div className="input-wrapper">
                                        {!this.props.editingSceneInfo 
                                            ? 
                                                <InputLabel id="new-scene-video-id-label">
                                                    Give a name to the scene: 
                                                </InputLabel>
                                            :
                                                <InputLabel id="new-scene-video-id-label">
                                                    Name of scene: 
                                                </InputLabel>
                                        }
                                        <input 
                                           // className="scene-name-input"
                                            name="scene" 
                                            value={this.state.id} 
                                            data-id={"new-scene-video-id"} 
                                            placeholder="scene name..."
                                            onChange= {(e) => {this.getNewSceneDetails(1, e.target.value); this.setState({ DetectChange:true,changing: true,MissingName:false, ValidId:false})} } 
                                            onBlur= {this.validateName}/>
                                            {this.state.MissingName ?<span className="error-msg"> Name is required</span>: <></>}
                                            {this.state.ValidId ?<span  className="error-msg"> Name in use</span>: <></>}
                                    </div>
                                    : <></>
                                }

                                {this.state.sceneType === "url" ?   
                                    <>
                                        <div className="input-wrapper">
                                            <InputLabel id="new-scene-video-url-label">URL where the video is hosted:</InputLabel>
                                            <input 
                                                name="videourl" 
                                                value={this.state.newScene.url} 
                                                data-id="new-scene-video-url"
                                                placeholder="the url of the video..."
                                                onChange= {(e) => {this.getNewSceneDetails(2, e.target.value); this.setState({DetectChange:true, MissingUrl:false, ValidUrl:false  })} } />
                                                {this.state.MissingUrl ?<span className="error-msg"> Url is required</span>: <></>}
                                                {this.state.ValidUrl ?<span className="error-msg"> Not a valid url video</span>: <></>}
                                        </div>
                                        <div className="input-wrapper">
                                            <InputLabel id="new-scene-video-rate-label">Frame rate of the video (fps):</InputLabel>
                                            <input 
                                                type="number"
                                                name="framerate"
                                                step={1}
                                                value={this.state.newScene.frameRate}
                                                data-id="new-scene-video-rate"
                                                placeholder={this.state.newScene.frameRate}
                                                onChange= {(e) => {this.getNewSceneDetails(3, e.target.value); this.setState({DetectChange:true})} } />
                                        </div>
                                    </>
                                    :   
                                    <> {this.state.sceneType === "s3" ?
                                        <>
                                            <div className="input-wrapper">
                                                <InputLabel id="new-scene-videos3-bucket-label">s3 Bucket:</InputLabel>
                                                <input 
                                                    name="s3Bucket" 
                                                    value={String(this.state.newSceneS3.s3Bucket)} 
                                                    data-id="new-scene-videos3-bucket"
                                                    placeholder="the s3 bucket of the video..."
                                                    onChange= {(e) => {this.getNewSceneDetails(4, e.target.value); this.setState({DetectChange:true, MissingBucket:false, ValidUrl: false, InvalidS3Region: false })}  } />
                                                     {this.state.MissingBucket ? <span className="error-msg"> s3Bucket is required</span>: <></>}
                                            </div>
                                            <div className="input-wrapper">
                                                <InputLabel id="new-scene-videos3-key-label">s3 Key:</InputLabel>
                                                <input 
                                                    name="s3Key" 
                                                    value={String(this.state.newSceneS3.s3Key)} 
                                                    data-id="new-scene-videos3-key"
                                                    placeholder="the s3 key of the video..."
                                                    onChange= {(e) => {this.getNewSceneDetails(5, e.target.value); this.setState({DetectChange:true, MissingKey:false, ValidUrl: false, InvalidS3Region: false })}  } />
                                                    {this.state.MissingKey ?<span className="error-msg"> s3Key is required</span>: <></>}
                                            </div>
                                            <div className="input-wrapper">
                                                <InputLabel id="new-scene-videos3-region-label">s3 Region:</InputLabel>
                                                <Select className="selectInput"
                                                    data-id="aws-regions"
                                                    name="aws-regions"
                                                    value={this.state.newSceneS3.s3Region ? String(this.state.newSceneS3.s3Region) : ""} 
                                                    onChange= {(e) => 
                                                        {
                                                            this.getNewSceneDetails(7, e.target.value); 
                                                            this.setState({DetectChange:true, MissingRegion:false, ValidUrl: false, InvalidS3Region: false })
                                                        }  
                                                    }
                                                >
                                                    {this.getAwsRegions().map((region: IAwsRegion, index:any) => (
                                                        <MenuItem key={index} value={region.code}>{region.name}</MenuItem>                               
                                                    ))}
                                                </Select>
                                                {this.state.MissingRegion ?<span className="error-msg"> s3 Region is required</span>: <></>}
                                                {this.state.InvalidS3Region ?<p className="error-msg"> Wrong S3 region for provided video</p>: <></>}
                                            </div>
                                            <div className="input-wrapper">
                                                <InputLabel id="new-scene-video-rate-label">Frame rate of the video (fps):</InputLabel>
                                                <input 
                                                    type="number"
                                                    name="framerate"
                                                    step={1}
                                                    value={this.state.newSceneS3.frameRate}
                                                    data-id="new-scene-video-rate"
                                                    placeholder={String(this.state.newSceneS3.frameRate)}
                                                    onChange= {(e) => {this.getNewSceneDetails(6, e.target.value); this.setState({DetectChange:true})} } />
                                                   
                                            </div>
                                        </>
                                        : <></>
                                        }
                                    </>
                                }

                                {this.state.error ? 
                                    <span className="error-msg">Please specify a video source or you won't be able to continue</span>
                                : <></>
                                }
                            </div>
                        </Tooltip>
                    </DialogContent>
                    <DialogActions>
                        {this.props.editingSceneInfo && !this.props.newTemplateInfo ? 
                            <Button  onClick={this.handleDeleteScene}>
                                DELETE
                            </Button>
                        : <></> }
                        <Button onClick={this.handleCancel}>
                            Cancel
                        </Button>
                        {!this.props.editingSceneInfo ?                             
                            <Button onClick={this.handleAddScene}>
                                Apply
                            </Button>
                        : 
                            <Button onClick={this.handleUpdateScene}>
                                Apply
                            </Button> 
                        }
                    </DialogActions>
                </Dialog>
           </>
        );
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(AddSceneModal);
