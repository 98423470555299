import { UserManagerSettings} from 'oidc-client';
import { createUserManager } from 'redux-oidc';
import {account_api, account_client_id} from '../window';

const local = `${window.location.protocol}//${window.location.hostname}${
  window.location.port ? `:${window.location.port}` : ''
}`;

const configuration: UserManagerSettings = {
  authority: account_api,
  client_id: account_client_id,
  response_type: 'token id_token',
  scope: 'openid profile roles player.read player.write campaigns.manage account.manage',
  redirect_uri: `${local}/signin-videosmart`,
  loadUserInfo: true,
  filterProtocolClaims: true,
  revokeAccessTokenOnSignout: true,
  post_logout_redirect_uri: `${local}/signin-videosmart`,
  // Renew access tokens automatically
  automaticSilentRenew: true,
  silent_redirect_uri: `${local}/silent-renew`
};

export const userManager = createUserManager(configuration);
