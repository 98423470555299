import React from 'react';
import { Check, Clear } from '@material-ui/icons';
import { TemplateActions } from '../../../../../redux';
import { connect } from 'react-redux';
import { Dispatch, bindActionCreators } from 'redux';

export interface SingleVariableProps {
    result:any;
    value:any;
    index:number;
    deleteVariable:any;
    applyChanges:any;
    updateVariableEditor: typeof TemplateActions.asyncActionCreators.updateVariableEditor,
}

export interface SingleVariableState {
    newValue:any
}

const mapDispatchToProps = (dispatch:Dispatch): Pick<SingleVariableProps, 'updateVariableEditor'> => ({
    updateVariableEditor: bindActionCreators(TemplateActions.asyncActionCreators.updateVariableEditor, dispatch)
});

class Variable extends React.Component<SingleVariableProps, SingleVariableState> {
    constructor(props:SingleVariableProps) {
        super(props);

        this.state = {
            newValue: this.props.result,
        }

        this.handleApply = this.handleApply.bind(this);
        this.handleDelete = this.handleDelete.bind(this);
    }   

    componentDidUpdate(prevProps:any){  
       this.props.updateVariableEditor({
            variable: this.props.value,
            result: this.state.newValue
        })
    }

    handleApply() {
        this.props.applyChanges(this.props.value, this.state.newValue)
    }

    handleDelete() {
        this.props.deleteVariable(this.props.value);
    }

    render() {
        return (
            <div>
                <span>{this.props.value}</span> : 
                <input 
                    type="text"
                    placeholder ={this.props.result ? this.props.result : "add value..."} 
                    onChange={(e) => {this.setState( { newValue: e.target.value }); }}
                />
                <Check className="checkBtn" onClick={this.handleApply}></Check>
                <Clear className="delBtn" onClick={this.handleDelete}></Clear>
            </div>
        );
    }
}

export default connect(null, mapDispatchToProps)(Variable);