import { makeStyles, Theme, Grid } from '@material-ui/core';
import React from 'react';
import Loader from './Loader';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    position: 'absolute',
    left: '50%',
    top: '50%',
    transform: 'translateX(-50%) translateY(-50%)'
  },
  loader: {
    fontSize: theme.spacing(8)
  }
}));

export interface ILoadingScreenProps {
  children?: React.ReactNode;
}

const LoadingScreen: React.FC<ILoadingScreenProps> = (props: ILoadingScreenProps) => {
  const { children } = props;

  const classes = useStyles();

  return (
    <Grid container className={classes.root} direction={"column"} spacing={4} alignItems={"center"}>
      <Grid item className={classes.loader}>
        <Loader />
      </Grid>
      <Grid item>
        {children}
      </Grid>
    </Grid>
  );
}

export default LoadingScreen;