import React from 'react';
import { connect } from 'react-redux';
import { Dispatch, bindActionCreators } from 'redux';
import { IRootState } from '../../../../../redux';
import { EditorSettingsActions } from '../../../../../redux/actions';
import OverlayRow from '../OverlayRow/OverlayRow';
import SimpleBar from 'simplebar-react';

import './styles/ElementsContainer.scss';

interface ElementsContainerProps {
    videoDuration:number,
    //-----------------------------------------------------
    scenesInTemplate:any,
    currentScene:number,
    readyToPaint: boolean,
    //-----------------------------------------------------
    closeSettings: typeof EditorSettingsActions.EditorSettingsActions.closeSettings
}

const mapStateToProps = (state:IRootState) => {
    return {
        scenesInTemplate: state.template.present.json.scenes,
        currentScene: state.template.present.currentScene,
        readyToPaint: state.editorSettings.readyToPaint,
    }
}

const mapDispatchToProps = (dispatch:Dispatch): Pick<ElementsContainerProps, 'closeSettings'> => ({
    closeSettings: bindActionCreators(EditorSettingsActions.EditorSettingsActions.closeSettings, dispatch)
});

class ElementsContainer extends React.Component<ElementsContainerProps> {
    state:any;
    keyGenerator: number;
    overlayHeight: number;

    constructor(props:ElementsContainerProps) {
        super(props);

        this.state = {
            overlaysToPaint: <div className='empty'></div>
        }        
        
        this.keyGenerator = -1;
        this.overlayHeight = 32;

        this.paintOverlayRows = this.paintOverlayRows.bind(this);
    }

    componentDidMount() {
        this.paintOverlayRows();
    }

    componentDidUpdate(prevProps:any) {
        if(prevProps !== this.props) {
            this.paintOverlayRows();
        }
    }

    paintOverlayRows() {
        this.setState(() => {
          return {
            overlaysToPaint: <div className='empty'></div>
          }
        });
    
        if (this.props.scenesInTemplate[this.props.currentScene].overlays.length > 0) {
          
          const newOverlaysToPaint = 
          this.props.scenesInTemplate[this.props.currentScene].overlays.map((overlayObject:any, index:number) => {
               this.keyGenerator++;
               return (
                 <OverlayRow 
                   key={this.keyGenerator}
                   // **********************************************
                   index={index}
                   ovHeight={this.overlayHeight}
                   sceneDuration={this.props.videoDuration}
                 />
               );
             });
    
         this.setState(() => {
           return {
             overlaysToPaint: newOverlaysToPaint,
           }
         });
        };
        
        this.props.closeSettings({});
      }


    render() {
        return (
            <div className="elements-container">
                
                <SimpleBar style={{clear:'both', maxHeight:'calc(100% - 203px)' }}>
                    {/* --------------  OVERLAYS in timeline  -------------- */}
                    {this.state.overlaysToPaint}
                </SimpleBar>

            </div>
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ElementsContainer);