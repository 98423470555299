import React from 'react'
import { Undo, Redo } from '@material-ui/icons';
import { Tooltip } from '@material-ui/core';

const UndoRedo = (props: any) => {
    return (
        <>
            <Tooltip title="Undo" aria-label="undo">
                <div className="buttons btn-with-border"  onClick={props.handleUndo}>
                    <Undo />
                </div>
            </Tooltip>
           
            <Tooltip title="Redo" aria-label="redo">
                <div className="buttons btn-with-border" onClick={props.handleRedo} >
                    <Redo />
                </div>
            </Tooltip>
        </>
    );
}

export default UndoRedo;
