import { Image } from '@material-ui/icons';
import React  from 'react';
import { connect } from 'react-redux';
import { IRootState } from '../../../redux/models';
import { Dispatch, bindActionCreators } from 'redux';
import { TemplateActions } from '../../../redux/actions';
import { Tooltip, Dialog, DialogTitle, DialogActions, Button, DialogContent } from '@material-ui/core';

import './style/statusbar.scss';
  
export interface PosterProps {
    addposter: typeof TemplateActions.asyncActionCreators.addPoster,
    deleteposter:typeof TemplateActions.asyncActionCreators.deletePoster,
    fulltemplate:any;
}

const mapStateToProps = (state:IRootState) => {
  return {
    fulltemplate: state.template 
  }
}

const mapDispatchToProps = (dispatch:Dispatch): Pick<PosterProps, 'addposter' | 'deleteposter' > => ({
    addposter:bindActionCreators(TemplateActions.asyncActionCreators.addPoster, dispatch),
    deleteposter:bindActionCreators(TemplateActions.asyncActionCreators.deletePoster, dispatch)

});

class PosterButton extends React.Component<PosterProps>{
    state:any;
    constructor(props:any) {
        super(props);
        this.state ={
            posterText: '',
            open: false,
            MissPoster:false,
            InValidPoster:false,
        }
        this.handleclose = this.handleclose.bind(this);
        this.handleAdd = this.handleAdd.bind(this);
        this.handleOpen = this.handleOpen.bind(this);
        this.handleDelete = this.handleDelete.bind(this);
    }
    componentDidMount(){
        if(this.props.fulltemplate.present.json.poster !== undefined){
            this.setState(() => {
                return {
                    ...this.state, 
                    posterText: this.props.fulltemplate.present.json.poster
                }
            });
        }
    }

    handleDelete(){
        this.props.deleteposter(this.props.fulltemplate.present.text);
        this.setState({ open:false,MissPoster:false, InValidPoster: false, posterText: '' });
    }
    handleOpen(){
        this.setState({ open:true})
    }
    handleclose(){
        this.setState({ open:false,MissPoster:false, InValidPoster: false});
    }
    async handleAdd(){
        if( this.state.posterText === ''){
            this.setState({ MissPoster: true});
        }
       
        if(this.state.posterText !== '' ){
            await fetch(this.state.posterText)
                .then((response) => {
                    if(response.ok) {
                        this.props.addposter({posterText: this.state.posterText})
                        this.setState({ open:false});
                        this.setState({ InValidPoster: false});
                    }
                })              
                .catch((error) => {
                    this.setState(() => ({ InValidPoster: true }));
                })
          
        }
    }
    render() {    
        return (
            <> 
            <Tooltip title="Add Poster" aria-label="add">
                <div className="buttons btn-with-border"  onClick={this.handleOpen} >
                    <Image/>
                </div>
            </Tooltip>
            <Dialog
                fullScreen={false}
                open={this.state.open}
                aria-labelledby="save-template-prompt-title"
                className="save-template-prompt"
            >
                <DialogTitle id="save-template-prompt-title">Please add a poster image</DialogTitle>
                <DialogContent>
                    <div className="input-poster-wrapper">
                        <input 
                            type="text" 
                            placeholder="the url of the image"
                            value = {this.state.posterText}
                            onChange={(e) => {this.setState({ posterText: e.target.value ,MissPoster:false, InValidPoster: false}) }}
                        />
                        <div className="error-container">{this.state.MissPoster ? "url required for adding a poster" : this.state.InValidPoster ? "not a valid url for the poster" : "\u00A0" }</div>
                    </div>
                </DialogContent>
                <DialogActions>
                {this.props.fulltemplate.present.json.poster ? 
                    <Button onClick={this.handleDelete}>
                    Delete
                    </Button>
                :
                <></>
                }    
                <Button onClick={this.handleclose}>
                    Cancel
                </Button>
                <Button onClick={this.handleAdd}>
                    OK
                </Button>
                </DialogActions>
      
            </Dialog>
            </>
        );
    }    
};

export default connect(mapStateToProps, mapDispatchToProps)(PosterButton);
