import {
  ActionType,
  TagSubActionType,
  ActionMessage
} from '@videosmart/player-template';

export class OverlayActionTypeConverter {
  public convert(action: ActionType | TagSubActionType): string {
    switch (action) {
      case ActionType.LoadScene:
        return 'Load Scene';
      case ActionType.RequestFullscreen:
        return 'Request Fullscreen';
      case ActionType.ExitFullscreen:
        return 'Exit Fullscreen';
      case ActionType.FunctionCall:
        return 'Function';
      case ActionType.ThumbsUp:
        return 'Rating Up';
      case ActionType.ThumbsDown:
        return 'Rating Down';
      default:
        return action.charAt(0).toUpperCase() + action.slice(1);
    }
  }

  public getAction(action: ActionMessage ): string {
    return (action !== undefined &&
        action.type === ActionType.Tag &&
        action.subAction !== undefined  ? action.subAction.type : action.type);
  }
  public getActionValue(action: ActionMessage ): string {
    return action !== undefined &&
    action.type === ActionType.Tag &&
    action.subAction !== undefined ? action.subAction.value : '';
  }
  public isSubActionTarget(action: ActionMessage ): boolean {
    return action !== undefined &&
    action.type === ActionType.Tag &&
    action.subAction !== undefined && 
    action.subAction.type === TagSubActionType.Url ? action.subAction.target : false;
  }
}

export const overlayActionTypeConverter = new OverlayActionTypeConverter();
