import SaveIcon from '@material-ui/icons/Save';
import React , { useState } from 'react';
import { connect } from 'react-redux';
import { IRootState } from '../../../redux/models';
import { RouteComponentProps, withRouter } from 'react-router';
import { Dispatch, bindActionCreators } from 'redux';
import { IDictionary } from '../../../api/models/IDictionary';
import { IRequest } from '../../../api/models/requests/IRequest';
import { ICreateTemplateVersionRequest } from '../../../api/models/requests/ICreateTemplateVersionRequest';
import { PlayerApiActions } from '../../../api/actions';
import { CampaignActions } from '../../../redux/actions/CampaignActions';
import { TemplateActions } from '../../../redux/actions';
import { EditorSettingsActions } from '../../../redux/actions/EditorSettingsActions';
import { jsonTryParse } from '../../../utils/jsonTryParse';
import { Tooltip, Dialog, DialogTitle, DialogActions, Button, DialogContent } from '@material-ui/core';

export interface ISaveTemplateProps extends RouteComponentProps<{ templateId: string }> {
  json?: any;
  text?: any;
  isSaved?: boolean;
  secondSave: boolean;
  campinfo:any,
  postTemplateVersion: typeof PlayerApiActions.actionCreators.postTemplateVersion;
  getInfo: typeof CampaignActions.getInfo;
  autoSave: typeof EditorSettingsActions.autoSave;
  templateSaved: typeof TemplateActions.actionCreators.templateSaved;
}

// ********************************************************************************
const mapStateToProps = (state: IRootState) => {
  return {
    json: state.template.present.json,
    text: state.template.present.text,
    isSaved: state.template.present.isSaved,
    secondSave: state.editorSettings.secondSave,
    campinfo: state.campaign
  };
};

const mapDispatchToProps = (dispatch: Dispatch): Pick<ISaveTemplateProps, 'templateSaved' | 'autoSave' | 'postTemplateVersion' | 'getInfo'> => ({
  postTemplateVersion: bindActionCreators(PlayerApiActions.actionCreators.postTemplateVersion, dispatch),
  getInfo: bindActionCreators(CampaignActions.getInfo, dispatch),
  autoSave: bindActionCreators(EditorSettingsActions.autoSave, dispatch),
  templateSaved: bindActionCreators(TemplateActions.actionCreators.templateSaved, dispatch)
});
// ********************************************************************************


const SaveTemplate: React.FC<ISaveTemplateProps> = (props: ISaveTemplateProps) => {
  const handleSave = (isSaved:boolean) => {
    setSaved(isSaved);
    props.templateSaved({ isSaved: isSaved });
  }
  
  const [modalOpen, setModal] = useState(false);
  const [comment, setComment] = useState('');
  const [saved, setSaved] = useState(true);

  const className = 'buttons btn-with-border '+`${props.isSaved ? `disabled` : ''}`;
  let  currentTemplate:any = jsonTryParse(props.text);

  const saveTemplate = async () => {
    //create new template version in api
      const params: IDictionary<string> = {
        templateId: props.match.params.templateId
      };
      const template: ICreateTemplateVersionRequest = {
        value:currentTemplate.isParsed ? currentTemplate.json : params.json,
        comment: !props.secondSave ? comment : 'first video added'
      };
      const request: IRequest<ICreateTemplateVersionRequest> = {
        params,
        body: template
      };
  
      try {
        const templateVersionsResponse = await props.postTemplateVersion(request);
        //update url
        if (templateVersionsResponse !== null && templateVersionsResponse.ok) {
          props.getInfo({
            name: props.campinfo.name,
            templateid: props.match.params.templateId,
            version: templateVersionsResponse.versionNumber,
            newTemplate: false
          });
          props.history.push(
            `/templates/${props.match.params.templateId}/versions/${templateVersionsResponse.versionNumber}/edit`
          );

          props.templateSaved({ isSaved: true });
        } else { }
  
        setSaved(true);
        setModal(false);
      }
      catch {
        setSaved(false);
      }

  };

  // if it's a new template we save automatically a new version of the template
  if(props.secondSave) {
    saveTemplate();
    props.autoSave({ secondSave: false});
  }
  
  const showPrompt = () => {
    if(!props.isSaved)
      setModal(true);
  }

  return (
    <> 
      <Tooltip title="Save Template" aria-label="save">
        <div className={className}  onClick={showPrompt} >
          <SaveIcon/>
        </div>
      </Tooltip>
      <Dialog
        fullScreen={false}
        open={modalOpen}
        aria-labelledby="save-template-prompt-title"
        className="save-template-prompt"
      >
        <DialogTitle id="save-template-prompt-title">Please insert a comment</DialogTitle>
        <DialogContent>
          <input 
            type="text" 
            placeholder="a description for the changes"
            onChange={(e) => { setComment(e.target.value) }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => { setModal(false); handleSave(true); }}>
            Cancel
          </Button>
          <Button onClick={saveTemplate}>
            OK
          </Button>
        </DialogActions>
        <div className="error-container">
          { !saved ? <span>There was an error saving the template</span> : <></> }
        </div>
      </Dialog>
    </>
  );
};

export default connect(mapStateToProps,mapDispatchToProps)(withRouter(SaveTemplate));
