import { ExpandMore,ExpandLess, KeyboardBackspace } from '@material-ui/icons';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import React from "react";
import { RouteComponentProps } from "react-router";
import { TemplateSummary } from '../models/TemplateSummary';
import { IAccountsummary, ICampaignSummary} from '../../../redux/models/CampaignInfo';
import Template from './Template';
import { PlayerApiActions } from '../../../api/actions';
import { Dispatch, bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { EditorSettingsActions } from '../../../redux/actions';
import '../styles/template.scss';
import AddTemplate from './AddTemplate';
// ********************************************************************************

export interface InfoProps extends TemplateListProps {
  getTemplates: typeof PlayerApiActions.actionCreators.getTemplates;
  getAccounts: typeof PlayerApiActions.actionCreators.getAccounts;
  getCampaigns: typeof PlayerApiActions.actionCreators.getCampaigns;
  showcampaing: typeof EditorSettingsActions.EditorSettingsActions.showCampaignInfo 
}
export interface TemplateListProps extends RouteComponentProps<{ templateId: string }> {
  history:any;
}

  export interface TemplateListState {
 
    currentPage: number;
    rowsPerPage: number;
    totalPages: number;  
    rowsPerPageOptions: number[];
    templates: TemplateSummary[];
    SortList: string [];
    Account: IAccountsummary[];
    AccountValue:string;
    Campaign: ICampaignSummary[];
    CampaignValue: string;
    SortValue: string;
    oldestT:boolean;
    nameAsc: boolean;
  }

  const templatesDummy: TemplateSummary[] = [
    {
      accountId: "302f0199-c893-4438-9719-1bf89f34bb53",
      campaignId: "7e980888-91b7-4b3d-9c6a-63185dfc76ac",
      createdAt: "2019-06-07T11:19:06.775903",
      id: "a5555e93-f70c-457f-a956-582d648c6051",
      name: "Test template 1",
      updatedAt: "2019-06-07T11:19:06.776068"
    },
    {
      accountId: "302f0199-c893-4438-9719-1bf89f34bb53",
      campaignId: "7e980888-91b7-4b3d-9c6a-63185dfc76ac",
      createdAt: "2019-06-07T11:23:56.732956",
      id: "86d29d3a-6f8f-4e36-9af1-5a2e739aeef3",
      name: "Test template 2",
      updatedAt: "2019-06-07T11:23:56.73317"
    },
    {
      accountId: "302f0199-c893-4438-9719-1bf89f34bb53",
      campaignId: "7e980888-91b7-4b3d-9c6a-63185dfc76ac",
      createdAt: "2019-06-07T11:28:45.911051",
      id: "ca883bb5-1123-409c-a544-58ab528108c3",
      name: "Test template 3",
      updatedAt: "2019-06-07T11:28:45.911248"
    },
    {
      accountId: "302f0199-c893-4438-9719-1bf89f34bb53",
      campaignId: "7e980888-91b7-4b3d-9c6a-63185dfc76ac",
      createdAt: "2019-06-07T11:29:11.843975",
      id: "0a69227d-20f0-43f5-a28c-9f74cb71d449",
      name: "Test template 4",
      updatedAt: "2019-06-07T11:29:11.843975"
    },
    {
      accountId: "302f0199-c893-4438-9719-1bf89f34bb53",
      campaignId: "7e980888-91b7-4b3d-9c6a-63185dfc76ac",
      createdAt: "2019-06-07T11:30:32.43443",
      id: "ae92ee3c-0b07-4e65-a2e1-bd1bcebad405",
      name: "Test template 5",
      updatedAt: "2019-06-07T11:30:32.43462"
    },
    {
      accountId: "302f0199-c893-4438-9719-1bf89f34bb53",
      campaignId: "7e980888-91b7-4b3d-9c6a-63185dfc76ac",
      createdAt: "2019-06-07T11:32:36.240238",
      id: "41f24c8a-fb3a-4168-b8da-4dc944c043b4",
      name: "Test template 6",
      updatedAt: "2019-06-07T11:32:36.240376"
    },
    {
      accountId: "302f0199-c893-4438-9719-1bf89f34bb53",
      campaignId: "7e980888-91b7-4b3d-9c6a-63185dfc76ac",
      createdAt: "2019-06-07T11:34:10.71286",
      id: "4b169274-4777-465a-b6b1-2cecb80a57e9",
      name: "Test template 7",
      updatedAt: "2019-06-07T11:34:10.713045"
    },
    {
      accountId: "302f0199-c893-4438-9719-1bf89f34bb53",
      campaignId: "7e980888-91b7-4b3d-9c6a-63185dfc76ac",
      createdAt: "2019-06-07T11:36:01.454668",
      id: "9f884b0e-e8f1-40f7-ae26-4c5671e868fd",
      name: "Test template 8",
      updatedAt: "2019-06-07T11:36:01.454796"
    },
    {
      accountId: "302f0199-c893-4438-9719-1bf89f34bb53",
      campaignId: "7e980888-91b7-4b3d-9c6a-63185dfc76ac",
      createdAt: "2019-06-07T11:36:49.82214",
      id: "c22a21a7-223b-43f3-bc57-3d74218bef89",
      name: "Test template 9",
      updatedAt: "2019-06-07T11:36:49.822256"
    },
    {
      accountId: "302f0199-c893-4438-9719-1bf89f34bb53",
      campaignId: "7e980888-91b7-4b3d-9c6a-63185dfc76ac",
      createdAt: "2019-06-07T11:40:13.005866",
      id: "1792a45a-a884-4598-b1a7-d2017d351f22",
      name: "Test template 10",
      updatedAt: "2019-06-07T11:40:13.006029"
    },
    {
      accountId: "302f0199-c893-4438-9719-1bf89f34bb53",
      campaignId: "7e980888-91b7-4b3d-9c6a-63185dfc76ac",
      createdAt: "2019-06-07T11:41:46.281939",
      id: "9a2fe89d-844b-4a49-8e4a-7ea129b8ae14",
      name: "Test template 11",
      updatedAt: "2019-06-07T11:41:46.282049"
    },
    {
      accountId: "302f0199-c893-4438-9719-1bf89f34bb53",
      campaignId: "7e980888-91b7-4b3d-9c6a-63185dfc76ac",
      createdAt: "2019-06-07T11:42:00.927909",
      id: "c2f76822-72c1-48bd-b925-e92e5852e9d2",
      name: "Test template 12",
      updatedAt: "2019-06-07T11:42:00.927909"
    },
    {
      accountId: "302f0199-c893-4438-9719-1bf89f34bb53",
      campaignId: "7e980888-91b7-4b3d-9c6a-63185dfc76ac",
      createdAt: "2019-06-07T11:44:43.013934",
      id: "bdc6d2fc-a087-44d6-a6ab-1122095611c2",
      name: "Test template 13",
      updatedAt: "2019-06-07T11:44:43.014041"
    },
    {
      accountId: "302f0199-c893-4438-9719-1bf89f34bb53",
      campaignId: "7e980888-91b7-4b3d-9c6a-63185dfc76ac",
      createdAt: "2019-06-07T11:54:48.278111",
      id: "9cc65f5c-ebad-40aa-a046-8e3053591760",
      name: "Test template 14",
      updatedAt: "2019-06-07T11:54:48.278249"
    },
    {
      accountId: "302f0199-c893-4438-9719-1bf89f34bb53",
      campaignId: "7e980888-91b7-4b3d-9c6a-63185dfc76ac",
      createdAt: "2019-06-10T09:24:28.193072",
      id: "71d0cbac-9133-4e8f-960b-d1ea6abac256",
      name: "Test template 15",
      updatedAt: "2019-06-10T09:24:28.193216"
    },
    {
      accountId: '302f0199-c893-4438-9719-1bf89f34bb53',
      campaignId: '7e980888-91b7-4b3d-9c6a-63185dfc76ac',
      createdAt: '2019-07-03T14:11:19.880107',
      id: '66451ad0-9ebe-4e20-aa07-6eb7981c6519',
      name: 'Tracking test',
      updatedAt: '2019-07-03T14:11:19.88026'
    },
    {
      createdAt: '2019-07-05T11:16:31.828493',
      id: '028c0dec-436c-4402-95f7-93436cd8ff28',
      name: 'Test onMount / onUnmount',
      updatedAt: '2019-07-05T11:16:31.828645'
    },
    {
      createdAt: '2019-07-08T09:35:03.838842',
      id: 'dd8fe070-ac37-4056-a1ca-6a76b74f1ddc',
      name: 'Test onMount / onUnmount',
      updatedAt: '2019-07-08T09:35:03.838964'
    }
  ];
  // ********************************************************************************
  
  const mapDispatchToProps = (dispatch: Dispatch): Pick<InfoProps, 'showcampaing'| 'getTemplates' | 'getAccounts' |'getCampaigns'> => ({
    getTemplates: bindActionCreators(PlayerApiActions.actionCreators.getTemplates,dispatch),
    showcampaing: bindActionCreators(EditorSettingsActions.EditorSettingsActions.showCampaignInfo, dispatch),
    getAccounts:  bindActionCreators(PlayerApiActions.actionCreators.getAccounts, dispatch),
    getCampaigns: bindActionCreators(PlayerApiActions.actionCreators.getCampaigns, dispatch),
  });

  // ********************************************************************************
class TemplateList extends React.Component<InfoProps, TemplateListState> {
  constructor(props: InfoProps) {
    super(props);

    this.state = {      
      currentPage: 0,
      totalPages:0,
      rowsPerPage: 16,
      rowsPerPageOptions: [8,16, 24, 32, 40, 48],
      templates: [],
      Account: [],
      AccountValue: '',
      Campaign: [],
      CampaignValue: '',
      SortList:['name', 'createdAt'],
      SortValue:'',
      oldestT: true,
      nameAsc: true,

    };

    this.handleOnChangePagePrev = this.handleOnChangePagePrev.bind(this);
    this.handleOnChangePageNext = this.handleOnChangePageNext.bind(this);
    this.onChangeRowsPerPage = this.onChangeRowsPerPage.bind(this);
    this.handleSortBy = this.handleSortBy.bind(this);
  }

  public componentDidMount = async () => {
    let newTempSorted;
    let templates=templatesDummy;
    let total = 0.00;
    const templatesResponse = await this.props.getTemplates();

    if (templatesResponse !== null && templatesResponse.templates !== null) {
      templates = templatesResponse.templates;
    }
    newTempSorted = templates.sort(function(a, b){
      var nameA=a["name"].toLowerCase(), nameB=b["name"].toLowerCase()
      if (nameA > nameB) {
        return -1;
      }
      if (nameA < nameB) {
        return 1;
      }
      return 0;
    });

    total =  templates.length / this.state.rowsPerPage;
    let inttotal = parseInt(total.toString());
    let finaltotal = 0;
    if(total > inttotal){
      finaltotal = inttotal + 1;
    }
    if(total === inttotal){
      finaltotal = inttotal;
    }
    if(total < inttotal){
      finaltotal = inttotal + 1;
    }
    this.setState(() => ({SortValue: String("name")  }))
    this.setState(() => ({ templates: newTempSorted, totalPages: finaltotal }));
     const accountsResponse = await this.props.getAccounts();
     const CampaignsResponse = await this.props.getCampaigns();
     if(accountsResponse !== null){
       this.setState(() => ({Account: accountsResponse}));
     }
     if(CampaignsResponse !== null){ 
       this.setState(() => ({  Campaign:CampaignsResponse }));
     }
    this.props.showcampaing({showCampaignInfo: true})  
  };
  componentWillMount(){
    document.getElementsByTagName('nav')[0].classList.add('templates');
  }  

  public handleOnChangePagePrev = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ) => {
    if(this.state.currentPage > 0){
      this.setState(() => ({ currentPage: (this.state.currentPage -1)}));
    }
  };

  public handleOnChangePageNext = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ) => {
    if(this.state.currentPage < this.state.totalPages -1){
      this.setState(() => ({ currentPage: (this.state.currentPage + 1)}));
    }
  };

  public onChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { currentPage, rowsPerPage, templates } = this.state;

    const newRowsPerpage = parseInt(event.target.value, 10);

    const prevMaximumPageNumber = Math.ceil(templates.length / rowsPerPage);
    const maximumPageNumber = Math.ceil(templates.length / newRowsPerpage);

    const delta = Math.floor(
      maximumPageNumber * (currentPage / prevMaximumPageNumber)
    );
    const newCurrentPage = Math.min(delta, delta * newRowsPerpage);

    this.setState(() => ({
      currentPage: newCurrentPage,
      rowsPerPage: newRowsPerpage
    }));
  };

  handleSortBy(e){
    let newtemp :  TemplateSummary[] = this.state.templates;
    
    if(e.target.value === "name"){
      //sort by NAME
      if(this.state.nameAsc){
        newtemp = this.state.templates.sort(function(a, b){
            var nameA=a[e.target.value].toLowerCase(), nameB=b[e.target.value].toLowerCase()
            if (nameA < nameB) {
              return -1;
            }
            if (nameA > nameB) {
              return 1;
            }
            return 0;
        });
      }else{
        newtemp = this.state.templates.sort(function(a, b){
          var nameA=a[e.target.value].toLowerCase(), nameB=b[e.target.value].toLowerCase()
          if (nameA > nameB) {
            return -1;
          }
          if (nameA < nameB) {
            return 1;
          }
          return 0;
        });
      }
    }
    else{
      //Sort by DATE
      if(this.state.oldestT){
        newtemp = this.state.templates.sort(function(a,b){
          //var dateA = Number(a[e.target.value]), dateB = Number(b[e.target.value]);
          return new Date(a[e.target.value]).getTime() - 
          new Date(b[e.target.value]).getTime()
          //return dateA - dateB;
        });
      }
      else{
        newtemp = this.state.templates.sort(function(a,b){
          return new Date(a[e.target.value]).getTime() +
          new Date(b[e.target.value]).getTime()
        }).reverse();
      }
    }  
    this.setState(() => ({SortValue: String(e.target.value)  }))
    if(e.target.value === 'name'){
      this.setState(() => ({
        nameAsc: !this.state.nameAsc,
        templates: newtemp 
      }));
    }
    else {
    this.setState(() => ({ 
        oldestT: !this.state.oldestT,
        templates: newtemp 
      }));
    }
  }

  public render = () => {
    const {
      currentPage,
      rowsPerPage,
      templates
    } = this.state;

    const startPoint = currentPage * rowsPerPage;
    const visibleTemplates: TemplateSummary[] = templates.slice(
      startPoint,
      startPoint + rowsPerPage
    );

    return (
        <div className="pageContainer">
          <h2>VIDEO LIBRARY</h2>
          <div className="actions-container">
            <AddTemplate history={this.props.history} templateList={this.state.templates} Campaign={this.state.Campaign} Account={this.state.Account}/>
            <div className="sortAndPagination noselect">
              <div className="sort">
                SORT BY&nbsp;&nbsp;&nbsp;
                <FormControl className="selectControl">
                  <Select className="selectInput"
                      data-id="demo-simple-select"
                      name="rowsPerPage"
                      value={this.state.SortValue}
                      onChange= {(e) => {this.handleSortBy(e)}} 
                  >                    
                    <MenuItem key={1} value={this.state.SortList[0]}>
                        {this.state.SortList[0]} {this.state.nameAsc ? <ExpandMore/> : <ExpandLess/> }
                    </MenuItem>
                    <MenuItem key={2}  value={this.state.SortList[1]}>
                      {this.state.SortList[1]}{this.state.oldestT? <ExpandMore/> : <ExpandLess/> }
                    </MenuItem>
                  </Select>
                </FormControl>
              </div>             
              <div className="pagination">
                <span>{this.state.currentPage + 1} of {this.state.totalPages}</span>
                {(this.state.currentPage + 1) === 1
                  ? <button onClick={this.handleOnChangePagePrev} className='btn-with-border noselect prev disabled'><KeyboardBackspace /></button>
                  : <button onClick={this.handleOnChangePagePrev} className='btn-with-border noselect prev'><KeyboardBackspace /></button>
                }
                
                {(this.state.currentPage + 1) === this.state.totalPages
                  ? <button onClick={this.handleOnChangePageNext} className='btn-with-border noselect next disabled'><KeyboardBackspace /></button>
                  : <button onClick={this.handleOnChangePageNext} className='btn-with-border noselect next'><KeyboardBackspace /></button>
                }
              </div>
              <div className="pagination">
                  <FormControl className="selectControl">                        
                    <Select className="selectInput"
                      data-id="demo-simple-select"
                      name="rowsPerPage"
                      value={this.state.rowsPerPage}
                      onChange= {(e) => {this.setState({rowsPerPage: Number(e.target.value),currentPage: 0, totalPages: parseInt((templates.length /Number(e.target.value)).toString()) + 1})}} 
                    >
                      {this.state.rowsPerPageOptions.map((value:number, index:number)=>(
                        <MenuItem key= {index} value={value}>{value}</MenuItem>
                      ))}                           
                    </Select>
                  </FormControl>
              </div>
            </div>
          </div>

          <div className="templatesContainer">
            <div className="templatesInnerContainer">
              {visibleTemplates.length > 0 ? (
                visibleTemplates.map((template: any, index: number) => (
                  <Template
                    key={template.id + index}
                    template={template}
                    Account={this.state.Account}
                    Campaign={this.state.Campaign}                 
                  >                    
                  </Template>
                ))
              ) : (            
                <div className="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
              )}
            </div>
          </div>
        </div>
    );
  };
}

export default connect(null, mapDispatchToProps)(TemplateList);
