
import React from 'react';
import {connect} from 'react-redux';
import { Dispatch, bindActionCreators } from 'redux';
import { Code } from '@material-ui/icons';
import { EditorSettingsActions } from '../../../redux';
import { Tooltip } from '@material-ui/core';

export interface CodeButtonProps {
  showCodeEditor:typeof EditorSettingsActions.EditorSettingsActions.showCodeEditor
}

const mapDispatchToProps = (dispatch:Dispatch): Pick<CodeButtonProps, 'showCodeEditor'> => ({
  showCodeEditor: bindActionCreators(EditorSettingsActions.EditorSettingsActions.showCodeEditor, dispatch)
});

const CodeButton = (props:CodeButtonProps) => {
  return ( 
    <Tooltip title="Code Editor" aria-label="code">
      <div 
        className="buttons btn-with-border" 
        onClick={() => { props.showCodeEditor({ codeEditor:true }); }} 
      >
        <Code  />
      </div>
    </Tooltip>
  );
};

export default connect(null, mapDispatchToProps)(CodeButton);