import './index.scss';

import React from 'react';
import ReactDOM from 'react-dom';

import App from './App';
import * as serviceWorker from './serviceWorker';

import { processSilentRenew } from 'redux-oidc';

const isSilentRenewCallback =
  window.location.pathname.toLowerCase() === '/silent-renew';
if (isSilentRenewCallback) {
  // Handle callback for silent renew
  processSilentRenew();
} else {
  ReactDOM.render(<App />, document.getElementById('root'));
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
