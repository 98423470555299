import { actionCreatorFactory } from 'typescript-fsa';

enum Type {
  OverlayToEdit = 'OVERLAY_TO_EDIT',
  OverlayToDelete = 'OVERLAY_TO_DELETE',
  OverlayToAddNew = "OVERLAY_TO_ADD_NEW",
  CloseSettings = 'CLOSE_SETTINGS',
  RefreshOverlays = 'REFRESH_OVERLAYS',
  RefreshTopBar = 'REFRESH_TOPBAR',
  ShowSubtitlesEdition = 'SHOW_SUBTITLES_EDITION',
  ShowOverlaysEdition = 'SHOW_OVERLAYS_EDITION',
  SubtitlesPainted = 'SUBTITLES_PAINTED',
  ShowCodeEditor = 'SHOW_CODE_EDITOR',
  LoadingData = 'LOADING_DATA',
  ShowVariablesEditor = 'SHOW_VARIABLES_EDITOR',
  ResetStore = 'RESET_STORE',
  AutoSave = 'AUTO_SAVE',
  RefreshCanvas = 'REFRESH_CANVAS',
  ShowAddScene = 'SHOW_ADD_SCENE',
  ChangeScene = 'CHANGE_SCENE',
  EditingScene = 'EDITING_SCENE',
  ShowCampaignInfo = 'SHOW_CAMPAIGN_INFO'
}

const create = actionCreatorFactory('EDITOR_SETTINGS');

export const EditorSettingsActions = {
  overlayToEdit: create<any>(Type.OverlayToEdit),
  overlayToDelete: create<any>(Type.OverlayToDelete),
  overlayToAddNew: create<any>(Type.OverlayToAddNew),
  closeSettings: create<any>(Type.CloseSettings),
  refreshOverlays: create<any>(Type.RefreshOverlays),
  refreshTopBar: create<any>(Type.RefreshTopBar),
  showSubtitlesEdition: create<any>(Type.ShowSubtitlesEdition),
  showOverlaysEdition: create<any>(Type.ShowOverlaysEdition),
  subtitlesPainted: create<any>(Type.SubtitlesPainted),
  showCodeEditor: create<any>(Type.ShowCodeEditor),
  loadingData: create<any>(Type.LoadingData),
  showVariablesEditor: create<any>(Type.ShowVariablesEditor),
  resetStore: create<any>(Type.ResetStore),
  autoSave: create<any>(Type.AutoSave),
  refreshCanvas: create<any>(Type.RefreshCanvas),
  showAddScene: create<any>(Type.ShowAddScene),
  changeScene: create<any>(Type.ChangeScene),
  editingScene: create<any>(Type.EditingScene),
  showCampaignInfo: create<any>(Type.ShowCampaignInfo)
}