import { makeStyles, Theme } from '@material-ui/core';
import { CSSProperties } from '@material-ui/styles';
import React from 'react';

const borderWidth = 2;
const animationDuration = 2;

const loaderSlice: CSSProperties = {
  borderRadius: '50%',
  border: `${borderWidth}px solid transparent`,
  animationName: '$spin',
  animationIterationCount: 'infinite',
  animationTimingFunction: 'linear'
};

const useStyles = makeStyles((theme: Theme) => ({
  '@keyframes spin': {
    from: {
      transform: 'rotate(0)'
    },
    to: {
      transform: 'rotate(360deg)' 
    }
  },
  root: {
    ...loaderSlice,
    position: 'relative',
    width: '2em',
    height: '2em',
    borderTopColor: theme.palette.primary.dark,
    animationDuration: `${animationDuration}s`,
    transition: theme.transitions.create([
      'width','height','margin'
    ], {
      duration: theme.transitions.duration.standard,
      easing: 'linear'
    }),
    '&:before': {
      ...loaderSlice,
      content: '""',
      position: 'absolute',
      top: 2 * borderWidth,
      left: 2 * borderWidth,
      right: 2 * borderWidth,
      bottom: 2 * borderWidth,
      borderTopColor: theme.palette.type === "dark" ? '#ffffff' : '#000000',
      animationDuration: `${1.5 * animationDuration}s`
    },
    '&:after': {
      ...loaderSlice,
      content: '""',
      position: 'absolute',
      top: 5 * borderWidth,
      left: 5 * borderWidth,
      right: 5 * borderWidth,
      bottom: 5 * borderWidth,
      borderTopColor: theme.palette.primary.light,
      animationDuration: `${0.75 * animationDuration}s`
    }
  },
}));

const Loader: React.FC = () => {
  const classes = useStyles();

  return (
    <div className={classes.root} />
  );
};

export default Loader;