import React from 'react';

import { LoadingScreen } from '../../components';
import { userManager } from '../../services/userManager';
import { Typography } from '@material-ui/core';

const SignInRedirect: React.FC = () => {
  userManager.signinRedirect();
  return (
    <LoadingScreen>
      <Typography>Logging you in...</Typography>
    </LoadingScreen>
  );
}

export default SignInRedirect;
