import React from 'react';
import {connect} from 'react-redux';
import { Dispatch, bindActionCreators } from 'redux';
import { EditorSettingsActions, IRootState } from '../../../../redux';
import { Close } from '@material-ui/icons';
import './styles/CloseEditorBtn.scss';

export interface CloseEditorProps {
    codeEditorVisible:boolean,
    showCodeEditor:typeof EditorSettingsActions.EditorSettingsActions.showCodeEditor,
    showVariablesEditor: typeof EditorSettingsActions.EditorSettingsActions.showVariablesEditor
}

const mapStateToProps = (state:IRootState) => {
    return {
        codeEditorVisible: state.editorSettings.codeEditor
    }
}

const mapDispatchToProps = (dispatch:Dispatch): Pick<CloseEditorProps, 'showCodeEditor'|'showVariablesEditor'> => ({
    showCodeEditor: bindActionCreators(EditorSettingsActions.EditorSettingsActions.showCodeEditor, dispatch),
    showVariablesEditor: bindActionCreators(EditorSettingsActions.EditorSettingsActions.showVariablesEditor, dispatch)
});

const CloseEditorBtn = (props:CloseEditorProps) => {
    return (
        <div 
            className="closeEditor-btn"
            onClick={() => { props.showCodeEditor({codeEditor:false}); props.showVariablesEditor({variablesEditor: false}); }}
            style={!props.codeEditorVisible ? {display:'none'} : {display:'block'}}
        >
            <Close />
        </div>
    )
}

export default connect(mapStateToProps, mapDispatchToProps)(CloseEditorBtn);