import React from 'react';

import './styles/PositionIndicator.scss';

export interface IndicatorProps {
    leftPosition:number,
    numOverlays:number,
    editingOverlays:boolean
}

const PositionIndicator = (props:IndicatorProps) => {    
    return (
        <div 
            className="indicator" 
            style={props.editingOverlays ? {left:props.leftPosition + "%", height:(props.numOverlays * 40) + 58} : {left:props.leftPosition + "%", height:58}}
        >
            <div style={{position:'relative'}}>
                <div style={{
                        position:'relative',
                        left:'-7px',
                        width:0, 
                        height:0, 
                        borderLeft:'8px solid transparent',
                        borderRight:'8px solid transparent',
                        borderTop: '8px solid #47c3f2'
                    }}></div>
            </div>
        </div>
    )
}

export default PositionIndicator;