import React from 'react';
import { IRootState } from '../../../../../../redux';
import { connect } from 'react-redux';
import { Dispatch, bindActionCreators } from 'redux';
import {EditorSettingsActions, OverlaysActions, TemplateActions} from '../../../../../../redux/actions';

export interface TabProps {
    index:number,
    activeTab:number,
    template: any,
    refreshTabs: any,
    scenes:any,    
    changeScene:typeof TemplateActions.asyncActionCreators.changeScene,
    updateOverlayFromJson:typeof OverlaysActions.OverlaysActions.updateOverlayFromJson,
    refreshOverlays: typeof EditorSettingsActions.EditorSettingsActions.refreshOverlays,
}

const mapStateToProps = (state:IRootState) => {
    return {
        activeTab: state.template.present.currentScene,
        template: state.template.present.text,
        scenes: state.template.present.json.scenes
    }
}

const mapDispatchToProps = (dispatch:Dispatch): Pick<TabProps, 'changeScene' | 'updateOverlayFromJson' | 'refreshOverlays'> => ({  
    changeScene: bindActionCreators(TemplateActions.asyncActionCreators.changeScene, dispatch),    
    updateOverlayFromJson:bindActionCreators(OverlaysActions.OverlaysActions.updateOverlayFromJson, dispatch),  
    refreshOverlays: bindActionCreators(EditorSettingsActions.EditorSettingsActions.refreshOverlays, dispatch)
});


const Tab = (props:TabProps) =>  {
    return (
        <>
            {props.scenes[props.index] ?
                <button 
                    className={props.index === props.activeTab ? "tab active" : "tab"}
                    onClick={ () => { 
                        props.changeScene(props.index);
                        props.refreshTabs();  
                                          
                        props.updateOverlayFromJson(
                          { 
                            currentScene:props.index, 
                            payload:props.template 
                          }
                        );                        
                         props.refreshOverlays({}); 
                    } }
                >
                    {props.scenes[props.index].id ? props.scenes[props.index].id : "Scene " + props.index}
                </button>  
            :
            <>
            </>}
        </>
    )
}

export default connect(mapStateToProps, mapDispatchToProps)(Tab);