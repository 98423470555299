import * as React from 'react';
import { connect } from 'react-redux';
import { Route, RouteProps } from 'react-router';

import { SignInRedirect } from '../areas/login';
import { LoadingScreen } from '../components';
import { IRootState } from '../redux';
import { Typography } from '@material-ui/core';

export interface IPrivateRouteProps extends RouteProps {
  //history:any ;
  isLoadingUser: boolean;
  isLoggedIn: boolean;
}

const mapStateToProps = (state: IRootState) => {
  return {
    isLoadingUser: state.oidc.isLoadingUser,
    isLoggedIn: !!state.oidc.user && !state.oidc.user.expired
  };
};

class PrivateRoute extends React.Component<IPrivateRouteProps> {
  
  render() {
    if (this.props.isLoadingUser) {
      return (
        <LoadingScreen>
          <Typography>Loading...</Typography>
        </LoadingScreen>
      );
    } else if (this.props.isLoggedIn) {
      return <Route {...this.props} />;
    } else {
      return <SignInRedirect />;
    }
  }
}

export default connect(mapStateToProps)(PrivateRoute);
