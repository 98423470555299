import { createMuiTheme } from '@material-ui/core';

// https://material-ui.com/customization/default-theme/
/* tslint:disable:object-literal-sort-keys */
export const darkTheme = createMuiTheme({
  typography: {
    fontFamily: '"Open Sans", "Helvetica", "Arial", sans-serif',
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightMedium: 700
  },
  palette: {
    type: 'dark',
    common: {
      black: '#000',
      white: '#fff'
    },
    primary: {
      main: '#007bff'
    },
    error: {
      main: '#ff4200'
    },
    background: {
      default: '#212121',
      paper: '#424242'
    }
  }
});
/* tslint:enable:object-literal-sort-keys */
