import Button from '@material-ui/core/Button';
  import Backdrop from "@material-ui/core/Backdrop";
  import { Add} from '@material-ui/icons';
  import MenuItem from '@material-ui/core/MenuItem';
  import Select from '@material-ui/core/Select';
  import React from "react";
  import { PlayerApiActions } from '../../../api/actions';
  import { Dispatch, bindActionCreators } from 'redux';
  import { connect } from 'react-redux';
  import {IDictionary} from '../../../api/models/IDictionary';
  import {IRequest} from '../../../api/models/requests/IRequest';
  import { CampaignActions } from '../../../redux/actions/CampaignActions';
  import '../styles/template.scss';
  import {
    Dialog,
    DialogContent,
    DialogTitle,
    DialogActions
} from '@material-ui/core';
import { TemplateSummary } from '../models/TemplateSummary';
  // ********************************************************************************
  
  export interface AddTemplateProps{
    history: any;
    Campaign: any[];
    Account: any[];
    templateList: TemplateSummary[];
    postTemplates: typeof PlayerApiActions.actionCreators.postTemplates;
    postTemplateVersion: typeof PlayerApiActions.actionCreators.postTemplateVersion;
    setNewTemplate: typeof CampaignActions.infoReceived;

  }

    export interface AddTemplateState {     
      name:string;
      open:boolean;   
      AccountValue:string;
      CampaignValue: string ;    
      MissingName:boolean;
      ValidName: boolean;
    }
  
    // ********************************************************************************
    
    const mapDispatchToProps = (dispatch: Dispatch): Pick<AddTemplateProps, 'setNewTemplate' | 'postTemplates'|'postTemplateVersion' > => ({
      postTemplates: bindActionCreators(PlayerApiActions.actionCreators.postTemplates,dispatch),
      postTemplateVersion : bindActionCreators(PlayerApiActions.actionCreators.postTemplateVersion, dispatch),
      setNewTemplate: bindActionCreators(CampaignActions.infoReceived, dispatch)
    });
  
    // ********************************************************************************
  class AddTemplate extends React.Component<AddTemplateProps, AddTemplateState> {
    constructor(props: AddTemplateProps) {
      super(props);
  
      this.state = {           
        AccountValue: '',
        CampaignValue: '',
        name:'',
        open: false,
        MissingName: false,
        ValidName:false,
  
      };
  
      this.handleOnClick = this.handleOnClick.bind(this);
      this.handleDialogOpen = this.handleDialogOpen.bind(this);
      this.handleDialogClose = this.handleDialogClose.bind(this);
      this.validateName =  this.validateName.bind(this);
    }
  
    public componentDidMount = async () => {
     
    };
    componentWillMount(){
      document.getElementsByTagName('nav')[0].classList.add('templates');
    }

    //validate if exist already a template with the same name 
    validateName(){     
          this.props.templateList.forEach((info:TemplateSummary)=>{
              if( info.name !== undefined){                  
                  if(this.state.name ===  info.name){
                      this.setState(() => ({ ValidName:true}));                   
                  }                 
              }
          })     
    }
  
    async handleOnClick(){
      if(this.state.name === ''){
        this.setState(() => ({ MissingName: true }));
      }else{
        this.setState(() => ({ MissingName: false }));
        if(!this.state.ValidName){
          const params : IDictionary<string> ={"Name":this.state.name};
          const body : any = {"Name":this.state.name,  "AccountId": this.state.AccountValue , "CampaignId": this.state.CampaignValue};
          const request : IRequest<any> = {params, body};
          const templatesResponse = await this.props.postTemplates(request);
          if (templatesResponse !== null && templatesResponse.ok) {  
            const params : IDictionary<string> ={"templateId": templatesResponse.id};
            const body : any = {"comment": "template created"};
            const request : IRequest<any> = {params, body};
            const createversionresponse = await this.props.postTemplateVersion(request);
            if(createversionresponse !== null && createversionresponse.ok){
              this.setState(() => ({ open: false }));
              this.props.history.push(`/templates/${templatesResponse.id}/versions/1/edit`);
              this.props.setNewTemplate({newTemplate:true}); 
            }
          }
        }
      }   
    }
  
    private handleDialogOpen = () => {
      this.setState(() => ({ open: true }));
    }
    
    private handleDialogClose = () => {
      this.setState(() => ({ MissingName: false }));
      this.setState(() => ({ open: false }));
    }
 
    public render = () => {

  
      return (
            <div>
                <button className="btn-with-border noselect" onClick={this.handleDialogOpen}>
                  New Template &nbsp;&nbsp;<Add />
                </button>                
                <Dialog
                //   
                    aria-labelledby="transition-modal-title"                
                    open={this.state.open}
                    onClose={this.handleDialogClose}
                    closeAfterTransition
                    BackdropComponent={Backdrop}
                    BackdropProps={{
                    timeout: 500,
                    }}
                >
                {/* <Fade in={this.state.open}> */}
                    <DialogTitle>
                       Add Template
                    </DialogTitle>
                    <DialogContent  style={{width:'400px'}} className="add-template-modal">   
                        {/* <form
                        id="transition-modal-description"
                        onSubmit={this.handleOnClick}
                        > */}
                         <label>Name:</label>               
                        <input 
                          name="name" 
                          value={this.state.name}
                          onChange= {(e) => {this.setState({name: e.target.value, MissingName: false, ValidName:false })}}
                          onBlur={this.validateName}/>          
                      {this.state.MissingName ? <div className="error-container"><span> Name is required</span></div>: <></>}
                      {this.state.ValidName && ( <div className="error-container"><span> Name in use</span></div>)}

                      <br/>                      
                      <label id="demo-simple-select-label">Accounts:</label>
                      <Select className="selectInput"
                          data-id="demo-simple-select"
                          name="type"
                          value={this.state.AccountValue}
                          onChange= {(e) => {this.setState({AccountValue: String(e.target.value)})}} 
                      >
                        {this.props.Account.map((value:any, key:any) => (
                          <MenuItem key={key} value={value.id}>{value.name}</MenuItem>
                        ))}
                      </Select>
                      <br/>                      
                      <label id="demo-simple-select-label">Campaigns:</label>
                      <Select className="selectInput"
                          data-id="demo-simple-select"
                          name="type"
                          value={this.state.CampaignValue}
                          onChange= {(e) => {this.setState({CampaignValue: String(e.target.value)})}} 
                      >
                        {this.props.Campaign.map((value:any, key:any) => (
                          <MenuItem key={key}  value={value.id}>{value.name}</MenuItem>
                        ))}
                      </Select>
                    </DialogContent>
                    <DialogActions> 
                        {/* <div className="actionArea"> */}
                            <div>
                                <Button  onClick={this.handleDialogClose} color="primary">
                                    Cancel
                                </Button>
                            </div>
                            <div>
                                <Button onClick={this.handleOnClick} color="primary">
                                Apply
                                </Button>
                            </div>
                            
                        {/* </div> */}
                    </DialogActions>
                </Dialog>
                        {/* </form>
                    </div>
                    </Fade> */}
                {/* </Modal> */}
            </div> 
      );
    };
  }
  
  export default connect(null, mapDispatchToProps)(AddTemplate);
  