import React from 'react';
import { Resizable } from 're-resizable';
import { connect } from 'react-redux';
import { Dispatch, bindActionCreators } from 'redux';
import { IRootState, OverlaysActions, TemplateActions, OverlayData } from '../../../../../../redux';
import './styles/overlay.scss';

export interface OverlayProps {
    index:number,           // the index of the overlay inside the array of OverlayArray
    sceneDuration: number,  // the duration of the video in seconds with 3 decimals
    cssHeight:number,
    ovClasses:string,
    updateHeight: any
    //-----------------------------------------------------
    template: any,
    overlaysInScene: Array<OverlayData>,
    currentScene: number,
    //-----------------------------------------------------
    updateOverlayJson: typeof TemplateActions.asyncActionCreators.updateOverlay,
    updateTemplate: typeof TemplateActions.actionCreators.updateTemplate,
    updateOverlay: typeof OverlaysActions.OverlaysActions.updateOverlay,
}

const mapStateToProps = (state: IRootState) => {
    return {
      template: state.template.present.json,
      overlaysInScene: state.overlaysInScene.current,
      currentScene: state.template.present.currentScene
    }
}

const mapDispatchToProps = (dispatch:Dispatch): Pick<OverlayProps, 'updateTemplate' | 'updateOverlayJson' | 'updateOverlay' >  => ({
    updateTemplate: bindActionCreators(TemplateActions.actionCreators.updateTemplate, dispatch),
    updateOverlayJson: bindActionCreators(TemplateActions.asyncActionCreators.updateOverlay, dispatch),
    updateOverlay: bindActionCreators(OverlaysActions.OverlaysActions.updateOverlay, dispatch),  
});


class Overlay extends React.Component<OverlayProps> {
    state:any;
    contRef:any;        // reference to div class="overlay-container"
    ovRef:any;          // reference to div class="overlay-wrapper"
    resizableRef:any;   // reference to div class="toggleOverlay-container"
    toggleOV:boolean;   // is used to avoid calling updateOverlay for second time when the overlay detects 'onMouseUp'
    changeDetected:boolean;
    resizing:boolean;
    _isMounted = false;
    constructor(props:any) {
        super(props);

        this.state = {
            start:  this.props.overlaysInScene[this.props.index] ?  this.props.overlaysInScene[this.props.index].startPoint : '' ,
            end: this.props.overlaysInScene[this.props.index] ?  this.props.overlaysInScene[this.props.index].endPoint :'',
            overlayVisible: true,
            mouseDown: false,
            init: false,
            xClicked: 0,           
            initialWidth: this.props.overlaysInScene[this.props.index] ? this.props.overlaysInScene[this.props.index].width : '',
            leftPosition: '0',
            leftPosInPerc: '',
            mainvalue: '0',
            containerWidthinPixel: 0,
            overlayWidthInPixels: 0,
            overlayLeftInPixels: 0
        };

        this.contRef = React.createRef();
        this.ovRef = React.createRef();
        this.resizableRef = React.createRef();
        this.toggleOV = false;
        this.changeDetected = false;
        this.resizing = false;

        this.updateOffsetValues = this.updateOffsetValues.bind(this);
        this.updateOverlayDimensions = this.updateOverlayDimensions.bind(this);
        this.handleResizingOverlay = this.handleResizingOverlay.bind(this);
        this.getOverlayWidthAndLeftFromTemplate = this.getOverlayWidthAndLeftFromTemplate.bind(this);
        this.handleMouseDown = this.handleMouseDown.bind(this);
        this.handleMoveOverlay = this.handleMoveOverlay.bind(this);
        this.handleMouseOut = this.handleMouseOut.bind(this);
    }

    componentDidMount() {    
        // here we get the width and left values from the template
        this._isMounted = true;
            this.getOverlayWidthAndLeftFromTemplate();
            setTimeout(() => { 
               
                this.updateOffsetValues();
            }, 500);
        
    }

    getOverlayWidthAndLeftFromTemplate() {
        // gets all the overlay info from the template in the json editor
        //const overlayInTemplate:any = this.props.template.scenes[this.props.currentScene].overlays[this.props.index];
        
        let overlayInTemplate:any = this.props.overlaysInScene[this.props.index];
   
        // 'leftOv' will store the CSS value of the leftPosition of the overlay 
        // in percentage related to the overlayContainer
        let startTime:number, 
            leftOv:any, 
            endTime:number;

            if (overlayInTemplate !== undefined && (overlayInTemplate.startPoint !== undefined) && (overlayInTemplate.startPoint !== 0)) {
                // here we have calculated the left position of the overlay already in %
                startTime = Number(((overlayInTemplate.startPoint * 100) / this.props.sceneDuration).toFixed(3));
                // stores this value in CSS format, which will be the new 'leftPosition' property of the state
                leftOv = String(startTime) + '%';
            }
            else {
                startTime = 0;
                // if start.time is undefined or zero, leftOv is zero too
                leftOv = "0%";
            }

            if(overlayInTemplate !== undefined && (overlayInTemplate.endPoint !== undefined) && (overlayInTemplate.endPoint !== this.props.sceneDuration)) {
                // here we have calculated the end of the overlay already in %            
                endTime = Number(((overlayInTemplate.endPoint * 100) / this.props.sceneDuration).toFixed(3));
            }
            else {
                // if end.time is undefined or equal to the length of the video, we assign to endTime the value of 100%
                endTime = 100;
            }

          
        // here we calculate the width of the overlay in %
        const overlayW:number = endTime - startTime;
        
        const ow:string = overlayW.toFixed(2) + '%';

        // we update the width of the currentOverlay value in the local state      
        this.setState(() => {
            return {
                ...this.state,
                initialWidth: ow,
                leftPosition: leftOv,
                leftPosInPerc: startTime,
                containerWidthinPixel: this.ovRef.current.offsetWidth,
                overlayWidthInPixels: this.resizableRef.current.offsetWidth,
                overlayLeftInPixels: this.resizableRef.current.offsetParent.offsetLeft
            }
        });
        // setTimeout(() => { 
        //     this.updateOffsetValues();
        // }, 500);
    }

    componentWillUnmount(){
        this._isMounted = false;
    }
    // componentDidUpdate(prevProps: any) {
    //     if((prevProps !== this.props) && this.props.template.scenes[this.props.currentScene].overlays[this.props.index]) {
    //         this.setState(() => {
    //             return {
    //                 ...this.state
    //             }
    //         });
    //     }
    // }
    updateOffsetValues() {
        // helper function to get the absolute values in pixels of the elements needed for the calculations
        if(this._isMounted){
        this.setState(() => {
            return {
                ...this.state,
                containerWidthinPixel: this.ovRef.current.offsetWidth,
                overlayWidthInPixels: this.resizableRef.current.offsetWidth,
                overlayLeftInPixels: this.resizableRef.current.offsetParent.offsetLeft
            }
        });
    }
    }

    updateOverlayDimensions() {  

        // if there was a real change of the values -> we dispatch the actions         
        if(this.changeDetected){
            // here we dispatch the 'updateOverlay' action of the Overlay Reducer
            // passing all values that need to be updated in the OverlayArray of the redux store
                this.props.updateOverlay({
                    index: this.props.index,
                    width: this.state.initialWidth,
                    startPoint: this.state.start,
                    endPoint: this.state.end                    
                });
            // here we dispatch the 'updateOverlay' action of the Template Reducer
            // passing all values that need to be updated in the Overlays array of the Json Template of the redux store
            this.props.updateOverlayJson({
                currentScene: this.props.currentScene,
                index: this.props.index,
                startPoint: this.state.start,
                endPoint: this.state.end                      
            });


        }
        // we reset the value for changeDetected to false, that way we will dispatch the 'updateOverlay' action
        // only when there is real changes of the properties values
        this.changeDetected = false;
    }

    handleResizingOverlay() {    
        // we indicate there was a change, so will be able to dispatch the 'updateOverlay' action
        this.changeDetected = true;
        // updates the absolute values of the elements needed in case the window or timeline were resized
        // and to get the updated width of the overlay after resizing
        this.updateOffsetValues();

        // calculates the new % value of the overlay after been resized
        const widthPercentage:number = Number(this.state.overlayWidthInPixels * 100 / this.state.containerWidthinPixel);

        // 'widthString' contains that percentage value in a format readable by CSS code
        const widthString:string = widthPercentage.toFixed(3) + "%";

        // we calculate the value in seconds for the duration of the overlay
        const w:number = Number(widthPercentage.toFixed(3));
        const newOvDuration:number = Number((this.props.sceneDuration * w / 100).toFixed(3));

        // we update the modified values of 'currentOverlay' in the local state
        // we calculate the new value for 'endPoint' that will be updated in state.currentOverlay
        // we have to consider the value of 'startPoint' because it can be different than zero
        const newEndPoint:number = this.state.start + newOvDuration;

        // we update the value in the local state
        this.setState(() => {
            return {
                ...this.state,
                initialWidth: widthString,
                end: Number(newEndPoint.toFixed(3))
            }
        });

        this.updateOverlayDimensions();

        this.resizing = false;
    }

  

    handleMouseDown(e:any) {
        const newX = e.nativeEvent.clientX;

        // we detect the x co-ordinate of the point where the mouse click ocurred
        // and we store that co-ordinate in the property "xClicked" of the local state

        if(!this.resizing) {

            if(!this.state.mouseDown) {
                document.getElementsByClassName('elements-container')[0].addEventListener('mousemove', this.handleMoveOverlay);
                this.setState(() => {
                    return {
                        mouseDown: true,
                        xClicked: newX,
                        mainvalue: this.state.leftPosition
                    }
                });
               
               // document.getElementsByClassName('elements-container')[0].addEventListener('mouseout', this.handleMouseOut);

                this.props.updateHeight(true);
            }
            else {
                this.updateOverlayDimensions();

                this.setState(() => {
                    return {
                        mouseDown: false         
                    }
                });
                document.getElementsByClassName('elements-container')[0].removeEventListener('mousemove', this.handleMoveOverlay);
               // document.getElementsByClassName('elements-container')[0].removeEventListener('mouseout', this.handleMouseOut);

                this.props.updateHeight(false);
            }
        }
    }

    handleMouseOut() {
        if(this.state.mouseDown) {
            this.updateOverlayDimensions();
        }
        this.setState(() => {
            return {
                mouseDown: false         
            }
        });
        this.props.updateHeight(false);
    }

    handleMoveOverlay(e:any) {
    // we do this only if the mouse is clicked (this.state.mouseDown == true)
        if(this.state.mouseDown) {

            let newX:number = e.clientX;

            // this gives us the x co-ordinate of the cursor relative to the position
            // where the user clicked, so we can detect if is moving forward or backwards
            if(newX !== this.state.xClicked) {
                //const divWrapper = document.getElementsByClassName('overlay-wrapper')[0] as HTMLElement;
               // const divWrapperWidth = divWrapper.offsetWidth;
           

                // calculate max-left position posible (%), will will be 100% - initialWidth
                //const maxLeft = 100 - Number(this.state.overlaysInCurrentSceneValues[this.state.overlayMoving].initialWidth.slice(0, -1));
                // calculate the x value for that max-left position
                //const maxLeftInPixels = (divWrapperWidth / 100) * maxLeft;
                // we do the operations only if the mouse is moving
                this.changeDetected = true;
                let newLeftInPixels:number
                if(newX >= 300 ){
                 newLeftInPixels  = (newX -300)- (this.state.xClicked - 300);
                }else{
                    newLeftInPixels = 0
                }
                const newLeft:number = Number((newLeftInPixels * 100 / this.state.containerWidthinPixel).toFixed(3));
                let newLeftInPerc:number;
                
                
                   
                if(newLeft > 0){
                    newLeftInPerc = Number(( Number(this.state.mainvalue.slice(0, -1)) + newLeft).toFixed(3));
                   
                }else if(newLeft === 0){
                    newLeftInPerc = Number(( newLeft).toFixed(3));
                }else{               
                    newLeftInPerc = Number(( Number(this.state.mainvalue.slice(0, -1)) + newLeft).toFixed(3));
                }

                let newLeftP:number = Number((this.state.overlayLeftInPixels + newLeftInPixels).toFixed(3));
                let maxLeftInPixels:number = Number((this.state.containerWidthinPixel - this.state.overlayWidthInPixels).toFixed(3));
                let maxLeftPerc:number = Number((maxLeftInPixels * 100 / this.state.containerWidthinPixel).toFixed(3));
                // if the final position is out of the container from the left, we make the leftPosition = 0
                if(newLeftInPerc < 0) {
                    newLeftInPerc = 0;
                    newLeftP = 0;
                }
                // if it's out from the right, we make leftPosition equal to the maximum left allowed
                else if(newLeftInPerc > maxLeftPerc) {
                    newLeftInPerc = maxLeftPerc;
                    newLeftP = maxLeftInPixels;
                }

                // we save the new left in percentage which will be used for the css styles
                //const newLeftString:string = newLeftInPerc + '%';

                // we calculate the new value for 'startPoint' that will be updated in state.currentOverlay
                let newStart:number = 0;
                if(newLeftInPerc > 0) {
                    // here we transform the left in percentage to be in seconds
                    newStart = Number((this.props.sceneDuration * newLeftInPerc / 100).toFixed(3));
                }

                // we update the modified values of 'currentOverlay' in the local state
                let amountMoved:number = newStart - this.state.start;
                let newEnd = Number((parseFloat(this.state.end) + amountMoved).toFixed(3));


                    this.setState(() => {
                        return {
                            ...this.state,
                            leftPosition: newLeftInPerc+ '%',
                            leftPosInPerc: newLeftInPerc,
                            overlayLeftInPixels: newLeftP,
                            start:newStart,
                            end: newEnd
                        }
                    });
                
            }
            else {
                this.changeDetected = false;
                return false;
            }
        }
        // mouse not clicked, mouse is simply passing over the overlay => we don't need to do anything
        else {
            return false;
        }
    }

    render() {
        return (            
            <div className="overlay-container" 
                 ref={this.contRef}    
                 
                 style={{ height: (this.props.cssHeight+8) + 'px' }}
            >
                <div className="overlay-wrapper" 
                    ref={this.ovRef}
                >
                    <Resizable
                        defaultSize={{ width: this.state.initialWidth, height: this.props.cssHeight}}
                        size={{width: this.state.initialWidth, height: this.props.cssHeight}}
                        className={this.props.ovClasses}
                        bounds={this.contRef.current}
                        enable={{left:true, right:true}}
                        style={(this.props.cssHeight > 40) 
                            ? { left:this.state.leftPosition, height: this.props.cssHeight , backgroundColor:'#57c38f' }
                            : { left:this.state.leftPosition, height: this.props.cssHeight }
                        }                        
                        onResizeStop={this.handleResizingOverlay}
                        onResizeStart={() => { this.resizing = true; }}
                    >
                        <div className="toggleOverlay-container" 
                            ref={this.resizableRef} 
                            onClick={this.handleMouseDown}
                            //onMouseUp={this.handleMouseUp}
                           // onMouseOut={this.handleMouseOut}
                            //onMouseMove={this.handleMoveOverlay} 
                        >
                        {this.state.overlayVisible ?                             
                            <span className="overlay_number noselect"
                                  style={{
                                            lineHeight: this.props.cssHeight + 'px', 
                                            fontSize: (this.props.cssHeight - 5) + 'px'
                                        }}
                            >
                                {this.props.index}
                            </span> : <span style={{display:'none'}}></span>
                        }
                        </div>
                    </Resizable>
                </div>
            </div>            
        );
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(Overlay);