import { reducerWithInitialState } from 'typescript-fsa-reducers';
import { OidcActions } from '../actions';
import { UserState } from 'redux-oidc';

const initialState: UserState = {
  isLoadingUser: true
};

export const oidcReducer = reducerWithInitialState(initialState).case(
  OidcActions.OidcActions.updateUserState,
  (state, { user, isLoadingUser }) => {
    let c: UserState = { user, isLoadingUser };
    return c;
  }
);
