import React from 'react';
import { connect } from 'react-redux';
import { Dispatch, bindActionCreators } from 'redux';
import { Add } from '@material-ui/icons';
import { EditorSettingsActions } from '../../../../../redux';

export interface AddOvProps {
    overlayToAddNew: typeof EditorSettingsActions.EditorSettingsActions.overlayToAddNew
}

const mapDispatchToProps = (dispatch:Dispatch) : Pick<AddOvProps, 'overlayToAddNew'> => ({
    overlayToAddNew: bindActionCreators(EditorSettingsActions.EditorSettingsActions.overlayToAddNew, dispatch)
});

const AddOverlayBtn = (props:AddOvProps) => {
    return (
        <button
            id="addOverlay" 
            className="btn-with-border noselect"
            onClick={ () => { props.overlayToAddNew({}); } }
        >
            Add Layer 
            <Add />
        </button>
    )
}

export default connect(null, mapDispatchToProps)(AddOverlayBtn);