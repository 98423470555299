import { Add, ExpandMore, KeyboardBackspace } from '@material-ui/icons';
import React from 'react';
import { connect } from 'react-redux';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { TemplateVersionSummary } from '../models/TemplateVersionSummary';
import TemplateVersion from './TemplateVersion';
import { RouteComponentProps } from 'react-router';
import { Dispatch, bindActionCreators } from 'redux';
import {PlayerApiActions} from '../../../api/actions';

import '../styles/template.scss';

export interface TemplateVersionListProps extends RouteComponentProps<{ templateId: string }> {
    getTemplateVersions: typeof PlayerApiActions.actionCreators.getTemplateVersions
  }

export interface TemplateVersionListState {
  currentPage: number;
  rowsPerPage: number;
  totalPages: number;
  rowsPerPageOptions: number[];
  templateId?: string;
  versions: TemplateVersionSummary[];
}


// ********************************************************************************

const mapDispatchToProps = (dispatch: Dispatch): Pick<TemplateVersionListProps, 'getTemplateVersions'> => ({
  getTemplateVersions: bindActionCreators(PlayerApiActions.actionCreators.getTemplateVersions, dispatch)
});
// ********************************************************************************

class TemplateVersionList extends React.Component<TemplateVersionListProps,TemplateVersionListState> {
  constructor(props: TemplateVersionListProps) {
    super(props);

    this.state = {
      currentPage: 0,
      rowsPerPage: 8,
      totalPages:0,
      rowsPerPageOptions: [8, 16, 24, 32, 40,48],
      versions: []
    };
  
    this.handleOnChangePagePrev = this.handleOnChangePagePrev.bind(this);
    this.handleOnChangePageNext = this.handleOnChangePageNext.bind(this);
    this.onChangeRowsPerPage = this.onChangeRowsPerPage.bind(this);
  }

  // public componentDidMount = async () => {
  // //   let versions = defaultVersions;
  // //   let total = 0;
  // //   const params : IDictionary<string> ={"templateId":this.props.match.params.templateId};
  // //   const request : IRequest<any> = {params};
  // //   const templateVersionsResponse = await this.props.getTemplateVersions(request);

  // //   if (templateVersionsResponse !== null && templateVersionsResponse.ok&& templateVersionsResponse.versions !== null) {
  // //     versions = templateVersionsResponse.versions;
  // //   } 
  // //   total =  parseInt((versions.length / this.state.rowsPerPage).toString());
  // //   this.setState(() => ({ versions, totalPages: total}));
  // };

  // public componentDidUpdate(prevProps: any){
  //   if(prevProps !== this.props) {
  //     this.setState(() => ({  totalPages: Number((this.state.versions.length / this.state.rowsPerPage).toFixed(0))}));
  //   }
  // }

  public handleOnChangePagePrev = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ) => {

    if(this.state.currentPage > 0){
      this.setState(() => ({ currentPage: (this.state.currentPage -1)}));
    }
  };

  public handleOnChangePageNext = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ) => {

    if(this.state.currentPage < this.state.totalPages){
      this.setState(() => ({ currentPage: (this.state.currentPage + 1)}));
    }
  };

  public onChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {

    const { currentPage, rowsPerPage, versions } = this.state;

    const newRowsPerpage = parseInt(event.target.value, 8);

    const prevMaximumPageNumber = Math.ceil(versions.length / rowsPerPage);
    const maximumPageNumber = Math.ceil(versions.length / newRowsPerpage);

    const delta = Math.floor(
      maximumPageNumber * (currentPage / prevMaximumPageNumber)
    );
    const newCurrentPage = Math.min(delta, delta * newRowsPerpage);

    this.setState(() => ({
      currentPage: newCurrentPage,
      rowsPerPage: newRowsPerpage
    }));
  };

  public render = () => {
    const {
      currentPage,
      rowsPerPage,
      versions
    } = this.state;

    const startPoint = currentPage * rowsPerPage;
    //const emptyRows = rowsPerPage - Math.min(rowsPerPage, versions.length - startPoint);

    const visibleVersions: TemplateVersionSummary[] = versions.slice(
      startPoint,
      startPoint + rowsPerPage
    );

    return (
      <div className="pageContainer">
        <h2>VIDEO LIBRARY</h2>
        <div className="actions-container">
          <button className="btn-with-border noselect">New Version &nbsp;&nbsp;<Add /></button>
          <div className="sortAndPagination">
            <div className="sort">
              SORT BY
              <button className="btn-with-border noselect sortBy"><ExpandMore /></button>
            </div>
            <div className="pagination">
              <span>{this.state.currentPage +1} of {this.state.totalPages + 1}</span>
              <button onClick={this.handleOnChangePagePrev} className="btn-with-border noselect prev"><KeyboardBackspace /></button>
              <button onClick={this.handleOnChangePageNext} className="btn-with-border noselect next"><KeyboardBackspace /></button>
            </div>
            <div className="pagination">
                  <FormControl className="selectControl">
                        
                        <Select className="selectInput"
                            data-id="demo-simple-select"
                            name="rowsPerPage"
                            value={this.state.rowsPerPage}
                            onChange= {(e) => {this.setState({rowsPerPage: Number(e.target.value), totalPages: parseInt((versions.length /  Number(e.target.value)).toString())})}} 
                        >
                          {this.state.rowsPerPageOptions.map((value:number, index:number)=>(
                                   <MenuItem key= {index} value={value}>{value}</MenuItem>
                          ))}
                           
                        </Select>
                  </FormControl>
              </div>
          </div>
        </div>

        <div className="templatesContainer">
          <div className="templatesInnerContainer">
            {visibleVersions.length > 0 ? (
              visibleVersions.map((version: TemplateVersionSummary, index: number) => (
                <TemplateVersion
                  key={version.versionNumber}
                  version={version}
                ></TemplateVersion>
              ))
            ) : (            
              <div className="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
            )}
          </div>
        </div>
      </div>
  );

    /*return (
      <div>
        <h2>Template Version List</h2>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Template Id</TableCell>
              <TableCell>Version Number</TableCell>
              <TableCell>Environment</TableCell>
              <TableCell>Comment</TableCell>
              <TableCell>Created at</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {visibleVersions.length > 0 ? (
              visibleVersions.map(
                (version: TemplateVersionSummary, index: number) => (
                  <TemplateVersion
                    key={version.versionNumber}
                    version={version}
                  ></TemplateVersion>
                )
              )
            ) : (
              <TableRow hover tabIndex={-1}>
                <TableCell colSpan={5} align="center">
                  No Templates Available
                </TableCell>
              </TableRow>
            )}
            {emptyRows > 0 && (
              <TableRow style={{ height: 49 * emptyRows }}>
                <TableCell colSpan={6} />
              </TableRow>
            )}
          </TableBody>
        </Table>
        <TablePagination
          rowsPerPageOptions={rowsPerPageOptions}
          component="div"
          count={versions.length}
          rowsPerPage={rowsPerPage}
          page={currentPage}
          backIconButtonProps={{ 'aria-label': 'Previous Page' }}
          nextIconButtonProps={{ 'aria-label': 'Next Page' }}
          onChangePage={this.handleOnChangePage}
          onChangeRowsPerPage={this.onChangeRowsPerPage}
        />
      </div>
    );*/

  };
}

export default connect(null, mapDispatchToProps)(TemplateVersionList);
