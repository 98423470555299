import { actionCreatorFactory } from 'typescript-fsa';

enum Type {
  AddOverlay = 'ADD_OVERLAY',
  DeleteOverlay = 'DELETE_OVERLAY',
  UpdateOverlay = 'UPDATE_OVERLAY',
  UpdateOverlayFromJson = 'UPDATE_OVERLAY_FROM_JSON',
  RedoOverlayFromJson = 'REDO_OVERLAY_FROM_JSON',
  UndoOverlayFromJson = 'UNDO_OVERLAY_FROM_JSON',
  InitializeOverlayFromJson = 'INITIALIZE_OVERLAY_FROM_JSON',
  ResetOverlaysInScene = 'RESET_OVERLAYS_IN_SCENE',
  ResetStore = 'RESET_STORE'
}

const create = actionCreatorFactory('OVERLAY');

export const OverlaysActions = {
  addOverlay: create<any>(Type.AddOverlay),
  deleteOverlay: create<number>(Type.DeleteOverlay),
  updateOverlay: create<any>(Type.UpdateOverlay),
  updateOverlayFromJson: create<any>(Type.UpdateOverlayFromJson),
  RedoOverlayFromJson: create<any>(Type.RedoOverlayFromJson),
  UndoOverlayFromJson: create<any>(Type.UndoOverlayFromJson),
  InitializeOverlayFromJson: create<any>(Type.InitializeOverlayFromJson),
  resetOverlaysInScene: create<any>(Type.ResetOverlaysInScene),
  resetStore: create<any>(Type.ResetStore)
}