import * as TemplateActions from './TemplateActions';
import * as OverlaysActions from './OverlaysActions';
import * as OidcActions from './OidcActions'
import * as CampaignActions from './CampaignActions';
import * as EditorSettingsActions from './EditorSettingsActions';

export {
 TemplateActions,
 OverlaysActions,
 OidcActions, 
 CampaignActions,
 EditorSettingsActions
}