import React from 'react';
import { connect } from 'react-redux';
import { IRootState, TemplateActions , OverlaysActions} from '../../../redux';
import { Dispatch, bindActionCreators } from 'redux';
import { EditorSettingsActions } from '../../../redux/actions';
import Tab from './components/ScenesTabs/Tab/Tab';
import TimelineBar from "./components/timelineBar/timelineBar";
import TopBar from './components/topBar/topBar';
import ScenesTabs from './components/ScenesTabs/ScenesTabs';
import ElementsBar from './components/ElementsBar/ElementsBar';
import ElementsContainer from './components/ElementsContainer/ElementsContainer';

import 'simplebar/dist/simplebar.min.css';

export interface TimelineProps { 
  videoSource: any,
  template: any,
  overlaysInScene: Array<Object>,
  currentScene: number,
  scenesInTemplate: any,
  addingNewOv: boolean,
  editing: boolean,
  readyToPaint: boolean,
  subtitlesReady:boolean,
  subtitleOptions: any,
  subtitlesInTemplate: any,
  subtitlesVisible:boolean,
  overlaysVisible:boolean,
  readyTopBar:boolean,
  refreshCanvas:boolean,
  //-----------------------------------------------------
  applyVariableValue: typeof TemplateActions.asyncActionCreators.applyVariableValue,
  initializeVariable: typeof TemplateActions.actionCreators.initializeVariable,
  updateOverlayFromJson:typeof OverlaysActions.OverlaysActions.updateOverlayFromJson,
  subtitlesPainted:typeof EditorSettingsActions.EditorSettingsActions.subtitlesPainted,
  loadingData:typeof EditorSettingsActions.EditorSettingsActions.loadingData,
  refreshTopBar:typeof EditorSettingsActions.EditorSettingsActions.refreshTopBar,
  closeSettings: typeof EditorSettingsActions.EditorSettingsActions.closeSettings,
  refreshCanvasAction: typeof EditorSettingsActions.EditorSettingsActions.refreshCanvas,
  refreshOverlays: typeof EditorSettingsActions.EditorSettingsActions.refreshOverlays,
  updateOverlay: typeof OverlaysActions.OverlaysActions.updateOverlay
}

const mapStateToProps = (state:IRootState) => {
  return {
    template: state.template.present, 
    scenesInTemplate: state.template.present.json.scenes,
    videoSource: state.template.present.json.scenes[state.template.present.currentScene].videoSource,
    subtitleOptions: state.template.present.json.subtitleOptions,
    subtitlesInTemplate: state.template.present.json.scenes[state.template.present.currentScene].subtitles,
    currentScene: state.template.present.currentScene,
    overlaysInScene: state.overlaysInScene.current,
    addingNewOv: state.editorSettings.addNewfunction,
    editing: state.editorSettings.editorVisible,
    readyToPaint: state.editorSettings.readyToPaint,
    subtitlesVisible: state.editorSettings.editingSubtitles,
    overlaysVisible: state.editorSettings.editingOverlays,
    subtitlesReady: state.editorSettings.subtitlesReady,
    readyTopBar: state.editorSettings.readyTopBar,
    refreshCanvas: state.editorSettings.refreshCanvas
  }
}

const mapDispatchToProps = (dispatch:Dispatch): Pick<TimelineProps, 'updateOverlay'| 'refreshOverlays' |'refreshCanvasAction' | 'applyVariableValue' | 'refreshTopBar' | 'loadingData' | 'closeSettings' | 'subtitlesPainted' | 'updateOverlayFromJson'| 'initializeVariable' > => ({
  updateOverlayFromJson: bindActionCreators(OverlaysActions.OverlaysActions.updateOverlayFromJson, dispatch), 
  loadingData: bindActionCreators(EditorSettingsActions.EditorSettingsActions.loadingData, dispatch),
  refreshTopBar:bindActionCreators(EditorSettingsActions.EditorSettingsActions.refreshTopBar, dispatch),
  closeSettings: bindActionCreators(EditorSettingsActions.EditorSettingsActions.closeSettings, dispatch),
  subtitlesPainted: bindActionCreators(EditorSettingsActions.EditorSettingsActions.subtitlesPainted, dispatch),
  refreshCanvasAction: bindActionCreators(EditorSettingsActions.EditorSettingsActions.refreshCanvas, dispatch),
  initializeVariable: bindActionCreators(TemplateActions.actionCreators.initializeVariable,dispatch),
  applyVariableValue: bindActionCreators(TemplateActions.asyncActionCreators.applyVariableValue, dispatch),
  refreshOverlays: bindActionCreators(EditorSettingsActions.EditorSettingsActions.refreshOverlays, dispatch),
  updateOverlay: bindActionCreators(OverlaysActions.OverlaysActions.updateOverlay, dispatch)
});


class Timeline extends React.Component<TimelineProps> {
  video:any;
  state:any;
  frameRate: number;
  containerRef:any;
  defaultRegion:string;

  constructor(props:any) {
    super(props);

    this.state = {
      tabs: <Tab key={0} index={0} refreshTabs={this.paintSceneTabs} />,
      currentFrame: 0,
      leftPosition: 0,
      leftThumbnail: 0,
      thumbnailVisible: false,
      timeToDisplay: "",
      totalFrames: 0,
      videoDuration: 0,
      videoLoaded: false,
      outerContainerHeight: 0,
      subtitlesToShow: <div className='subtitle-editor'>Subtitles empty...</div>,
      valueRefresh: false,
      overlayMoving: 0,
      newOverlayLeft: 0,
      mouseClicked:false
    };

    this.frameRate = 0;
    this.video = document.getElementsByTagName('video')[0];
    this.containerRef = React.createRef();
    this.defaultRegion = "eu-west-1"; //default region is set to Ireland for old templates i.e. before aws region became mandatory when creating new ones.
    this.getInitialVideoValues = this.getInitialVideoValues.bind(this);
    this.changeOfScene = this.changeOfScene.bind(this);
    this.InitializeSavedOverlays = this.InitializeSavedOverlays.bind(this);
    this.callInitializeVariable = this.callInitializeVariable.bind(this);
    this.updateVideoAndInterface = this.updateVideoAndInterface.bind(this);
    this.refreshView = this.refreshView.bind(this);
    this.minTwoDigits = this.minTwoDigits.bind(this);
    this.goToNextFrame = this.goToNextFrame.bind(this);
    this.goToPreviousFrame = this.goToPreviousFrame.bind(this);
    this.updateTimeDisplay = this.updateTimeDisplay.bind(this);
    this.updateTimelineBar = this.updateTimelineBar.bind(this);
    this.handleClickOnTimeline = this.handleClickOnTimeline.bind(this);
    this.handleHoverOnTimeline = this.handleHoverOnTimeline.bind(this);
    this.handleHoverOutTimeline = this.handleHoverOutTimeline.bind(this);
    this.muteVideo = this.muteVideo.bind(this);
    this.unmuteVideo = this.unmuteVideo.bind(this);
    this.playVideo = this.playVideo.bind(this);
    this.pauseVideo = this.pauseVideo.bind(this);
    this.paintSceneTabs = this.paintSceneTabs.bind(this);
  }

  getInitialVideoValues() {
    this.frameRate = this.props.videoSource.frameRate;
    this.setState( () => {
      return{
        ...this.state,
        // this.video.duration give us the time in seconds (e.g. 96.355)
        videoDuration: Number(this.video.duration.toFixed(3)),
        totalFrames: Math.floor(this.video.duration * this.frameRate)
      }
    });
  } 

  // we wait until the component is mount to set the initial values that need to be passed to the other components
  componentDidMount() {
    // this is to set the initial values that need to be passed to the other components and 
    // for when coming back from the templates list after having edited another scene before
    this.props.updateOverlayFromJson(
      { 
        currentScene:this.props.currentScene,
        payload:this.props.template.text
      }
    ); 
    this.getInitialVideoValues();
    // we wait for the video to load to get the video information, otherwise will be undefined
    this.updateTimeDisplay();
    if(this.props.template.initLoaded){
      this.props.initializeVariable(this.props.template.text); 
      this.refreshView();
    }   
  }

  componentDidUpdate(prevProps:any) {
    
   if(this.props.readyToPaint && !this.props.subtitlesVisible && prevProps.currentScene === this.props.currentScene) {
      this.props.updateOverlayFromJson(
        { 
          currentScene:this.props.currentScene,
          payload:this.props.template.text
        }
      ); 
      this.props.scenesInTemplate[this.props.currentScene].overlays.map((ov:any, index:number) => {
       this.props.updateOverlay({
        index: index,
        startPoint: ov.start.time,
        endPoint: ov.end.time      
       });
      });
      this.paintSceneTabs();
   }

   if(prevProps.currentScene !== this.props.currentScene) {
     this.changeOfScene();
   }
   
   if(this.props.refreshCanvas) {
     this.refreshView();
     this.props.refreshCanvasAction({ refreshCanvas: false });
   }
  }
  
  changeOfScene() {
    this.paintSceneTabs();
    this.refreshView();
  }

  InitializeSavedOverlays() {    
    if(this.props.scenesInTemplate[this.props.currentScene].overlays.length > 0){
      this.props.updateOverlayFromJson(
        { 
          currentScene:this.props.currentScene,
          payload:this.props.template.text
        }
      ); 
     
    } 
    this.paintSceneTabs();
  }
  callInitializeVariable(value){
    this.props.applyVariableValue({
      variable: value,
      result: "https://s3-eu-west-1.amazonaws.com/mbaaws-rci/122/87777A95-7FEF-4DD5-9F9C-9BEE78D44B18.mp4"
    });
  }

  updateVideoAndInterface() {
    this.props.loadingData({loading:true});
    this.video.onloadedmetadata = () => {
     
      this.getInitialVideoValues(); 
      this.props.refreshTopBar({ readyTopBar:false });
      this.setState(() => {
        return {
          ...this.state,
          videoLoaded: true,
          leftPosition: 0
        }
      });
      this.updateTimeDisplay();
      if(this.props.scenesInTemplate[this.props.currentScene].overlays !== undefined){
        this.InitializeSavedOverlays();
      }
      this.props.loadingData({ loading:false });    
      this.props.refreshOverlays({});
    }
  }

  refreshView() {   
    if(this.props.scenesInTemplate[this.props.currentScene].videoSource.type === "url") {
      // case the template comes with videosource. source like variable we need to allow the user insert a value for the variable , this way the page will load 
      if(this.props.scenesInTemplate[this.props.currentScene].videoSource.source.includes("{") && this.props.scenesInTemplate[this.props.currentScene].videoSource.source.includes("}")) {
       this.callInitializeVariable(this.props.scenesInTemplate[this.props.currentScene].videoSource.source);
        setTimeout(() => { 
          this.video.src = this.props.scenesInTemplate[this.props.currentScene].videoSource.source;
          this.updateVideoAndInterface();
        }, 1000);
      }
      else {
      // videoSource is not a variable -> we can directly load the video from the source specified in the template
        this.video.src = this.props.scenesInTemplate[this.props.currentScene].videoSource.source;
        this.updateVideoAndInterface();
      }
    }
    else if (this.props.scenesInTemplate[this.props.currentScene].videoSource.type === "s3") {     
      const s3Bucket = this.props.scenesInTemplate[this.props.currentScene].videoSource.s3Bucket;
      const s3Key = this.props.scenesInTemplate[this.props.currentScene].videoSource.s3Key;
      const s3Region = this.props.scenesInTemplate[this.props.currentScene].videoSource.s3Region;

      if(!s3Region){
        this.video.src = "https://" + s3Bucket + ".s3-" + this.defaultRegion + ".amazonaws.com/" + s3Key;
      }
      else{
        this.video.src = "https://" + s3Bucket + ".s3-" + s3Region + ".amazonaws.com/" + s3Key;
      }
     
      this.updateVideoAndInterface();
    }
  }  

  minTwoDigits(n) {
    // Returns always a 2 digit number, when less than 10 it will add a 0 in front
    return (n < 10 ? '0' : '') + n;
  }
  // we advance the video by one frame
  goToNextFrame() {
    if (this.state.currentFrame < this.state.totalFrames) {
      this.setState( (prevState:any) => {
        return {
          ...prevState,
          currentFrame: prevState.currentFrame + 1,
          leftPosition: Math.floor((prevState.currentFrame * 100) / prevState.totalFrames)
        }
      } );
    };
    // here we move 1 frame forward
    this.video.currentTime += (1/this.frameRate);
    this.updateTimeDisplay();
  }
  goToPreviousFrame() {
    if(this.state.currentFrame > 0) {
      this.setState( (prevState:any) => {
        return {
          ...prevState,
          currentFrame: prevState.currentFrame - 1,
          leftPosition: Math.floor((prevState.currentFrame * 100) / prevState.totalFrames)
        }
      } );
    };
        
    // here we move 1 frame rewind
    this.video.currentTime -= (1/this.frameRate);
    this.updateTimeDisplay();
  }

  updateTimeDisplay() {
    // New format:   0:03:20:02   hours:minutes:seconds:miliseconds
    let hours = Math.floor(this.video.currentTime / 3600);
    let minutes = Math.floor(this.video.currentTime / 60);
    let seconds = Math.floor(this.video.currentTime % 60);
    let miliseconds = Math.floor(((this.video.currentTime % 60) - seconds) * 100);
    // here we give format to the time that will be displayed accordingly to video.currentTime    
    let currentTime:string = this.minTwoDigits(hours) + ':' + this.minTwoDigits(minutes) + ':' + this.minTwoDigits(seconds) + ':' + this.minTwoDigits(miliseconds);
    
    this.setState( (prevState:any) => {
      return {
        ...prevState,
        timeToDisplay: currentTime
      }
    });   

    // if we get to the end of the video we hide the Pause icon and show again the Play icon
    if (this.video.currentTime === this.video.duration) {
      document.getElementsByClassName('timeline-controls')[0].classList.remove('playing');
    }
  }

  updateTimelineBar() {   
    // here we calculate the new value for 'currentFrame'
    // and the new value for 'leftPosition' depending on 'video.currentTime'
    this.setState( (prevState:any) => {
      return {
        ...prevState,
        currentFrame: Math.round(this.video.currentTime * this.frameRate),
        leftPosition: ((this.video.currentTime * 100) / this.video.duration).toFixed(2)
      }
    } );
    this.updateTimeDisplay();
  }

  handleClickOnTimeline(pointX:number, fullWidth:number) {
    // first we update the video.currentTime depending of the position clicked in the timeline
    this.video.currentTime = (((pointX * 100) / fullWidth) * this.video.duration) / 100;

    this.updateTimelineBar();
  }

  // here we show the video thumbnail when the mouse is over the timelineBar
  handleHoverOnTimeline(pointX:number, fullWidth:number) {
    if(this.state.videoLoaded) {
      if (!this.state.thumbnailVisible) {
        this.setState( (prevState:any) => {
          return {
            ...prevState,
            thumbnailVisible: true
          }
        } );
      }

      // it's 102px because the video is 100px and has 1px border on each side
      const xPointLimit = fullWidth - 102;
      // we calculate the new position for the videoThumbnail while the mouse is moving over the timeline
      this.setState( (prevState:any) => {
        if(pointX > xPointLimit) {
          return{
            ...prevState,
            leftThumbnail: xPointLimit
          }
        }
        else {
          return {
            ...prevState,
            leftThumbnail: pointX
          }
        }
      } );
    }
  }

  // here we simply hide the video thumbnail
  handleHoverOutTimeline() {
    this.setState( (prevState:any) => {
      return {
        ...prevState,
        thumbnailVisible: false
      }
    } );
  }

  muteVideo() {
    this.video.muted = true;
  }

  unmuteVideo(){
    this.video.muted = false;
  } 

  playVideo() {    
    this.video.play();
    // do this while video is playing    
    this.video.ontimeupdate = () => {
      this.updateTimelineBar();
    };
  }

  pauseVideo() {
    this.video.pause();    
    this.updateTimelineBar();
  } 

  paintSceneTabs() {
    let newTabs = this.props.scenesInTemplate.map((scene:any, index:number) => {
      return (
        <Tab 
            key={"tab" + index}
            index={index}
            refreshTabs={this.paintSceneTabs}
        />
      )
    }); 

    this.setState(() => {
        return {
            tabs: newTabs
        }
    });
 }

  render() {
    return(
      <div className="timeline-component-container"
        ref={this.containerRef}
      >
        {this.state.videoLoaded
        ? <>
            <TopBar 
              currentFrame={this.state.currentFrame}
              goToNextFrame={this.goToNextFrame} 
              goToPreviousFrame={this.goToPreviousFrame}
              muteVideo={this.muteVideo}
              pauseVideo={this.pauseVideo}
              playVideo={this.playVideo}
              totalFrames={this.state.totalFrames} 
              unmuteVideo={this.unmuteVideo}
              currentTime={this.state.timeToDisplay}
              videoDuration={this.video.duration}
              refreshTopBar={this.props.refreshTopBar}
              readyTopBar={this.props.readyTopBar}
            />
            <ScenesTabs tabs={this.state.tabs} updateTabs={this.paintSceneTabs}/>
            <ElementsBar />
            <TimelineBar
              leftPosition={this.state.leftPosition}
              leftThumbnail={this.state.leftThumbnail}
              handleClickOnTimeline={this.handleClickOnTimeline}
              handleHoverOnTimeline={this.handleHoverOnTimeline}
              handleHoverOutTimeline={this.handleHoverOutTimeline}
              thumbnailVisible={this.state.thumbnailVisible}
              videoSrc={this.video.src}
              videoDuration={this.video.duration}
            />
            <ElementsContainer 
              videoDuration={this.video.duration}
            />
          </>
        : <div style={{width:'100%', height:'inherit', textAlign:'center', display:'table-cell', verticalAlign:'middle'}}>
            <div className="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
          </div>
        }
      </div>
    );
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(Timeline);