import React from 'react';
import CampaignDetails from './campaignInfo';
import { IRootState , EditorSettingsActions, TemplateActions } from "../../redux";
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Dispatch, bindActionCreators } from 'redux';
import { PlayerApiActions } from '../../api/actions';
import { CampaignActions } from '../../redux/actions/CampaignActions';
import { IDictionary } from '../../api/models/IDictionary';
import { IRequest } from '../../api/models/requests/IRequest';
import { jsonTryParse } from '../../utils/jsonTryParse';
import { ICreateTemplateVersionRequest } from '../../api/models/requests/ICreateTemplateVersionRequest';
import {
    Dialog,
    DialogContent,
    DialogContentText,
    DialogTitle,
    DialogActions,
    Button
} from '@material-ui/core';
import { Close } from '@material-ui/icons';
import { KeyboardBackspace } from '@material-ui/icons';
import './styles/navBar.scss';

export interface NavProps {
    history: any;
    showcampaing:any,
    campinfo:any,
    template:any,
    isSaved:boolean,
    //-----------------------------------------------------------------
    showVariablesEditor: typeof EditorSettingsActions.EditorSettingsActions.showVariablesEditor,
    showCodeEditor: typeof EditorSettingsActions.EditorSettingsActions.showCodeEditor,
    showCampaignInfo:typeof EditorSettingsActions.EditorSettingsActions.showCampaignInfo,
    postTemplateVersion: typeof PlayerApiActions.actionCreators.postTemplateVersion,
    getInfo : typeof CampaignActions.getInfo,
    templateSaved: typeof TemplateActions.actionCreators.templateSaved
}

const mapStateToProps = (state:IRootState) => {
    return {
      template: state.template.present.text,
      showcampaing: state.editorSettings.showCampaignInfo,
      campinfo: state.campaign,
      isSaved: state.template.present.isSaved
    }
}
const mapDispatchToProps = (dispatch:Dispatch): Pick<NavProps, 'templateSaved' | 'showCodeEditor'| 'showVariablesEditor' | 'showCampaignInfo' | 'postTemplateVersion' | 'getInfo'> => ({
    showVariablesEditor: bindActionCreators(EditorSettingsActions.EditorSettingsActions.showVariablesEditor, dispatch),
    showCodeEditor: bindActionCreators(EditorSettingsActions.EditorSettingsActions.showCodeEditor,dispatch),
    showCampaignInfo: bindActionCreators(EditorSettingsActions.EditorSettingsActions.showCampaignInfo,dispatch),
    postTemplateVersion: bindActionCreators(PlayerApiActions.actionCreators.postTemplateVersion, dispatch),
    getInfo: bindActionCreators(CampaignActions.getInfo, dispatch),
    templateSaved: bindActionCreators(TemplateActions.actionCreators.templateSaved, dispatch)
});
  
class NavBar extends React.Component<NavProps> {
    state:any;
    constructor(props:NavProps) {
        super(props);
        this.state = {
            actionmodalBool: false,
            open:false,
            saveCliked:false,
            SaveComment: '',
            errorSaving: false
        }
        this.handleGoBack = this.handleGoBack.bind(this);
        this.handleDialogClose = this.handleDialogClose.bind(this);
        this.handlemodalcall = this.handlemodalcall.bind(this);
        this.handleGoForward = this.handleGoForward.bind(this);
        this.handleSavingTemplate = this.handleSavingTemplate.bind(this);
        this.handleClickSave = this.handleClickSave.bind(this);
        this.handleCloseSave = this.handleCloseSave.bind(this);
    }

    handleGoBack(){
        // we are in the Editor
        if(window.location.pathname !== '/') {
            // "template.isSaved == true"  =>  we go back to the template list
            if(this.props.isSaved) {
                this.handlemodalcall();
            }
            // template.isSaved == false  =>  we open the modal
            else {                
                this.setState(() => {
                    return {
                        actionmodalBool:true,
                        open:true
                    }
                });
            }
        }
        // we are in the Template List
        else {
            // do nothing
        }
    }

    handlemodalcall(){
        this.props.showCampaignInfo({showCampaignInfo:true});
        this.props.history.goBack();                
        this.props.showCodeEditor({codeEditor:false}); 
        this.props.showVariablesEditor({variablesEditor: false});

        this.setState(() => {
            return {
                actionmodalBool:false,
                open:false,
                saveCliked:false
            }
        });
        this.props.templateSaved({ isSaved: true });
    }

    handleGoForward(){
        this.props.history.goForward();
        this.props.showCampaignInfo({showCampaignInfo:false});
        
        if(window.location.pathname === '/') {
            console.log("href: " + window.location.href);
            console.log("pathname: " + window.location.pathname);
        }
    }

    handleClickSave(){
        this.setState(() => {return { saveCliked:true }});
    }

    handleCloseSave(){
        this.setState(() => {return { saveCliked:false }});
    }

    handleSavingTemplate= async () => {
        let  currentTemplate:any = jsonTryParse(this.props.template);
        const params: IDictionary<string> = {
            templateId: this.props.campinfo.templateid
        };
          const template: ICreateTemplateVersionRequest = {
            value:currentTemplate.isParsed ? currentTemplate.json : params.json,
            comment: this.state.SaveComment !== '' ? this.state.SaveComment : 'Go back save'
          };

          const request: IRequest<ICreateTemplateVersionRequest> = {
            params,
            body: template
          };
      
          try {
            const templateVersionsResponse = await this.props.postTemplateVersion(request);
            //update url
            if (templateVersionsResponse !== null && templateVersionsResponse.ok) {
              this.props.getInfo({
                name: this.props.campinfo.name,
                templateid: this.props.campinfo.templateid,
                version: templateVersionsResponse.versionNumber,
                newTemplate: false
              });
              this.handlemodalcall()
              this.props.templateSaved({ isSaved: true });
            } else { }
          }
          catch (e) {
            this.setState(() => {return { errorSaving:true }});
          }         
    }

    handleDialogClose(){
        this.setState(() => {
            return {
                actionmodalBool:false,
                open:false
            }
        });
    }

    render(){
        return (
            <nav>
                <div className="leftSide">
                    {/* <div id="hub-btn" className="btn-with-border">
                        <span className="noselect">HUB</span><Apps className="hub-icon" />
                    </div> */}
                    { this.props.isSaved 
                        ?
                            <Link to="/">
                                <div id="left-arrow" className="navArrow btn-with-border" >
                                    <KeyboardBackspace id="nav-back" />
                                </div>
                            </Link>
                        : 
                            <div id="left-arrow" className="navArrow btn-with-border" onClick={this.handleGoBack} >
                                <KeyboardBackspace id="nav-back" />
                            </div>
                    }
                    {/* <div id="right-arrow" className="navArrow btn-with-border" onClick={this.handleGoForward} >
                        <KeyboardBackspace id="nav-forward" />
                    </div> */}
                </div>

                <div className="rightSide">
                    {!this.props.showcampaing? <CampaignDetails/> : <></>}

                    <div className="textOnRightSide noselect">
                        VS Player Editor <sup>TM</sup>
                    </div>
                </div>
            
                { this.state.actionmodalBool ?
                    <Dialog fullScreen={false} open={this.state.open && !this.props.isSaved} onClose={this.handleDialogClose} aria-labelledby="editor-settings-modal-title">
                        <DialogTitle id="editor-settings-modal-title">
                            Alert
                            <Close
                                className="closeBtn"
                                onClick={this.handleDialogClose}
                            /> 
                      </DialogTitle>                                         
                      <DialogContent>
                          
                             <h4> Do you want to save the template?</h4>
                        </DialogContent>
                    <DialogActions> 
                          <Button className="deleteBtn" onClick={this.handleClickSave}>
                              Save Template
                          </Button>
                           {/* <SaveTemplate> Save Template</SaveTemplate> */}
                          <Button className="deleteBtn" onClick={this.handlemodalcall}>
                              Go Back withou saving
                          </Button>
                      </DialogActions>
                  </Dialog>
                : 
                <></>
                }
                { this.state.saveCliked ? <>
                <Dialog
                    fullScreen={false}
                    open={this.state.saveCliked}
                    aria-labelledby="save-template-prompt-title"
                    className="save-template-prompt"
                >
                    <DialogTitle id="save-template-prompt-title">Please insert a comment</DialogTitle>
                    <DialogContent>
                    
                        <input 
                            type="text" 
                            placeholder="a description for the changes"
                            onChange={(e) =>  { this.setState({ SaveComment: e.target.value }) }}
                        />
                   
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.handleCloseSave }>
                            Cancel
                        </Button>
                        <Button onClick={this.handleSavingTemplate}>
                            OK
                        </Button>
                    </DialogActions>
                    <div className="error-container">
                        { this.state.errorSaving 
                            ? <span>There was an error saving the template</span>
                            : <></> 
                        }
                    </div>
                </Dialog>
                </>
                : 
                <></>}

            </nav>
        )
    }
};

export default connect(mapStateToProps,mapDispatchToProps)(NavBar);