export interface VideoDrawerProps {
  frameRate: number;
  videoSrc: string;
  onFrameUpdate: (frame: number) => void;
  onResolutionChange: (width: number, height: number) => void;
};

class VideoDrawer {

  private _currentFrame: number;

  private _frameRate: number;

  private _onFrameUpdate: (frame: number) => void;

  private _onResolutionChange: (width: number, height: number) => void;

  private _rafToken: number;

  private _videoEl: HTMLVideoElement;

  constructor(props: VideoDrawerProps) {    
    // * THIS PROBABLY IS NOT THE BEST WAY TO DO IT *
    if(document.getElementById('backVideo') !== null) {      
      document.body.removeChild(document.getElementById('backVideo') as Node);
    }

    const {frameRate, onFrameUpdate, onResolutionChange, videoSrc } = props;
    // * THIS PROBABLY IS NOT THE BEST WAY TO DO IT *
    this._videoEl = document.createElement('video');
    this.videoSrc = videoSrc;
    this._videoEl.id = 'backVideo';

    this._currentFrame = 0;
   
    this._frameRate = frameRate;
    this._onResolutionChange = onResolutionChange;
    this._onFrameUpdate = onFrameUpdate;
    this._rafToken = 0;
    
    this._videoEl.addEventListener('play', this.handleOnPlay);
    this._videoEl.addEventListener('pause', this.handleOnPause);
    this._videoEl.addEventListener('seeked', this.handleOnSeeked);
    this._videoEl.addEventListener('loadeddata', this.handleOnLoadedData);

    // Only for debugging
    this._videoEl.controls = true;
    this._videoEl.style.position = 'fixed';
    this._videoEl.style.top = '0';
    this._videoEl.style.left = '0';
    this._videoEl.style.width = '1px';
    this._videoEl.style.borderRadius = '0';
    this._videoEl.style.boxShadow = 'none';

    
    // * THIS PROBABLY IS NOT THE BEST WAY TO DO IT *
    document.body.appendChild(this._videoEl);

    this.draw = this.draw.bind(this);
    this.handleOnPlay =  this.handleOnPlay.bind(this);
    this.handleOnPause = this.handleOnPause.bind(this);
    this.handleOnSeeked = this.handleOnSeeked.bind(this);
    this.handleOnLoadedData = this.handleOnLoadedData.bind(this);
    this.frameUpdateCheck = this.frameUpdateCheck.bind(this);
    this.requestFrameUpdateCheck = this.requestFrameUpdateCheck.bind(this);
  };



  public get frameRate() {
    return this._frameRate;
  };
  public set frameRate(value: number) {
    this._frameRate = value;
  };
  public get videoSrc() {
    return this._videoEl.src;
  };
  public set videoSrc(value: string) {
    this._videoEl.src = value;
  };
  public get currentTime() {
    return this._videoEl.currentTime;
  };
  public set currentTime(value: number) {
    this._videoEl.currentTime = value;
  };

  public video = (value: string) =>{
    this._videoEl.src = value;
  }

  public draw = (ctx: CanvasRenderingContext2D) => {
    const { videoWidth, videoHeight } = this._videoEl; 
    ctx.drawImage(this._videoEl, 0, 0, videoWidth, videoHeight);
  };

  public handleOnPlay = () => {
    this.frameUpdateCheck();
  };

  public handleOnPause = () => {
    this.frameUpdateCheck();
  };

  private handleOnSeeked = () => {
    this.frameUpdateCheck();
  };

  private handleOnLoadedData = () => {
    const video = this._videoEl;

    const height = video.videoHeight;
    const width = video.videoWidth;

    if (height === 0 && width === 0) {
      window.setTimeout(() => this.handleOnLoadedData(), 100);
    } else {
      this._onResolutionChange(width, height);
    }
  }

  private frameUpdateCheck = () => {
    const time = this.currentTime;
    const frame = Math.floor(time * this.frameRate);

    if (this._currentFrame !== frame) {
      this._currentFrame = frame;

      this._onFrameUpdate(this._currentFrame);
    };

    // Schedule next frame check if still playing
    this._rafToken = 0;
    if (!this._videoEl.paused) {
      this.requestFrameUpdateCheck();
    }
  };

  private requestFrameUpdateCheck = () => {
    // Prevent concurrent requests
    if (this._rafToken === 0) {
      this._rafToken = requestAnimationFrame(this.frameUpdateCheck);
    }
  };
};

export { VideoDrawer };