import { Point, TrackerType } from '@videosmart/player-template';
import { Tracker } from '../models/Tracker';
import { TrackerStyle } from './PreviewCanvas';

export interface OverlayHoveredProps {
  mousePosition: Point;
  tracker: Tracker;
  videoHeight: number;
  videoWidth: number;
};

export interface OverlayDrawProps extends OverlayHoveredProps {
  style: TrackerStyle;
};

class OverlayDrawer {

  public draw(ctx: CanvasRenderingContext2D, props: OverlayDrawProps) {
    const { mousePosition, style, tracker, videoWidth, videoHeight } = props;
    const points = this.calcCornerPoints(tracker, videoWidth, videoHeight);
    ctx.save();

    this.drawOverlay(ctx, style, points, mousePosition);
    this.drawOverlayCorner(ctx, style, points);
    this.drawOverlayCornerShape(ctx, style, points, mousePosition);
    this.drawOverlayText(ctx, style, points, tracker.name);

    ctx.restore();
  };

  public isHovered = (ctx: CanvasRenderingContext2D, props: OverlayHoveredProps) => {
    const { mousePosition, tracker, videoWidth, videoHeight } = props;

    const points = this.calcCornerPoints(tracker, videoWidth, videoHeight);
    const cornerPoints = this.calcCornerShapesPoints(points);

    const shape = this.createPath(points);
    const cornerShapes = this.createCornerPath(cornerPoints)

    return {
      isHovered: this.isPointInPath(ctx, shape, mousePosition),
      cornerId: this.isPointOnCorner(ctx, cornerShapes, mousePosition)
    };
  };

  private calcCenterPoint = (points: Point[]) => {
    const first = points[0];
    const last = points[points.length - 1];

    if (first.x !== last.x || first.y !== last.y) {
      points.push(first);
    }

    let twicearea = 0;
    let x = 0;
    let y = 0;

    for (let i = 0, j = points.length - 1; i < points.length; j = i++) {
      const p1 = points[i];
      const p2 = points[j];
      const f = p1.x * p2.y - p2.x * p1.y;

      twicearea += f;
      x += (p1.x + p2.x) * f;
      y += (p1.y + p2.y) * f;
    }

    twicearea *= 3;

    return {
      x: x / twicearea,
      y: y / twicearea
    };
  };

  private calcCornerPoints = (tracker: Tracker, videoWidth: number, videoHeight: number): Point[] => {
    const { points, type } = tracker;

    switch (type) {
      case TrackerType.ScaleAndTranslate: {
        const x1 = points[0].x * videoWidth;
        const y1 = points[0].y * videoHeight;
        const x2 = points[2].x * videoWidth;
        const y2 = points[2].y * videoHeight;

        const newPoints: Point[] = [
          { x: x1, y: y1 },
          { x: x2, y: y1 },
          { x: x2, y: y2 },
          { x: x1, y: y2 }
        ];

        return newPoints;
      }

      case TrackerType.Perspective: {
        const newPoints = points.map((point) => ({
          x: point.x * videoWidth,
          y: point.y * videoHeight
        }));

        return newPoints;
      }

    };
  };

  private calcCornerShapesPoints = (points: Point[]) => {
    const cornerSize = 6;
    return points.map((point) => {
      return [
        {
          x: point.x - cornerSize,
          y: point.y - cornerSize
        },
        {
          x: point.x + cornerSize,
          y: point.y - cornerSize
        },
        {
          x: point.x + cornerSize,
          y: point.y + cornerSize
        },
        {
          x: point.x - cornerSize,
          y: point.y + cornerSize
        },
      ];
    })
  };

  private createPath = (points: Point[]) => {
    const path = new Path2D();
    path.moveTo(points[3].x, points[3].y);

    points.forEach((point) => {
      path.lineTo(point.x, point.y);
    });

    path.closePath();

    return path;
  };

  private createCornerPath = (corners: Point[][]) => {
    return corners.map((points) => this.createPath(points));
  };

  // the poligonal overlay shape
  private drawOverlay = (ctx: CanvasRenderingContext2D, style: TrackerStyle, points: Point[], mousePosition: Point) => {
    const path = this.createPath(points);

    const isHovered = this.isPointInPath(ctx, path, mousePosition);

    ctx.strokeStyle = style.color;
    ctx.fillStyle = style.color;

    ctx.save();
    ctx.globalAlpha = isHovered ? 0.25 : 0.15;
    ctx.fill(path);
    ctx.restore();

    ctx.save();
    ctx.lineWidth = 0.25;
    ctx.shadowColor = style.shadowColor;
    ctx.shadowBlur = 4;
    ctx.shadowOffsetX = 2;
    ctx.shadowOffsetY = 2;
    ctx.stroke(path);
    ctx.restore();
  };

  // the X of each corner
  private drawOverlayCorner = (ctx: CanvasRenderingContext2D, style: TrackerStyle, points: Point[]) => {
    const crossSize = 1;

    ctx.beginPath();
    ctx.save();
    ctx.lineWidth = 0.35;
    ctx.globalAlpha = 0.9;
    ctx.shadowColor = style.shadowColor;
    ctx.shadowBlur = 3;
    ctx.shadowOffsetX = 2;
    ctx.shadowOffsetY = 2;
    ctx.strokeStyle = '#ffffff';

    points.forEach((point) => {
      const { x, y } = point;

      ctx.moveTo(x - crossSize, y - crossSize);
      ctx.lineTo(x + crossSize, y + crossSize);
      ctx.moveTo(x + crossSize, y - crossSize);
      ctx.lineTo(x - crossSize, y + crossSize);
    });

    ctx.stroke();
    ctx.closePath();
    ctx.restore();
  };

  // the square around the X of each corner of the overlay
  private drawOverlayCornerShape = (ctx: CanvasRenderingContext2D, style: TrackerStyle, points: Point[], mousePosition: Point) => {
    const cornerPoints = this.calcCornerShapesPoints(points);

    const shapes = this.createCornerPath(cornerPoints);
    
    shapes.forEach((shape) => {
      const isHovered = this.isPointInPath(ctx, shape, mousePosition);

      if (isHovered) {
        ctx.save();
        ctx.lineWidth = 0.25;
        ctx.strokeStyle = '#ffffff';
        ctx.shadowColor = style.shadowColor;
        ctx.shadowBlur = 4;
        ctx.shadowOffsetX = 2;
        ctx.shadowOffsetY = 2;
        ctx.fillStyle = '#ffffff33';
        ctx.fill(shape);
        ctx.stroke(shape);
        ctx.restore();
      }
    });
  };

  // the text inside the overlay
  private drawOverlayText = (ctx: CanvasRenderingContext2D, style: TrackerStyle, points: Point[], text: string) => {
    const centerPoint = this.calcCenterPoint(points);

    ctx.save();
    ctx.globalAlpha = 0.9;
    ctx.fillStyle = style.color || '#F4CB48';
    ctx.textAlign = 'center';
    ctx.textBaseline = 'middle';
    ctx.font = 'bold 30px Arial';
    ctx.shadowColor = style.shadowColor;
    ctx.shadowBlur = 3;

    ctx.fillText(text, centerPoint.x, centerPoint.y);

    ctx.restore();
  };

  private isPointInPath = (ctx: CanvasRenderingContext2D, path: Path2D, point: Point) => {
    return (
      ctx.isPointInStroke(path, point.x, point.y) ||
      ctx.isPointInPath(path, point.x, point.y)
    );
  };

  private isPointOnCorner = (ctx: CanvasRenderingContext2D, corners: Path2D[], point: Point) => {
    return corners.findIndex((corner) => this.isPointInPath(ctx, corner, point));
  };
};

export { OverlayDrawer };