import { Dispatch } from 'redux';
import { ActionCreatorFactory } from 'typescript-fsa';
import { asyncFactory } from 'typescript-fsa-redux-thunk';
import { IRootState, IStatus } from '../../redux/models';
import {IRequest} from '../models/requests/IRequest';
import configuration from '../../services/configuration';

const local = `${window.location.protocol}//${window.location.hostname}${window.location.port ? `:${window.location.port}` : ''}`;
const apiRoot = configuration.playerApiUrl;
//const accoutRoot = configuration.accountApiUrl;

export type WithStatus<TResponse> = IStatus & TResponse;

const withStatus = (status: IStatus) => <TResponse>(response?: TResponse) => {
  return Object.assign({}, response, status) as WithStatus<TResponse>;
};

export const createApiAction = <TRequest, TResponse>(
  method: 'GET' | 'POST',
  factory: ActionCreatorFactory,
  type: string,
  endpoint: string,
  authorize: boolean,
  callback?: (
    response: WithStatus<TResponse>,
    dispatch: Dispatch,
    getState: () => IRootState
  ) => void
) => {
  const createAsync = asyncFactory<IRootState>(factory);
  return createAsync<IRequest<TRequest>, WithStatus<TResponse>, IStatus>(
    type,
    async (request, dispatch, getState) => {
      const headers: any = {};
      if(type === "GET_TEMPLATE_VERSIONS"){
        endpoint =  `${apiRoot}/templates/{templateId}/versions`;
      }
      if(type === "GET_TEMPLATE_VERSION"){
        endpoint =  `${apiRoot}/templates/{templateId}/versions/{versionNumber}`;
      }
      if(type === "POST_TEMPLATE_VERSION"){
        endpoint =  `${apiRoot}/templates/{templateId}/versions`;
      }

      // tslint:disable:no-string-literal
      if (method === 'POST') {
        headers['Content-Type'] = 'application/json';
      }
      if (authorize) {
        const state = getState();
        if (state.oidc.user) {
          const accessToken = state.oidc.user.access_token;
          headers['Authorization'] = `Bearer ${accessToken}`;
          headers['Access-Control-Request-Method'] = 'GET, POST';
          headers['Origin'] = local;
        }
      }
      // tslint:enable:no-string-literal
      let body;
      let test;
      if (request!== null && request !== undefined){
        if(request.params !== null && request.params !== undefined) {
          test = request.params;
          for(var key in test){
            endpoint = endpoint.replace(`{${key}}`, test[key]);
          }
        }
        if (request.body !== null) {
          body = request.body;
        }
      }
      const response = await fetch(endpoint, {
        body: method === 'POST' && body ? JSON.stringify(body) : undefined,
        credentials: 'same-origin',
        headers,
        method
      });

      const status: IStatus = {
        ok: response.ok,
        status: response.status
      };

      //validate response status
      switch (response.status) {
        case 401:
        case 403:
          return Promise.reject(status);
      }
      if(!response.ok){
        return Promise.reject(status);
      }
      const responseBody: TResponse = await response.json();
      const result = withStatus(status)(responseBody);

      if (callback) {
        callback(result, dispatch, getState);
      }

      return result;
    }
  );
};

export const createApiAction2 = <TRequest, TResponse>(
  method: 'GET' | 'POST',
  factory: ActionCreatorFactory,
  type: string,
  endpoint: string,
  authorize: boolean,
  callback?: (
    response: TResponse,
    dispatch: Dispatch,
    getState: () => IRootState
  ) => void  
) => {
  const createAsync = asyncFactory<IRootState>(factory);
  return createAsync<IRequest<TRequest>, TResponse, IStatus>(
    type,
    async (request, dispatch, getState) => {
      const headers: any = {};

      // tslint:disable:no-string-literal
      if (method === 'POST') {
        headers['Content-Type'] = 'application/json';
      }
      if (authorize) {
        const state = getState();
        if (state.oidc.user) {
          const accessToken = state.oidc.user.access_token;
          headers['Authorization'] = `Bearer ${accessToken}`;
          headers['Access-Control-Request-Method'] = 'GET, POST';
          headers['Origin'] = local;
        }
      }
      // tslint:enable:no-string-literal
      let body;
      if (request!== null && request !== undefined){
        if(request.params !== null && request.params !== undefined) {
          for(var key in request.params){
            endpoint = endpoint.replace(`{${key}}`, request.params[key]);
          }
        }
        if(request.body !== null){
          body = request.body; 
        }
      }

      const response = await fetch(endpoint, {
        body: method === 'POST' && body ? JSON.stringify(body) : undefined,
        credentials: 'same-origin',
        headers,
        method
      });

    

      // if (authorize && response.status === 401) {
      //   return Promise.reject(status);
      // }

      const responseBody: TResponse = await response.json();
    

      if (callback) {
        callback(responseBody, dispatch, getState);
      }

      return responseBody;
    }
  );
};

export const createDownloadAction = (
  factory: ActionCreatorFactory,
  type: string,
  endpoint: string,
  authorize: boolean = false
) => {
  const createAsync = asyncFactory<IRootState>(factory);
  return createAsync<{}, Blob, IStatus>(
    type,
    async (params, dispatch, getState) => {
      const headers: any = {};

      // tslint:disable:no-string-literal
      if (authorize) {
        const state = getState();
        if (state.oidc.user) {
          const accessToken = state.oidc.user.access_token;
          headers['Authorization'] = `Bearer ${accessToken}`;
        }
      }
      // tslint:enable:no-string-literal

      const response = await fetch(endpoint, {
        credentials: 'include',
        headers,
        method: 'GET'
      });

      const status: IStatus = {
        ok: response.ok,
        status: response.status
      };

      if (authorize && response.status === 401) {
        return Promise.reject(status);
      }

      return await response.blob();
    }
  );
};
