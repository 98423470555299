import { User } from 'oidc-client';
import React from 'react';
import { RouteComponentProps } from 'react-router';
import { CallbackComponent } from 'redux-oidc';

import { LoadingScreen } from '../../components';
import { userManager } from '../../services';
// import { userFound } from 'redux-oidc';
import { Dispatch, bindActionCreators } from "redux";
import { connect } from 'react-redux';
import {OidcActions} from '../../redux/actions'


export interface ISignInCallbackProps extends RouteComponentProps {
  updateUserState: typeof OidcActions.OidcActions.updateUserState
};

// ********************************************************************************
const mapDispatchToProps = (dispatch:Dispatch): Pick<ISignInCallbackProps, "updateUserState"> => ({
  updateUserState: bindActionCreators(OidcActions.OidcActions.updateUserState,dispatch)
});
// ********************************************************************************

class SignInCallback extends React.Component<ISignInCallbackProps> {
  constructor(props:ISignInCallbackProps) {
    super(props);

    this.successCallback = this.successCallback.bind(this);
    this.errorCallback = this.errorCallback.bind(this);
  }

  successCallback = (user: User) => {
    const redirectUrl = (user.state && user.state.redirectUrl) || '/';
    this.props.updateUserState({user, isLoadingUser:false});
    this.props.history.push(redirectUrl);
  };

  errorCallback = (error: Error) => {
    console.error(`There was an error handling the token callback: ${error.message}`);
    this.props.history.push('/');
  }

  render() {
    return (
      <CallbackComponent
        userManager={userManager}
        successCallback={this.successCallback}
        errorCallback={this.errorCallback}
      >
        <LoadingScreen>Logged in...</LoadingScreen>
      </CallbackComponent>
    );
  }
};

export default connect(null, mapDispatchToProps)(SignInCallback);