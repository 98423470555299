import React from 'react';
import { Close } from '@material-ui/icons';
import { connect } from 'react-redux';
import { Dispatch, bindActionCreators } from 'redux';
import { IRootState, OverlaysActions, TemplateActions, OverlayData, EditorSettingsActions } from '../../../redux';
import SimpleBar from 'simplebar-react';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Button from '@material-ui/core/Button';
import { Tooltip, Checkbox, FormControlLabel } from '@material-ui/core';
import {TrackerType,ActionType, TagSubActionType} from '@videosmart/player-template';
import './styles/OverlayEditor.scss';
import {overlayActionTypeConverter} from './converters/OverlayActionTypeConverter'

export interface OverlayEditorData {
    overlayIndex:number,   // the index of the overlay to be edited at the moment
    template:any,
    overlaysInScene:Array<OverlayData>,
    currentScene:number,
    addingNewOv:boolean,
    showAddSceneModal:boolean,
    updateTemplate: typeof TemplateActions.actionCreators.updateTemplate,
    addOverlayToJson: typeof TemplateActions.asyncActionCreators.addOverlayToJson,
    updateOverlayJson: typeof TemplateActions.asyncActionCreators.updateOverlay,
    removeOverlayFromJson:typeof TemplateActions.asyncActionCreators.removeOverlayFromJson,
    addScenetoJson: typeof TemplateActions.asyncActionCreators.addSceneToJson,
    updateOverlay: typeof OverlaysActions.OverlaysActions.updateOverlay,
    deleteOverlay: typeof OverlaysActions.OverlaysActions.deleteOverlay,
    addOverlayToTimeline: typeof OverlaysActions.OverlaysActions.addOverlay,
    overlayToEdit: typeof EditorSettingsActions.EditorSettingsActions.overlayToEdit,
    overlayToDelete: typeof EditorSettingsActions.EditorSettingsActions.overlayToDelete,
    overlayToAddNew: typeof EditorSettingsActions.EditorSettingsActions.overlayToAddNew,
    closeSettings: typeof EditorSettingsActions.EditorSettingsActions.closeSettings,
    refreshOverlays: typeof EditorSettingsActions.EditorSettingsActions.refreshOverlays,
    changeScene: typeof EditorSettingsActions.EditorSettingsActions.changeScene,
    editingScene: typeof EditorSettingsActions.EditorSettingsActions.editingScene,
    showAddScene: typeof EditorSettingsActions.EditorSettingsActions.showAddScene
}

const mapStateToProps = (state: IRootState) => {
    return {
        overlayIndex: state.editorSettings.index,
        template: state.template.present.json,
        overlaysInScene: state.overlaysInScene.current,
        addingNewOv: state.editorSettings.addNewfunction,
        currentScene: state.template.present.currentScene,
        showAddSceneModal:state.editorSettings.showAddSceneModal
    }
}

const mapDispatchToProps = (dispatch:Dispatch): Pick<OverlayEditorData, 'editingScene' | 'changeScene' | 'showAddScene' | 'addScenetoJson' | 'refreshOverlays' | 'overlayToAddNew' | 'addOverlayToTimeline' | 'addOverlayToJson' | 'closeSettings' | 'updateTemplate' | 'updateOverlay' | 'updateOverlayJson' | 'overlayToEdit'| 'overlayToDelete' | 'removeOverlayFromJson' | 'deleteOverlay' >  => ({
    updateTemplate: bindActionCreators(TemplateActions.actionCreators.updateTemplate, dispatch),
    updateOverlayJson: bindActionCreators(TemplateActions.asyncActionCreators.updateOverlay, dispatch),
    removeOverlayFromJson: bindActionCreators(TemplateActions.asyncActionCreators.removeOverlayFromJson, dispatch),
    addOverlayToJson: bindActionCreators(TemplateActions.asyncActionCreators.addOverlayToJson, dispatch),
    addScenetoJson: bindActionCreators(TemplateActions.asyncActionCreators.addSceneToJson, dispatch),
    updateOverlay: bindActionCreators(OverlaysActions.OverlaysActions.updateOverlay, dispatch),
    deleteOverlay: bindActionCreators(OverlaysActions.OverlaysActions.deleteOverlay, dispatch),    
    addOverlayToTimeline: bindActionCreators(OverlaysActions.OverlaysActions.addOverlay, dispatch),
    closeSettings: bindActionCreators(EditorSettingsActions.EditorSettingsActions.closeSettings, dispatch),
    overlayToEdit: bindActionCreators(EditorSettingsActions.EditorSettingsActions.overlayToEdit, dispatch),
    overlayToDelete: bindActionCreators(EditorSettingsActions.EditorSettingsActions.overlayToDelete, dispatch),
    overlayToAddNew: bindActionCreators(EditorSettingsActions.EditorSettingsActions.overlayToAddNew, dispatch),
    refreshOverlays: bindActionCreators(EditorSettingsActions.EditorSettingsActions.refreshOverlays, dispatch),
    showAddScene: bindActionCreators(EditorSettingsActions.EditorSettingsActions.showAddScene, dispatch),
    changeScene: bindActionCreators(EditorSettingsActions.EditorSettingsActions.changeScene, dispatch),
    editingScene: bindActionCreators(EditorSettingsActions.EditorSettingsActions.editingScene, dispatch)
});

const TrackerValueArray = Object.values(TrackerType);
const AutoplayArray = ['true', 'false'];

const NewAction:any = [];


    const ActionValueArray = Object.values(ActionType);
    const TagSubActionValueArray = Object.values(TagSubActionType);
    

    ActionValueArray.forEach((action:any) => {
        NewAction.push(action)
    });
    TagSubActionValueArray.forEach((action:any) => {
        NewAction.push(action)
    });

class OverlayEditor extends React.Component<OverlayEditorData> {
    state:any;
    keyGenerator:number;

    constructor(props:any) {
        super(props);

        this.state = {  
            vidDur: Number(document.getElementsByTagName('video')[0].duration.toFixed(3)), 
            name: '' ,
            id: '',
            type: '',
            action:  '' ,
            startpoint: '' ,
            endpoint: '',
            trackerType: '',
            x1: '' ,
            x2:'',
            x3: '' ,
            x4: '',
            y1: '',
            y2: '' ,
            y3: '',
            y4:'',
            scene:'',
            autoplay:'',
            functionT:'',
            callOnParent: '',
            seektime:'',
            subActionValue:'',
            target: false,
            tag: '',
            scenesList: [],
            sceneSelected: '',
            sceneType:'',
            MissingName:false,
            MissingType:false,
            MissingTag: false,
            MissingSubActionValue:false,
            NotValidName:false,
            ovNamesList: [],
            changing:false,
            DetectChange:false
        };
        
        this.keyGenerator = -1;

        this.applyChanges = this.applyChanges.bind(this);
        this.handleDeleteOverlay = this.handleDeleteOverlay.bind(this);
        this.handleAddingOverlay = this.handleAddingOverlay.bind(this);
        this.generateScenesArray = this.generateScenesArray.bind(this);
        this.showHideInputNewScene = this.showHideInputNewScene.bind(this);
        this.validateName =  this.validateName.bind(this);
    }

    componentDidMount(){
        this.props.template.scenes.forEach((infoscene:any)=>{
            if( infoscene.overlays !== undefined){
                infoscene.overlays.forEach((infooverlay:any) => {
                   this.state.ovNamesList.push(infooverlay.content.embeddedId)
  
                });
            }
        })
        if(this.props.overlayIndex > -1){  
                this.setState(() => {
                    var overlay = this.props.template.scenes[this.props.currentScene].overlays[this.props.overlayIndex];
                    return {
                        ...this.state, 
                        name:  overlay.content.embeddedId,
                        id: this.props.overlayIndex,
                        type: overlay.tracker.type,
                        action: overlay.actions ? overlayActionTypeConverter.getAction(overlay.actions[0]) : '',
                        scene: overlay.actions ? (overlay.actions[0].type === "loadScene" ?overlay.actions[0].sceneId : '' ): '',
                        autoplay: overlay.actions ? (overlay.actions[0].type === "loadScene" ?overlay.actions[0].autoPlay : '' ): '',
                        functionT: overlay.actions ? (overlay.actions[0].type === "functionCall" ?overlay.actions[0].function : '' ): '',
                        callOnParent: overlay.actions ? (overlay.actions[0].type === "functionCall" ?overlay.actions[0].callOnParent : '' ): '',
                        seektime: overlay.actions ? (overlay.actions[0].type === "seek" ?overlay.actions[0].time : '' ): '',
                        tag: overlay.actions ? (overlay.actions[0].type === "tag" ?overlay.actions[0].tag : '' ): '',                  
                        startpoint:overlay.start.time,
                        endpoint:overlay.end.time,
                        trackerType: overlay.tracker.type,
                        x1:overlay.tracker.trackData.frames[0].points[0].x,
                        x2:overlay.tracker.trackData.frames[0].points[1].x,
                        x3:overlay.tracker.trackData.frames[0].points[2].x,
                        x4:overlay.tracker.trackData.frames[0].points[3].x,
                        y1:overlay.tracker.trackData.frames[0].points[0].y,
                        y2:overlay.tracker.trackData.frames[0].points[1].y,
                        y3:overlay.tracker.trackData.frames[0].points[2].y,
                        y4:overlay.tracker.trackData.frames[0].points[3].y,
                        subActionValue: overlay.actions ? overlayActionTypeConverter.getActionValue(overlay.actions[0]): '',
                        target: overlay.actions ? overlayActionTypeConverter.isSubActionTarget(overlay.actions[0]): false        
                    }
                });
       }
       else {
        this.setState(() => {
            return {
                x1:0.2,
                x2:0.4,
                x3:0.4,
                x4:0.2,
                y1:0.2,
                y2:0.2,
                y3:0.4,
                y4:0.4        
            }
        });
       }

       this.generateScenesArray();
    }

    componentDidUpdate(prevProps: any) {
        if(!this.props.showAddSceneModal) {
            if(this.props.overlayIndex > -1) {
                if(prevProps !== this.props) {
                        this.setState(() => {
                            var overlay = this.props.template.scenes[this.props.currentScene].overlays[this.props.overlayIndex];
                            return {
                                ...this.state, 
                                name: overlay.content.embeddedId,
                                id: this.props.overlayIndex,
                                type:overlay.tracker.type,
                                action:overlay.actions ? overlayActionTypeConverter.getAction(overlay.actions[0]) : '',
                                scene: overlay.actions ? (overlay.actions[0].type === "loadScene" ?overlay.actions[0].sceneId : '' ): '',
                                autoplay: overlay.actions ? (overlay.actions[0].type === "loadScene" ?overlay.actions[0].autoPlay : '' ): '',
                                functionT: overlay.actions ? (overlay.actions[0].type === "functionCall" ?overlay.actions[0].function : '' ): '',
                                callOnParent: overlay.actions ? (overlay.actions[0].type === "functionCall" ?overlay.actions[0].callOnParent : '' ): '',
                                seektime: overlay.actions ? (overlay.actions[0].type === "seek" ?overlay.actions[0].time : '' ): '',
                                tag: overlay.actions ? (overlay.actions[0].type === "tag" ?overlay.actions[0].tag : '' ): '',                     
                                startpoint:overlay.start.time,
                                endpoint:overlay.end.time,
                                trackerType: overlay.tracker.type,
                                x1:overlay.tracker.trackData.frames[0].points[0].x,
                                x2:overlay.tracker.trackData.frames[0].points[1].x,
                                x3:overlay.tracker.trackData.frames[0].points[2].x,
                                x4:overlay.tracker.trackData.frames[0].points[3].x,
                                y1:overlay.tracker.trackData.frames[0].points[0].y,
                                y2:overlay.tracker.trackData.frames[0].points[1].y,
                                y3:overlay.tracker.trackData.frames[0].points[2].y,
                                y4:overlay.tracker.trackData.frames[0].points[3].y,
                                subActionValue: overlay.actions ? overlayActionTypeConverter.getActionValue(overlay.actions[0]): '',
                                target: overlay.actions ? overlayActionTypeConverter.isSubActionTarget(overlay.actions[0]): false
                            }
                        });
                    
                }
            }else{
                if(prevProps !== this.props) {
                    this.setState(() => {
                        return {
                            name: '' ,
                            id: '',
                            type: '',
                            action:  '' ,
                            trackerType: '',
                            x1:0.2,
                            x2:0.4,
                            x3:0.4,
                            x4:0.2,
                            y1:0.2,
                            y2:0.2,
                            y3:0.4,
                            y4:0.4 ,     
                            scene:'',
                            autoplay:'',
                            functionT:'',
                            callOnParent: '',
                            seektime:'',
                            tag: '',
                            scenesList: [],
                            sceneSelected: '',
                            sceneType:''
                        }

                    });
                }
            }

            if(prevProps !== this.props) {
                this.generateScenesArray();
            }
        }
    }
    validateName(){
        if(this.state.changing){
            this.state.ovNamesList.forEach((info:any)=>{
                if(this.state.name === info){
                    this.setState(() => ({NotValidName:true}));                           
                }
            });
        }
    }
    
    applyChanges() {
        
        // dispatch actions updateTemplate and updateTracker of the TemplateReducer
        // Calculate With tu do the updates necessary to the timeline
        const vidDuration = Number(document.getElementsByTagName('video')[0].duration.toFixed(3));
        let finalendpoint: number;
        let finalstartpoint: number;
        let startTime:number,
            endTime:number;
        if ((this.state.startpoint !== undefined) && (this.state.startpoint !== 0)) {
            // here we have calculated the left position of the overlay already in %
            startTime = Number(((this.state.startpoint * 100) / vidDuration).toFixed(3));
            // stores this value in CSS format, which will be the new 'leftPosition' property of the state          
        }
        else {
            startTime = 0;
            // if start.time is undefined or zero, leftOv is zero too         
        }
        //if((this.state.endpoint !== undefined) && (this.state.endpoint !== this.props.overlaysInScene[this.props.overlayIndex].videoDuration)) {
        if((this.state.endpoint !== undefined) && (this.state.endpoint !== vidDuration)) {
            // here we have calculated the end of the overlay already in %            
            endTime = Number(((this.state.endpoint * 100) / vidDuration).toFixed(3));
        }
        else {
            // if end.time is undefined or equal to the length of the video, we assign to endTime the value of 100%
            endTime = 100;
        }

        //validade endpoint

        if(this.state.endpoint > vidDuration){
            finalendpoint = (vidDuration + 0.002);
        }else if(this.state.endpoint < this.state.startpoint){
            finalendpoint = (vidDuration + 0.002);
        }else{
            finalendpoint = this.state.endpoint;
        }
        if(this.state.startpoint > vidDuration){
            finalstartpoint = vidDuration;
            finalendpoint = (vidDuration + 0.002);
        }else if(this.state.startpoint > finalendpoint)
        {
             finalstartpoint = 0;
        }else{
            finalstartpoint = this.state.startpoint;
        }

        // here we calculate the width of the overlay in %
        const overlayW = endTime - startTime;
        const ow = overlayW.toFixed(2) + '%';

        //do UI validations
        let validationErrors = this.doValidations();

        if(!this.state.NotValidName && !validationErrors){

            if(this.state.DetectChange){
                this.props.updateOverlay({
                    index: this.props.overlayIndex,
                    width: ow,
                    startPoint:finalstartpoint,
                    endPoint: finalendpoint
                });

                this.props.updateOverlayJson({
                    currentScene: this.props.currentScene,
                    name: this.state.name,
                    index: this.props.overlayIndex,
                    startPoint:  finalstartpoint,
                    endPoint:  finalendpoint,
                    type: this.state.type,
                    action: this.state.action,
                    trackerType: this.state.trackerType,
                    x1: this.state.x1 <= 1 && this.state.x1 >= 0 ? this.state.x1 : 1,
                    x2: this.state.x2 <= 1 && this.state.x2 >= 0 ? this.state.x2 : 1,
                    x3: this.state.x3 <= 1 && this.state.x3 >= 0 ? this.state.x3 : 0 ,
                    x4: this.state.x4 <= 1 && this.state.x4 >= 0 ? this.state.x4 : 0,
                    y1: this.state.y1 <= 1 && this.state.y1 >= 0 ? this.state.y1 : 0,
                    y2: this.state.y2 <= 1 && this.state.y2 >= 0 ? this.state.y2 : 0,
                    y3: this.state.y3 <= 1 && this.state.y3 >= 0 ? this.state.y3 : 1,
                    y4: this.state.y4 <= 1 && this.state.y4 >= 0 ? this.state.y4 : 1,
                    scene: this.state.scene,
                    autoplay: this.state.autoplay,
                    tag: this.state.tag,
                    seektime: this.state.seektime,
                    functionT: this.state.functionT,
                    callOnParent: this.state.callOnParent,
                    subActionValue: this.state.subActionValue,
                    target: this.state.target
                });
                this.props.refreshOverlays({});
            }
            this.props.closeSettings({});
            
        }
    }

    handleDeleteOverlay() {     
        this.props.overlayToDelete({});
       
        if (this.props.template.scenes[this.props.currentScene].overlays.length > 0) {
            this.props.removeOverlayFromJson({ 
              currentScene: this.props.currentScene,
              indexInArray:this.props.overlayIndex
            });
            this.props.deleteOverlay(this.props.overlayIndex);
        }
        this.props.refreshOverlays({});
    }

    handleAddingOverlay() {
        //do UI validations
        let validationErrors = this.doValidations();

        if(!this.state.NotValidName  && !validationErrors){
            const vidDuration = Number(document.getElementsByTagName('video')[0].duration.toFixed(3));
            let finalendpoint: number;
            let finalstartpoint: number;
            if(this.state.endpoint > vidDuration){
                finalendpoint = (vidDuration + 0.002);
            }else if(Number(this.state.startpoint) > Number(this.state.endpoint)){
                finalendpoint = (vidDuration + 0.002);
            }else{
                finalendpoint = this.state.endpoint;
            }
            if(Number(this.state.startpoint) > vidDuration){
                finalstartpoint = vidDuration;
                finalendpoint = (vidDuration + 0.002);
            }else if(Number(this.state.startpoint) > finalendpoint)
            {
                 finalstartpoint = 0;
            }else{
                finalstartpoint = this.state.startpoint;
            }

            this.props.addOverlayToJson(
                {
                    sceneNumber: this.props.currentScene,
                    name: this.state.name,
                    startPoint:  finalstartpoint,
                    endPoint:  finalendpoint,
                    type: this.state.type,
                    action: this.state.action,
                    x1: this.state.x1 <= 1 && this.state.x1 >= 0 ? this.state.x1 : 1,
                    x2: this.state.x2 <= 1 && this.state.x2 >= 0 ? this.state.x2 : 1,
                    x3: this.state.x3 <= 1 && this.state.x3 >= 0 ? this.state.x3 : 0 ,
                    x4: this.state.x4 <= 1 && this.state.x4 >= 0 ? this.state.x4 : 0,
                    y1: this.state.y1 <= 1 && this.state.y1 >= 0 ? this.state.y1 : 0,
                    y2: this.state.y2 <= 1 && this.state.y2 >= 0 ? this.state.y2 : 0,
                    y3: this.state.y3 <= 1 && this.state.y3 >= 0 ? this.state.y3 : 1,
                    y4: this.state.y4 <= 1 && this.state.y4 >= 0 ? this.state.y4 : 1,
                    scene: this.state.scene,
                    autoplay: this.state.autoplay,
                    tag: this.state.tag,
                    seektime: this.state.seektime,
                    functionT: this.state.functionT,
                    callOnParent: this.state.callOnParent, 
                    subActionValue: this.state.subActionValue,
                    target: this.state.target
                });        
            
            this.props.addOverlayToTimeline({
                startPoint:  finalstartpoint,
                endPoint:  finalendpoint,
                contentType: this.state.type,
                embeddedId: this.state.name
            });
    
            this.props.refreshOverlays({});
        }

    }

    generateScenesArray() {
        let scenes = this.props.template.scenes;

        let newScenesList = Array<string>();

        if(scenes.length > 0) {
            scenes.forEach(scene => {
                newScenesList.push(scene.id);
            });
        }

        this.setState(() => {
            return {
                scenesList: newScenesList
            }
        });
    }

    showHideInputNewScene() {
        this.props.changeScene({ changeToNewScene: false });
        this.props.editingScene({ editingSceneInfo: false });
        this.props.showAddScene({ showAddSceneModal: !this.props.showAddSceneModal });
    }
    doValidations() : boolean{
        let validationsErrors : boolean = false;
        if(this.state.name === ''){
            this.setState(() => ({ MissingName: true }));
            validationsErrors=true;
        }
        if(this.state.type === ''){
            this.setState(() => ({ MissingType: true }));
            validationsErrors=true;
        }
        if(this.state.tag === '' && 
        ( this.state.action ===  ActionType.Tag || 
            this.state.action=== TagSubActionType.Url || this.state.action=== TagSubActionType.Email || this.state.action=== TagSubActionType.Phone)){
            this.setState(() => ({ MissingTag: true }));
            validationsErrors=true;
        }
        if(this.state.subActionValue === '' && 
        (this.state.action=== TagSubActionType.Url || this.state.action=== TagSubActionType.Email || this.state.action=== TagSubActionType.Phone)){
            this.setState(() => ({ MissingSubActionValue: true }));
            validationsErrors=true;
        }

        return validationsErrors;
    }
    
    render() {
        return(
            <div className="overlaySettingsContainer">
                <SimpleBar style={{ minHeight:'100%', maxHeight:'100%', padding:'16px' }}>    

                    {/* ------------   CLOSE BUTTON   ------------- */}      
                    <Tooltip title="Close Settings" aria-label="close">      
                        <Close
                            className="closeBtn"
                            onClick={() => { this.props.closeSettings({}) } }
                        >                    
                        </Close>
                    </Tooltip>
                    
                    <div style={{height:'1px', clear:'both'}}></div>
                    
                    {/* ---------------    NAME   ------------------ */}
                    <div className="ov-row-wrapper">  
                        <label>Name:</label>
                        <input 
                            name="name" 
                            value={this.state.name} 
                            data-id={this.props.overlayIndex} 
                            onChange= {(e) => {this.setState({name: e.target.value,DetectChange:true,changing:true, MissingName:false, NotValidName:false  })}}
                            onBlur={this.validateName} />
                        <span className="error-container">{this.state.MissingName ? "Name is required" : this.state.NotValidName ? "Name in use, please use another name" : '\u00A0'}</span>
                     </div> 
                    
                    {/* --------   ACTION ( play / pause / seek / loadScene / ... )   -------- */}    
                    <FormControl className="selectControl">
                        <InputLabel id="demo-simple-select2-label">Action:</InputLabel>
                        <Select className="selectInput"
                            data-id="demo-simple-select2"
                            name="actions"
                            value={this.state.action}
                            onChange={ (e) => {this.setState({action: e.target.value,DetectChange:true, subActionValue: '', target:false, tag:'',MissingTag:false,MissingSubActionValue:false})} } 
                            
                        >
                            { NewAction.map((value:any, index:any) => (
                                <MenuItem key={index} value={value}>{overlayActionTypeConverter.convert(value)}</MenuItem>                               
                            )) }
                        </Select>
                    </FormControl>
                                      
                    {/* --------  if ACTION == 'loadScene'   -------- */}    
                    { this.state.action === "loadScene" ? 
                       <> 
                            <br/><br />
                            <div className="loadScene-container">
                                {/* -------- CHECKBOX --------- */}
                                <FormControlLabel 
                                    control={<Checkbox value="checkedC" />}
                                    label="new scene"
                                    labelPlacement="end"
                                    onChange={this.showHideInputNewScene}
                                    className="newSceneCheckbox"
                                />
                                {/* -------- SCENE NAME --------- */}
                                <label>Scenes:</label>                  
                                {/* ----------  Scenes List ---------- */}
                                <FormControl className="selectControl scenes-list">
                                    <Select className="selectInput"
                                        data-id="scenes-list"
                                        name="scenes"
                                        value={this.state.scene}
                                        onChange={ (e) => { this.setState({ scene: e.target.value,DetectChange:true }) } }
                                    >
                                        {this.state.scenesList.map((value:any,index:any) => (
                                            <MenuItem key={Math.floor(Math.random() * 1000 + index)} value={value}>{value}</MenuItem>                               
                                        )) }
                                    </Select>
                                </FormControl>
                                {/* -------- AUTO PLAY --------- */}
                                <FormControl className="selectControl">
                                    <InputLabel id="demo-simple-select2-label">Auto play:</InputLabel>
                                    <Select className="selectInput"
                                        data-id="demo-simple-select2"
                                        name="actions"
                                        value={this.state.autoplay}
                                        onChange={ (e) => {this.setState({DetectChange:true,autoplay: e.target.value})} } 
                                    >
                                    {AutoplayArray.map((value:any, index:any) => (
                                        <MenuItem key={Math.floor(Math.random() * 1000 + index)} value={value}>{value}</MenuItem>                               
                                    )) }
                                    </Select>
                                </FormControl>
                            </div>
                        </>
                    : <></> }

                    {/* --------  if ACTION == 'functionCall'   -------- */}  
                    { this.state.action === "functionCall" ? 
                       <> 
                            <br /><br />
                            <div style={{marginLeft:'20px'}}>                            
                                <FormControl className="selectControl">
                                <InputLabel id="demo-simple-select2-label">callOnParent:</InputLabel>
                                    <Select className="selectInput"
                                        data-id="demo-simple-select2"
                                        name="actions"
                                        value={this.state.callOnParent}
                                        onChange={ (e) => {this.setState({DetectChange:true,callOnParent: e.target.value})} } 
                                    >
                                    {AutoplayArray.map((value:any, index:any) => (
                                        <MenuItem key={Math.floor(Math.random() * 1000 + index)} value={value}>{value}</MenuItem>                               
                                    )) }
                                    </Select>
                                </FormControl>
                                <label>function:</label>
                                <input 
                                    name="functionT" 
                                    value={this.state.functionT} 
                                    data-id={this.props.overlayIndex} 
                                    onChange= {(e) => {this.setState({DetectChange:true,functionT: e.target.value})}} />
                            </div>
                        </>
                    : <></> }
                    
                    {/* --------  if ACTION == 'tag'   -------- */}  
                    { this.state.action === "tag" ? 
                       <> 
                            <br /><br />
                            <div style={{marginLeft:'20px'}}>
                            <div className="ov-row-wrapper">                                                          
                                <label>Tag:</label>
                                <input 
                                    name="tag" 
                                    value={this.state.tag} 
                                    data-id={this.props.overlayIndex} 
                                    onChange= {(e) => {this.setState({DetectChange:true,tag: e.target.value, MissingTag:false})}} />
                                   <span className="error-container">{this.state.MissingTag ? "Tag is required" :'\u00A0'}</span>
                            </div>
                            
                            </div>
                            
                        </>
                    : <></> }

                    {/* --------  if ACTION == 'seek'   -------- */} 
                    { this.state.action === "seek" ? 
                       <> 
                            <br /><br />
                            <div style={{marginLeft:'20px'}}>                                                         
                                <label>Seek time:</label>
                                <input 
                                    type="number"
                                    name="seektime" 
                                    step={'0.001'}
                                    value={this.state.seektime} 
                                    data-id={this.props.overlayIndex} 
                                    onChange= {(e) => {this.setState({DetectChange:true,seektime: e.target.value})}} />
                            </div>
                        </>
                    : <></> }
                    { this.state.action === "url" ? 
                       <> 
                            <br /><br />
                            <div className="ov-row-wrapper">     
                            <div className="loadScene-container">
                                <div style={{marginLeft:'20px'}}>         
                                                                              
                                    <label>Url:</label>
                                    <input 
                                        type="text"
                                        name="subActionValue" 
                                    
                                        value={this.state.subActionValue} 
                                        data-id={this.props.overlayIndex} 
                                        onChange= {(e) => {this.setState({DetectChange:true,subActionValue: e.target.value})}} />
                                        <span className="error-container">{this.state.MissingSubActionValue ? "Url is required" :'\u00A0'}</span>
                                
                                </div>
                                <div style={{marginLeft:'20px'}}>               
                                                                           
                                    <label>Event name:</label>
                                    <input 
                                        name="tag" 
                                        value={this.state.tag} 
                                        data-id={this.props.overlayIndex} 
                                        onChange= {(e) => {this.setState({DetectChange:true,tag: e.target.value})}} />
                                        <span className="error-container">{this.state.MissingTag ? "Event name is required" :'\u00A0'}</span>
                                        
                                    </div>
                                <FormControlLabel 
                                        control={<Checkbox checked={this.state.target}
                                        onChange={(e) => {this.setState({DetectChange:true,target: e.target.checked})}}/>}
                                        label="target new page"
                                        labelPlacement="end"
                                        value={this.state.target}
                                        // onChange={(e) => {this.setState({DetectChange:true, target: this.state.target})}}
                                        className="TargetCheckbox"
                                />
                             </div>   
                             </div> 
                        </>
                    : <></> }
                    { this.state.action === "email" ? 
                       <> 
                            <br /><br />
                            <div className="ov-row-wrapper"> 
                            <div className="loadScene-container">
                                <div style={{marginLeft:'20px'}}>                           
                                                                  
                                    <label>Email:</label>
                                    <input 
                                        type="text"
                                        name="subActionValue" 
                                    
                                        value={this.state.subActionValue} 
                                        data-id={this.props.overlayIndex} 
                                        onChange= {(e) => {this.setState({DetectChange:true,subActionValue: e.target.value})}} />
                                        <span className="error-container">{this.state.MissingSubActionValue ? "Email is required" :'\u00A0'}</span>
                                </div>
                                <div style={{marginLeft:'20px'}}>                                                    
                                    <label>Event name:</label>
                                    <input 
                                        name="tag" 
                                        value={this.state.tag} 
                                        data-id={this.props.overlayIndex} 
                                        onChange= {(e) => {this.setState({DetectChange:true,tag: e.target.value})}} />
                                        <span className="error-container">{this.state.MissingTag ? "Event name is required" :'\u00A0'}</span>
                                    </div>
                                    </div>
                             </div>   
                             
                        </>
                    : <></> }
                    { this.state.action === "phone" ? 
                       <> 
                            <br /><br />
                            <div className="ov-row-wrapper">
                            <div className="loadScene-container">
                                <div style={{marginLeft:'20px'}}>           
                                    <label>Number:</label>
                                    <input 
                                        type="text"
                                        name="subActionValue" 
                                    
                                        value={this.state.subActionValue} 
                                        data-id={this.props.overlayIndex} 
                                        onChange= {(e) => {this.setState({DetectChange:true,subActionValue: e.target.value})}} />
                                        <span className="error-container">{this.state.MissingSubActionValue ? "Number is required" :'\u00A0'}</span>
                                </div>
                                <div style={{marginLeft:'20px'}}>                                               
                                    <label>Event name:</label>
                                    <input 
                                        name="tag" 
                                        value={this.state.tag} 
                                        data-id={this.props.overlayIndex} 
                                        onChange= {(e) => {this.setState({DetectChange:true,tag: e.target.value})}} />
                                        <span className="error-container">{this.state.MissingTag ? "Event name is required" :'\u00A0'}</span>
                                    </div>
                                    </div>
                             </div>   
                             
                        </>
                    : <></> }
                    <br /><br />
                    
                    {/*  ----------------------   START POINT  ----------------  */}
                    <label>Start Point:</label>
                    <input 
                        type="number"
                        name="startpoint"
                        step={'0.001'} 
                        min="0" max={Number(document.getElementsByTagName('video')[0].duration.toFixed(3))}        
                        value={this.state.startpoint} 
                        data-id={this.props.overlayIndex}
                        onChange={ (e) => {this.setState({DetectChange:true,startpoint: e.target.value})} } />

                    <br /><br />

                    {/*  ----------------------   END POINT  ----------------  */}
                    <label>End Point:</label>
                    <input
                        type="number"
                        name="endpoint"
                        min="0" max={Number(document.getElementsByTagName('video')[0].duration.toFixed(3))}
                        step={'0.001'}   
                        value={this.state.endpoint} 
                        data-id={this.props.overlayIndex} 
                        onChange={ (e) => {this.setState({DetectChange:true,endpoint: e.target.value})} } />
                    
                    <br /><br />
                    
                    {/* --------   TYPE ( perspective /  scaleAndTranslate )   -------- */}
                    <div className="ov-row-wrapper">  
                        { this.props.addingNewOv ? 
                            <FormControl className="selectControl">
                                <InputLabel id="demo-simple-select-label">Type:</InputLabel>
                                <Select className="selectInput"
                                    data-id="demo-simple-select"
                                    name="type"
                                    value={this.state.type}
                                    onChange={(e) => {this.setState({DetectChange:true,type: e.target.value, MissingType:false})} } 
                                >
                                { TrackerValueArray.map((value:any, index:any) => (
                                    <MenuItem key={Math.floor(Math.random() * 1000 + index)} value={value}>{value}</MenuItem>
                                )) }
                                </Select>                        
                            </FormControl>
                        :
                            <Tooltip 
                                title="when changing the overlay type, apply the changes before editing the x,y coordinates" 
                                aria-label="change type"
                                placement="left-start"
                                classes={{ tooltip: "tooltip-ov-type"}}
                            >
                                <FormControl className="selectControl">
                                    <InputLabel id="demo-simple-select-label">Type:</InputLabel>
                                    <Select className="selectInput"
                                        data-id="demo-simple-select"
                                        name="type"
                                        value={this.state.type}
                                        onChange={(e) => {this.setState({DetectChange:true,type: e.target.value, MissingType:false})} } 
                                    >
                                    { TrackerValueArray.map((value:any, index:any) => (
                                        <MenuItem key={Math.floor(Math.random() * 1000 + index)} value={value}>{value}</MenuItem>
                                    )) }
                                    </Select>                        
                                </FormControl>
                            </Tooltip>
                        }
                        <span className="error-container" style={{ top:'-2px' }}>{this.state.MissingType ? "Type is required" : "\u00A0"}</span>
                    </div>

                    {/*  ----------------------   TRACKER POINTS  ----------------  */}
                    <label>Tracker:</label><br />
                    <div className="pointBlock">
                        <label>x</label>
                        <input className="trackerPoint"
                            name="x1"
                            type="number"
                            value={this.state.x1} 
                            min="0" max="1"
                            step="0.01"
                            data-id={this.props.overlayIndex} 
                            onChange={ (e) => {this.setState({DetectChange:true,x1: e.target.value})} } />
                        <label>y</label>
                        <input className="trackerPoint"
                            name="y1"
                            type="number"
                            min="0" max="1"
                            step="0.01"
                            value={this.state.y1}
                            data-id={this.props.overlayIndex} 
                            onChange={ (e) => {this.setState({DetectChange:true,y1: e.target.value})} } />
                    </div>
                    <div className="pointBlock">
                        <label>x</label>
                        <input className="trackerPoint"
                            name="x2"
                            type="number"
                            min="0" max="1"
                            step="0.01"
                            value={this.state.x2} 
                            data-id={this.props.overlayIndex} 
                            onChange= {(e) => {this.setState({DetectChange:true,x2: e.target.value})}} />
                        <label>y</label>
                        <input className="trackerPoint"
                            name="y2"
                            type="number"
                            min="0" max="1"
                            step="0.01"
                            value={this.state.y2} 
                            data-id={this.props.overlayIndex} 
                            onChange= {(e) => {this.setState({DetectChange:true,y2: e.target.value})}} />
                    </div>
                    <div className="pointBlock">
                        <label>x</label>
                        <input className="trackerPoint"
                            name="x3"
                            type="number"
                            min="0" max="1"
                            step="0.01"
                            value={this.state.x3} 
                            data-id={this.props.overlayIndex} 
                            onChange= {(e) => {this.setState({DetectChange:true,x3: e.target.value})}} />
                        <label>y</label>
                        <input className="trackerPoint"
                            name="y3"
                            type="number"
                            min="0" max="1"
                            step="0.01"
                            value={this.state.y3} 
                            data-id={this.props.overlayIndex} 
                            onChange= {(e) => {this.setState({DetectChange:true,y3: e.target.value})}} />
                    </div>
                    <div className="pointBlock">
                        <label>x</label>
                        <input className="trackerPoint"
                            name="x4"
                            type="number"
                            min="0" max="1"
                            step="0.01"
                            value={this.state.x4} 
                            data-id={this.props.overlayIndex} 
                            onChange= {(e) => {this.setState({DetectChange:true,x4: e.target.value})}} />
                        <label>y</label>
                        <input className="trackerPoint"
                            name="y4"
                            type="number"
                            min="0" max="1"
                            step="0.01"
                            value={this.state.y4} 
                            data-id={this.props.overlayIndex} 
                            onChange= {(e) => {this.setState({DetectChange:true,y4: e.target.value})}} />
                    </div>
                    
                    {/*  ----------------------   ACTION BUTTONS  ----------------  */}
                    { this.props.addingNewOv ? 
                        <Button className="deleteBtn"
                            onClick={this.handleAddingOverlay}
                        >
                            ADD
                        </Button>
                    :
                    <>
                        <Tooltip title="Delete Overlay" aria-label="delete"> 
                            <Button className="deleteBtn"
                                onClick={this.handleDeleteOverlay}
                            >
                                DELETE
                            </Button>
                        </Tooltip>


                        <Tooltip title="Apply Changes" aria-label="apply"> 
                            <Button className="applyBtn"
                                onClick={this.applyChanges}
                            >
                                APPLY
                            </Button>
                        </Tooltip>
                    </> }

                    <div style={{height:'1px', clear:'both'}}></div>                
                  
                </SimpleBar>
            </div>
        );
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(OverlayEditor);