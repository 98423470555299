import {
  NumberVariableType,
  StringVariableType,
  TemplateMessage,
  TrackDataType,
  VideoSourceType,
  ToggleFunctionType,
  OverlayMessage,
  SubtitleTrackMessage,
  SubtitleTrackType,
  SceneMessage,
  ActionMessage,
  ContentType,
  ActionType,
  TagSubActionType
} from '@videosmart/player-template';
import { SubtitlePosition } from '@videosmart/player-template/lib/enums/SubtitlePosition';
import { reducerWithInitialState } from 'typescript-fsa-reducers';
import { jsonTryParse } from '../../utils/jsonTryParse';
import { TemplateActions } from '../actions';
import { ITemplateState, VariablesData } from '../models/ITemplateState';


const defaultScene:SceneMessage = {
  id: '',
  videoSource: {
    type: VideoSourceType.Url,
    frameRate: 25,
    source: ''    
  },
  overlays: [
  ],
  subtitles: [
  ]
};

// NEW DEFAULT TEMPLATE WITHOUT OVERLAYS
const defaultTemplate:TemplateMessage = {
  autoPlay: false,
  content: "",
  subtitleOptions: {
    defaultLanguage: {
      type: StringVariableType.Constant,
      value: 'enGb'
    },
    fontFamily: {
      type: StringVariableType.Constant,
      value: 'Arial'
    },
    position: SubtitlePosition.Bottom
  },
  defaultSceneId: {
    type: StringVariableType.Constant,
    value: 'scene1',
  },
  scenes: [
    {
      id: '',
      videoSource: {
        type: VideoSourceType.Url,
        frameRate: 25,
        source: ''    
      },
      overlays: [
      ],
      subtitles: [
      ]
    }
  ]
};

const initialState:ITemplateState = {
  isJsonUpToDate: true,
  json: defaultTemplate,
  text: JSON.stringify(defaultTemplate, undefined, 2),
  validationError: undefined,
  isSaved: true,
  currentScene: 0,
  ArrayScene:[0],
  VariableArray:[],
  initLoaded: false
};

let v:any = "";
let ArrayVar:any = [];
let PreviewsValueJson:any = "";
let deleteVarBoolean:boolean = false;
export const templateReducer = reducerWithInitialState(initialState)
  .case(TemplateActions.actionCreators.InitializeTemplate, (state, payload) => {
    
    const payloadStringify = JSON.stringify(payload, undefined, 2);
    const result = jsonTryParse(payloadStringify);
    if (payload === null) {
      result.isParsed = false;
    }
   
    const finalTemplate:TemplateMessage = defaultTemplate as TemplateMessage; 

    let newState:ITemplateState = state as ITemplateState;
    let newArray:Array<number> = [];
    if(result.isParsed ){
      for(let i=0; i < result.json.scenes.length; i++) {
        newArray.push(i);
      }
      newState.ArrayScene = newArray;
    }

    return {
      text: result.isParsed
          ? payloadStringify
          : JSON.stringify(finalTemplate, undefined, 2),
      isJsonUpToDate: result.isParsed,
      json: result.isParsed ? result.json : finalTemplate,
      validationError: !result.isParsed ? result.validationError : undefined,
      isSaved: state.isSaved,
      currentScene: result.isParsed ? result.json.scenes.length -1 : state.currentScene,
      ArrayScene: result.isParsed ? newState.ArrayScene : state.ArrayScene,
      VariableArray: state.VariableArray,
      initLoaded: true
    }
  })
  .case(TemplateActions.actionCreators.updateTemplate, (state, payload) => {
    const result = jsonTryParse(payload);
    const finalTemplate = JSON.parse(JSON.stringify(state.json));

    const fTemplate:TemplateMessage = result.isParsed
                                      ? result.json as any 
                                      : finalTemplate as TemplateMessage;

    return {
      text: JSON.stringify(fTemplate, undefined, 2),
      isJsonUpToDate: result.isParsed,
      json: fTemplate,
      validationError: !result.isParsed ? result.validationError : undefined,
      isSaved:false,
      currentScene: state.currentScene,
      ArrayScene: state.ArrayScene ,
      VariableArray: state.VariableArray,
      initLoaded: true
    };
  })
  .case(TemplateActions.actionCreators.updateTracker, (state, { currentScene,  overlayId, points }) => {
    const finalTemplate = JSON.parse(JSON.stringify(state.json));
    const newTemplate:TemplateMessage =  finalTemplate as TemplateMessage;
    // we make a copy of the current template
    let newRScene:any = newTemplate.scenes;

    let newpoint =  newRScene[currentScene].overlays![overlayId].tracker!.trackData.frames[0].points;
      // Notes:
      // If tracker has 1 frame & is scale and translate & the positions are 0,0 to 1,1 the 'overlay.tracker' should be undefined.
      // The frame to update is Math.max(0, Math.min(frames length, current frame + offset));
      newpoint = points;
      newRScene[currentScene].overlays![overlayId].tracker!.trackData.frames[0].points = newpoint
      newTemplate.scenes = newRScene;
  
      return {
        ...state,
        json: newTemplate,
        text: JSON.stringify(newTemplate, undefined, 2),
        isSaved: false
      };
  })
  .case(TemplateActions.asyncActionCreators.addOverlayToJson, (state, {sceneNumber, type, action,name, startPoint, endPoint, x1,x2,x3,x4,y1,y2,y3,y4,scene,autoplay,callOnParent, functionT, seektime, tag,subActionValue,target }) => {
    let newA:ActionMessage[] = [];  
    let newOverlay:OverlayMessage [] = [];
    let newOv:OverlayMessage;
    if(action !== undefined && action !== ""){
      let newAction = resolveAction(action, autoplay,scene,callOnParent, functionT,seektime,tag,subActionValue,target);
      newA.push(newAction);
    }
      newOv = overlayTemplate(newA, name,startPoint, endPoint,type,x1,x2,x3,x4,y1,y2,y3,y4);
    
   
    //Tracker type 

    const finalTemplate = JSON.parse(JSON.stringify(state.json));
    const newTemplate:TemplateMessage = finalTemplate as TemplateMessage;
    // because of the variable feature i need to add a overlay also on the state.text so this way the text will have the variable all the time
    let newJson;
    const finalText = jsonTryParse(state.text);
    if(finalText.isParsed){
      newJson = JSON.parse(JSON.stringify(finalText.json));
    }
    let newTextScene:any = newJson.scenes;
    if (newTextScene[sceneNumber].overlays !== undefined) {
      newTextScene[sceneNumber].overlays.push(newOv);
      newJson.scenes = newTextScene;
    }
    // we make a copy of the current template
    let newRScene:any = newTemplate.scenes;
   
    // we add the new overlay to the array of overlays inside the template
    //Prepare the code to have an array of scenes and still work perfectly
    
    if (newRScene[sceneNumber].overlays !== undefined) {
      newRScene[sceneNumber].overlays.push(newOv);
      newTemplate.scenes = newRScene;
    }else{
      newRScene.forEach((scene:any) =>{
        
        let updatedProps = {
            id: scene.id,
            overlays: newOverlay,
            subtitles: scene.subtitles,
            videoSource: scene.videoSource
        }                  
        const sceneToPush:SceneMessage = {
          id: updatedProps.id,
          videoSource: updatedProps.videoSource,
          overlays: newOverlay,
          subtitles: updatedProps.subtitles
        }
        newRScene.length = 0;                    
        newRScene.push(sceneToPush);
      }); 
      newRScene[sceneNumber].overlays.push(newOv);
      newTemplate.scenes = newRScene;
    }
    // --------------------------------------------- content section --------------------------------------------------------------
    buildcontent(newTemplate,newRScene);
     newTemplate.content =  v;
     newJson.content =  v;
//'-----------------------------------------------end content----------------------------------------------------------------------
    // we override the json file in the state with the updated one
    return {
      ...state,
      json: newTemplate,
      text: JSON.stringify(newJson, undefined, 2),
      isSaved: false
    }
  })
  .case(TemplateActions.asyncActionCreators.addSubtitleLanguageToJson, ( state , { currentScene } ) => {
    // create the object for the new subtitle track that will be added to the jsonEditor
    const newSubtitle:SubtitleTrackMessage = {
        type: SubtitleTrackType.Constant,
        label: "English",
        language: "en",
        //cues: "{variableSubtitle}"
        cues: [{
          length: 0,
          start: 0,
          text: "",
        }]   
    };

    // we make a copy of the current template
    const finalTemplate = JSON.parse(JSON.stringify(state.json));

    const newTemplate:TemplateMessage = finalTemplate as TemplateMessage; 
   
    // we add the new overlay to the array of overlays inside the template
    // Prepare the code to have an array of scenes and still work perfectly
    let newRScene:any = newTemplate.scenes;
      if (newRScene[currentScene].subtitles !== undefined) {
        newRScene[currentScene].subtitles.push(newSubtitle);
      }
      else {
        console.log('Template Reducer : the scene array is undefined');
      }
    newTemplate.scenes = newRScene;

    // we overwrite the json file in the state with the updated one
    return {
      ...state,
      json: newTemplate,
      text: JSON.stringify(newTemplate, undefined, 2),
      isSaved: false
    }
  })
  .case(TemplateActions.asyncActionCreators.removeOverlayFromJson, ( state, { currentScene, indexInArray } ) => {
    const finalTemplate = JSON.parse(JSON.stringify(state.json));
    const newTemplate:TemplateMessage = finalTemplate as TemplateMessage; 
    // we make a copy of the current template
    let newRScene:any = newTemplate.scenes;
    // let newRScene:any = newState.json.scenes[currentScene];   
    const overlays = newRScene[currentScene].overlays;   
    const updatedOverlays = overlays.filter((ov:any) => {
      return ov !== overlays[indexInArray]
    });
    newRScene[currentScene].overlays = updatedOverlays;
    newTemplate.scenes = newRScene

    // --------------------------------------------- content section --------------------------------------------------------------
        buildcontent(newTemplate,newRScene);
        newTemplate.content =  v;
    //'-----------------------------------------------end ----------------------------------------------------------------------

    let newJson;
    const finalText = jsonTryParse(state.text);
    if(finalText.isParsed){
      newJson = JSON.parse(JSON.stringify(finalText.json));
    }
    let newTextScene:any = newJson.scenes;
    if (newTextScene[currentScene].overlays !== undefined) {
      newTextScene[currentScene].overlays = updatedOverlays
      newJson.scenes = newTextScene;
    }
    newJson.content =  v;

    return {
      ...state,
      json: newTemplate,
      text: JSON.stringify(newJson, undefined, 2),
      isSaved: false
    }
  })
  .case(TemplateActions.asyncActionCreators.updateOverlay, (state, { currentScene, index, startPoint, endPoint, name, id,action, type, x1,x2,x3,x4,y1,y2,y3,y4,scene,autoplay,callOnParent, functionT, seektime, tag, trackerType, subActionValue, target } ) => {
    const finalTemplate = JSON.parse(JSON.stringify(state.json));
    const newTemplate:TemplateMessage = finalTemplate as TemplateMessage; 
    const vidDuration = Number(document.getElementsByTagName('video')[0].duration.toFixed(3));
    let newOv:OverlayMessage;
    let newA:ActionMessage[] = []; 

    // we add the new overlay to the array of overlays inside the template
    // Prepare the code to have an array of scenes and still work perfectly
    let newRScene:any = newTemplate.scenes;
    let overlays = newRScene[currentScene].overlays;

      // first we make a copy of the overlay to be updated inside the array of overlays
      // we update the start and end point of this overlay
      // //we update the visible value for this specific overlay
      if (name !== undefined) {
        overlays[index].content.embeddedId = name;
      }
      if (type !== undefined) {
        overlays[index].tracker.type = type;
      }
    // verify start and endpoint (validade points and config with max ranges)
    let start;
    let end;
    if(startPoint !== "" && startPoint !== undefined){
      start  = Number(startPoint);
    }else{
      start = 0;      
    }
    if(endPoint !== "" && endPoint !== undefined){
      end = Number(endPoint);    
    }else{
      end = vidDuration;      
    }
    overlays[index].start.time = start;
    overlays[index].end.time = end;


      if(action !== undefined && action !== ""){
        let newAction = resolveAction(action, autoplay,scene,callOnParent, functionT,seektime,tag,subActionValue,target);

          if(overlays[index].actions !== undefined){
            overlays[index].actions.length = 0;
            overlays[index].actions.push(newAction);     
          }
          else{
            newA.push(newAction);    
          
            newOv = overlayTemplate(newA, name,start, end,type,x1,x2,x3,x4,y1,y2,y3,y4);

            overlays[index] = newOv;
          }    
      }
      
      
      if ((x1 !== undefined) && (typeof(x1) === 'string')) {
        overlays[index].tracker.trackData.frames[0].points[0].x = Number(x1);
        if(trackerType === 'scaleAndTranslate') {
          overlays[index].tracker.trackData.frames[0].points[3].x = Number(x1);
        }
      }
      if ((x2 !== undefined) && (typeof(x2) === 'string')) {
        overlays[index].tracker.trackData.frames[0].points[1].x = Number(x2);
        if(trackerType === 'scaleAndTranslate') {
          overlays[index].tracker.trackData.frames[0].points[2].x = Number(x2);
        }
      }
      if ((x3 !== undefined) && (typeof(x3) === 'string')) {
        overlays[index].tracker.trackData.frames[0].points[2].x = Number(x3);
        if(trackerType === 'scaleAndTranslate') {
          overlays[index].tracker.trackData.frames[0].points[1].x = Number(x3);
        }
      }
      if ((x4 !== undefined) && (typeof(x4) === 'string')) {
        overlays[index].tracker.trackData.frames[0].points[3].x = Number(x4);
        if(trackerType === 'scaleAndTranslate') {
          overlays[index].tracker.trackData.frames[0].points[0].x = Number(x4);
        }
      }
      if ((y1 !== undefined) && (typeof(y1) === 'string')) {
        overlays[index].tracker.trackData.frames[0].points[0].y = Number(y1);
        if(trackerType === 'scaleAndTranslate') {
          overlays[index].tracker.trackData.frames[0].points[1].y = Number(y1);
        }
      }
      if ((y2 !== undefined) && (typeof(y2) === 'string')) {
        overlays[index].tracker.trackData.frames[0].points[1].y = Number(y2);
        if(trackerType === 'scaleAndTranslate') {
          overlays[index].tracker.trackData.frames[0].points[0].y = Number(y2);
        }
      }
      if ((y3 !== undefined) && (typeof(y3) === 'string')) {
        overlays[index].tracker.trackData.frames[0].points[2].y = Number(y3);
        if(trackerType === 'scaleAndTranslate') {
          overlays[index].tracker.trackData.frames[0].points[3].y = Number(y3);
        }
      }
      if ((y4 !== undefined) && (typeof(y4) === 'string')) {
        overlays[index].tracker.trackData.frames[0].points[3].y = Number(y4);
        if(trackerType === 'scaleAndTranslate') {
          overlays[index].tracker.trackData.frames[0].points[2].y = Number(y4);
        }
      }
      // we create a copy of the state including the updated overlay
      //newJson.scenes[currentScene].overlays = overlays;
      newRScene[currentScene].overlays = overlays;
      newTemplate.scenes = newRScene

      //text
      let newJson;
      const finalText = jsonTryParse(state.text);
      if(finalText.isParsed){
        newJson = JSON.parse(JSON.stringify(finalText.json));
      }
      let newTextScene:any = newJson.scenes;
      if (newTextScene[currentScene].overlays !== undefined) {
        newTextScene[currentScene].overlays = overlays
        newJson.scenes = newTextScene;
      }
    // --------------------------------------------- content section --------------------------------------------------------------
     buildcontent(newTemplate, newRScene);
     newTemplate.content =  v;
     newJson.content = v;
//'-----------------------------------------------end ----------------------------------------------------------------------
    
    // we return the new state
    return {
      ...state,
      json: newTemplate,
      text: JSON.stringify(newJson, undefined, 2),
      isSaved: false
    }
    
  })
  .case(TemplateActions.asyncActionCreators.addSceneToJson, ( state , { sourceType, sceneId, videoSource, s3Source, changeToScene } ) => {
    let newScene:SceneMessage;   
    if(sourceType === 'url') {
      newScene = {
        id: sceneId ? sceneId : 'S' + (state.json.scenes.length + 1),
        videoSource: {
          type: VideoSourceType.Url,
          frameRate: videoSource.frameRate ? Number(videoSource.frameRate) : 25,
          source: videoSource.url ? videoSource.url : 'https://s3-eu-west-1.amazonaws.com/mbaaws-rci/122/87777A95-7FEF-4DD5-9F9C-9BEE78D44B18.mp4'     
        },
        overlays: [
        ],
        subtitles: [
        ]
      };
    }
    else {
      newScene = {
        id: sceneId ? sceneId : 'S' + (state.json.scenes.length + 1),
        videoSource: {
          s3Bucket: s3Source.s3Bucket ? s3Source.s3Bucket : '',
          s3Key: s3Source.s3Key ? s3Source.s3Key : '',
          isPrivate: s3Source.isPrivate,
          type: VideoSourceType.S3,
          frameRate: s3Source.frameRate 
        },
        overlays: [
        ],
        subtitles: [
        ]
        
      }
    }     
    const finalTemplate = JSON.parse(JSON.stringify(state.json));
    const newTemplate:TemplateMessage = finalTemplate as TemplateMessage;  
    let newScenesArray:any = newTemplate.scenes; 
    if (newScenesArray !== undefined) {
      newScenesArray.push(newScene);
    }
    else {
      console.log('Template Reducer : the overlays array is undefined');
    }
    if(newScenesArray.length === 1){newTemplate.defaultSceneId = newScene.id;}
    newTemplate.scenes = newScenesArray;
    //Update currentScene and array of scenes
    const final = JSON.parse(JSON.stringify(state));    
    const newValue = newScenesArray.length -1;   
    final.ArrayScene.push(newValue);    
    if(changeToScene){
      final.currentScene= newValue;
    }
    
    let newJson;
    const finalText = jsonTryParse(state.text);
    if(finalText.isParsed){
      newJson = JSON.parse(JSON.stringify(finalText.json));
    }
    let newTextScene:any = newJson.scenes;
    if (newTextScene !== undefined) {
      newTextScene.push(newScene);
      newJson.scenes = newTextScene;
      
    }
    if(newTextScene.length === 1){newJson.defaultSceneId = newScene.id;}


    return {
      ...state,
      json: newTemplate,
      text: JSON.stringify(newJson, undefined, 2),
      isSaved: false,
      currentScene: final.currentScene,
      ArrayScene: final.ArrayScene
    }
  })
  .case(TemplateActions.asyncActionCreators.changeScene, ( state , index) => {
    const final = JSON.parse(JSON.stringify(state));  
    const newValue = index;
    final.currentScene= newValue;

    return {
      ...state,
      currentScene: final.currentScene
    }
  })
  .case(TemplateActions.asyncActionCreators.updateSubtitleLanguage, ( state, { currentScene, index, label, language, type } ) => {
    let newTemplate:ITemplateState = { ...state };

    let newJson:any = { ...state.json };

    if(newJson.scenes[currentScene].subtitles[index]) {
      if(label !== undefined) {
        newJson.scenes[currentScene].subtitles[index].label = label;
      }
      if(language !== undefined) {
        newJson.scenes[currentScene].subtitles[index].language = language;
      }
      if(type !== undefined) {
        newJson.scenes[currentScene].subtitles[index].type = type;
      }
    }

    return {
      ...newTemplate,
      json: newJson,
      text: JSON.stringify(newJson, undefined, 2),
      isSaved: false
    }
  })
  .case(TemplateActions.asyncActionCreators.setSceneBasicInfo, ( state, { sourceType, newInfo, newId } ) => {
    let newState = JSON.parse(JSON.stringify(state));

    let sceneIndex = newState.json.scenes.length > 0 ? newState.currentScene : 0;

    if (newState.json.scenes.length === 0) {
      newState.json.scenes.push(defaultScene);
    }
    if (newState.json.scenes.length === 1) {
      newState.json.defaultSceneId = newId;
    }
    newState.json.scenes[newState.currentScene].id = newId;

    if(sourceType === 'url') {
      newState.json.scenes[newState.currentScene].videoSource = {
        type: VideoSourceType.Url,
        frameRate: newInfo.frameRate,
        source: newInfo.url
      }
    }
    else if(sourceType === 's3') {
      newState.json.scenes[newState.currentScene].videoSource = {
        s3Bucket: newInfo.s3Bucket,
        s3Key: newInfo.s3Key,
        isPrivate: newInfo.isPrivate,
        s3Region: newInfo.s3Region,
        type: VideoSourceType.S3,
        frameRate: newInfo.frameRate 
      }
    }     

    const payloadStringify = JSON.stringify(newState.json, undefined, 2);
    const result = jsonTryParse(payloadStringify);
    if (newState === null) {
      result.isParsed = false;
    }

    const finalTemplate:TemplateMessage = { ...defaultTemplate };

    let newArray:Array<number> = [];
    if(result.isParsed && result.json.scenes){
      for(let i=0; i < result.json.scenes.length; i++) {
        newArray.push(i);
      }
      newState.ArrayScene = newArray;
    }
    else { newState.ArrayScene = [0]; }


    return {
      text: result.isParsed
          ? payloadStringify
          : JSON.stringify(finalTemplate, undefined, 2),
      isJsonUpToDate: result.isParsed,
      json: result.isParsed ? result.json : finalTemplate,
      validationError: !result.isParsed ? result.validationError : undefined,
      isSaved: false,
      currentScene: sceneIndex,
      ArrayScene: newState.ArrayScene,
      VariableArray: state.VariableArray,
      initLoaded: true   
    }
  })
  .case(TemplateActions.asyncActionCreators.deleteSceneFromTemplate, (state,{currentScene}) => {
    const newState = JSON.parse(JSON.stringify(state));
    const currentJson = newState.json
    let newScenes:any;
    if(currentJson.scenes.length > 1) {
      newScenes = currentJson.scenes.filter((scene:any, index:number) => {
        return index !== currentScene;
      });
    }
    else {
      newScenes = [defaultScene];
      state.isSaved = false;
    }
    newState.json.scenes = newScenes;
    const newSceneIndex = newScenes.length > 0 ? (newScenes.length - 1) : 0;
    const payloadStringify = JSON.stringify(newState.json, undefined, 2);
    const result = jsonTryParse(payloadStringify);
    if (newState === null) {
      result.isParsed = false;
    }
  
    let newJson;
    const finalText = jsonTryParse(state.text);
    if(finalText.isParsed){
      newJson = JSON.parse(JSON.stringify(finalText.json));
    }
    let newTextScene:any = newJson.scenes;
    newTextScene = newScenes
    if(newScenes[0].id !== newState.json.defaultSceneId){
      newState.json.defaultSceneId =newScenes[0].id;
      newJson.defaultSceneId =newScenes[0].id;
    }
    newJson.scenes = newTextScene;
    
    

    const finalTemplate:TemplateMessage = { ...defaultTemplate };

    let newArray:Array<number> = [];
    if(result.isParsed && result.json.scenes){
      for(let i=0; i < result.json.scenes.length; i++) {
        newArray.push(i);
      }
      newState.ArrayScene = newArray;
    }
    else { newState.ArrayScene = [0]; }

    return {
      text: JSON.stringify(newJson, undefined, 2) ,
      isJsonUpToDate: result.isParsed,
      json: result.isParsed ? result.json : finalTemplate,
      validationError: !result.isParsed ? result.validationError : undefined,
      isSaved: false,
      currentScene: newSceneIndex,
      ArrayScene: newState.ArrayScene,
      VariableArray: state.VariableArray,
      initLoaded: true   
    }


  })
  .case(TemplateActions.asyncActionCreators.addVariable, ( state , {text, line} ) => {
    let final = JSON.parse(JSON.stringify(state));
    let variableExists:boolean = false;
    let arraysPosVar:number = -1;
    if(final.VariableArray.length > 0){
      for(let i=0; i<final.VariableArray.length; i++){
        if (final.VariableArray[i].name === text) {
          variableExists = true;
          arraysPosVar = i;
        }
      }
    }
    let newVariable:VariablesData;
    if((final.VariableArray.length === 0) || !variableExists) {
      newVariable = {
        name: text,
        value: null,
        positionsLine: [line]
      }
      final.VariableArray.push(newVariable);
    }
    else {
      if(final.VariableArray.length > 0 && (variableExists = false)){
        newVariable = {
          name: text,
          value: null,
          positionsLine: [line]
        }
        final.VariableArray.push(newVariable);
      }else{
        final.VariableArray[arraysPosVar].positionsLine.push(line);
      }   
    }
    return {
      ...state,
      VariableArray: final.VariableArray
    }
  })
  .case(TemplateActions.asyncActionCreators.deleteVariable, (state, {variable}) => {
    deleteVarBoolean = true;

  
    let newJson: any
    const stateC = JSON.parse(JSON.stringify(state));
    const newTemplate:ITemplateState = stateC as ITemplateState; 
    let newArray:any = newTemplate.VariableArray;
    const temp = jsonTryParse(state.text);
    if(temp.isParsed){
      newJson = JSON.parse(JSON.stringify(temp.json));
    }else{
      newJson = JSON.parse(JSON.stringify(state.json));
    }
    
    let result:any;
    result = getVariable(newJson, variable, "")
    newArray = newArray.filter(e => e.name !== variable);
    newTemplate.VariableArray = newArray

    deleteVarBoolean = false;
    return {
      ...state,
      text: JSON.stringify(result, undefined, 2),
      json: result,
      VariableArray: newTemplate.VariableArray
    }
  })
  .case(TemplateActions.asyncActionCreators.deleteAllVariable, (state, {allvar}) => {
    deleteVarBoolean = true;
    let newJson: any
    const stateC = JSON.parse(JSON.stringify(state));
    const newTemplate:ITemplateState = stateC as ITemplateState; 
    const temp = jsonTryParse(state.text);
    if(temp.isParsed){
      newJson = JSON.parse(JSON.stringify(temp.json));
    }else{
      newJson = JSON.parse(JSON.stringify(state.json));
    } 
    let result:any;
    if(newTemplate.VariableArray.length > 0){
      allvar.forEach((variable:any) => {
      
        result = getVariable(newJson,variable.name , "")
      
      })    
      newTemplate.VariableArray = []
      deleteVarBoolean = false;
      return {
        ...state,
        text: JSON.stringify(result, undefined, 2),
        json: result,
        VariableArray: newTemplate.VariableArray
      }
    }
    else{
      return {
        ...state
      }
    }
  })
  .case(TemplateActions.asyncActionCreators.applyVariableValue, (state, {variable, result}) => {
    let stateC;
    //const stateC = JSON.parse(JSON.stringify(state.json));
    const final = JSON.parse(JSON.stringify(state));
    let newJson;
    const temp = jsonTryParse(state.text);
    if(temp.isParsed){
      stateC = JSON.parse(JSON.stringify(temp.json));
    }else{
      stateC = JSON.parse(JSON.stringify(state.json));
    }
    const newTemplate:TemplateMessage = stateC as TemplateMessage ; 
    let newArray:any = newTemplate;
    final.VariableArray.forEach((value: any, index: any) =>{
      if(value.name !== variable){
        newJson = getVariable(newArray, value.name, value.value);
      }
    });
    newJson = getVariable(newArray, variable, result);
    for(let i=0; i<final.VariableArray.length; i++){
      if (final.VariableArray[i].name === variable) {
        final.VariableArray[i].value = result;
      }
    }
    return {
      ...state,
      json: newJson,
      VariableArray: final.VariableArray
    }
  })
  .case(TemplateActions.actionCreators.applyAllVariable, (state) => {
    let stateC;
    const final = JSON.parse(JSON.stringify(state));
    let newJson;
    const temp = jsonTryParse(state.text);
    if(temp.isParsed){
      stateC = JSON.parse(JSON.stringify(temp.json));
    }else{
      stateC = JSON.parse(JSON.stringify(state.json));
    }
    const newTemplate:TemplateMessage = stateC as TemplateMessage ; 
    let newArray:any = newTemplate;
    if(final.VariableArray.length > 0){
      final.VariableArray.forEach((value: any, index: any) =>{   
          newJson = getVariable(newArray, value.name, value.value);    
      });
      return {
        ...state,
        json: newJson,
      }
    }
    else{
      return{
        ...state
      }
    }
  })
  .case(TemplateActions.asyncActionCreators.updateVariableEditor, (state, {variable, result}) => {
    const final = JSON.parse(JSON.stringify(state));   
    for(let i=0; i<final.VariableArray.length; i++){
      if (final.VariableArray[i].name === variable) {
        final.VariableArray[i].value = result;
      }
    }
    return {
      ...state,
      VariableArray: final.VariableArray
    }
  })
  .case(TemplateActions.actionCreators.initializeVariable, (state) => {
    let stateC
    let AllVar:any = []
    const final = JSON.parse(JSON.stringify(state));
    const temp = jsonTryParse(state.text);
    if(temp.isParsed){
      stateC = JSON.parse(JSON.stringify(temp.json));
    }else{
      stateC = JSON.parse(JSON.stringify(state.json));
    }
    const newTemplate:TemplateMessage = stateC as TemplateMessage ; 
    let newArray:any = newTemplate;
    ArrayVar = [];
    AllVar = getInitAllVariable(newArray);
    let varA:any = [];
    let newVariable:VariablesData;
    for(let i= 0; i < AllVar.length; i ++){
      newVariable = {
        name: AllVar[i],
        value: null,
        positionsLine: [0]
      }
      varA.push(newVariable)
          
    }
    final.VariableArray = varA;  
    return {
      ...state,
      VariableArray: final.VariableArray
    }
  })
  .case(TemplateActions.actionCreators.resetStore, () => {
    return { ...initialState }
  })
  .case(TemplateActions.asyncActionCreators.addPoster, (state, {posterText}) => {
    const finalTemplate = JSON.parse(JSON.stringify(state.json));
    const newTemplate:TemplateMessage = finalTemplate as TemplateMessage;

    newTemplate.poster = posterText;

    return {
      ...state,
      json: newTemplate,
      text: JSON.stringify(newTemplate, undefined, 2),
      isSaved: false
    }
  })
  .case(TemplateActions.asyncActionCreators.deletePoster, (state) => {
    const finalTemplate = JSON.parse(JSON.stringify(state.json));
    const newTemplate:TemplateMessage = {
      autoPlay: finalTemplate.autoPlay,
      content: finalTemplate.content,
      subtitleOptions: finalTemplate.subtitleOptions,
      defaultSceneId: finalTemplate.defaultSceneId,
      scenes: finalTemplate.scenes
    }
    return {
      ...state,
      json: newTemplate,
      text: JSON.stringify(newTemplate, undefined, 2),
      isSaved: false
    }
  })
  .case(TemplateActions.actionCreators.templateSaved, (state, { isSaved }) => {    
    return {
      ...state,
      isSaved: isSaved
    }
  })
  
function buildcontent(value:any, value2:any){
  let newContent:any = value.content;
  let ArrayRest:any = [];
    if(value2 !== undefined){
      value2.forEach((scene:any, index:number) => {
        if(scene.overlays !== undefined){
          scene.overlays.forEach((overlay:any, index:number) => {   
            if(overlay.actions !== undefined){
              overlay.actions.forEach((actionO:ActionMessage, index:number) => {
                let valueAction = "&nbsp;";
                if(actionO && actionO.type === ActionType.Tag){
                  if(actionO.subAction){
                    let target="";
                    let href ="";
                    switch(actionO.subAction.type){
                      case TagSubActionType.Url:
                          target = actionO.subAction.target ? "target='_blank'":"";
                          href = "href='"+actionO.subAction.value+"'";
                          break;
                      case TagSubActionType.Email:
                        href = "href='mailto:"+actionO.subAction.value+"'";
                        break; 
                      case TagSubActionType.Phone:
                        href = "href='tel:"+actionO.subAction.value+"'";
                        break;  
                    }
                    valueAction = `<a style='display:block;width:100%;height:100%;' `+href+` `+target+`>&nbsp;</a>`;
                  }
                }
                   let defaultvalue = `<div id='`+ overlay.content.embeddedId +`' class='vsp-embedded-overlay'><div class='vsp-transparent-button vsp-interactive'>`+valueAction+`</div></div>`; 
                  ArrayRest.push(defaultvalue);
                                         
              });            
            }  
          });
        }      
      });
  
    }
  let final = `<style>.vsp-transparent-button{display:block;height:100%;width:100%;border-radius:5px;cursor:pointer;background-color:rgba(255,255,255,0.000000001);}.vjs-poster{background-size:cover;}</style>`+ ArrayRest.join("") + ``;
  newContent = final;
  v =  newContent;
 //
  return v;
}
//recrusive function to run all json
function getVariable(json, variable, result) {
  var x;
  if(json instanceof Object) {
      for (x in json) {
        if (json.hasOwnProperty(x)){
          //recursive call to scan property
          
          if(json[x] === variable) {
            if(deleteVarBoolean){
              if(result === ""){
                if(PreviewsValueJson === "end"){
                  json[x]= 5;
                }
                if(PreviewsValueJson === "start"){
                  json[x] = 0;
                }
                if(PreviewsValueJson === "videoSource"){
                  json[x] = "https://s3-eu-west-1.amazonaws.com/mbaaws-rci/122/87777A95-7FEF-4DD5-9F9C-9BEE78D44B18.mp4";
                }
                if(PreviewsValueJson === "subtitles"){
                  json[x] = []
                }
               // json[x] = result;
              }
            }
            else{          
              if(PreviewsValueJson === "end"){
                json[x]= Number(result);
              }else if(PreviewsValueJson === "start"){
                json[x] = Number(result);
              }else{
                json[x] = result;
              }
             
            }

          }
          else{
            if(deleteVarBoolean){
              if(result ===""){
                PreviewsValueJson = x;
              }
            }
            PreviewsValueJson = x;
            getVariable( json[x], variable, result );  
          }
        }                
      }
  }
  else {
  };
  return json;

};
function getInitAllVariable(json) { 
  var x;
  if(json instanceof Object) {
      for (x in json) {
        if (json.hasOwnProperty(x)){
          var re = new RegExp(/\{[a-zA-Z]{1,}\}/g);
          if(re.test(json[x])){
            ArrayVar.push(json[x]);
          }
          else{
            getInitAllVariable(json[x]);  
          }                 
        }                
      }
  }
  else {
  };
  return ArrayVar;
};

var overlayTemplate = function(newA:any, name:any, startPoint:any, endPoint:any, type:any, x1:any,x2:any,x3:any,x4:any,y1:any, y2:any,y3:any,y4:any) : OverlayMessage {

  return {
    actions: newA.length>0 ? newA:undefined,
  content: { 
    embeddedId: name,
    type: ContentType.Embedded
  },
  start: 
  { 
    type: ToggleFunctionType.TimeUpdate,
    time: startPoint ? Number(startPoint) : 0
  },  
  end: {
    type: ToggleFunctionType.TimeUpdate,
    time: endPoint ?  Number(endPoint) : 5
  },  
  tracker: {
    type: type ? type : "scaleAndTranslate",
    trackData: {
      offsetFrames: {
        type: NumberVariableType.Constant,
        value: 0
      },
      type: TrackDataType.Embedded,
      frames: [{
        points: [
          { x: x1, y: y1 },
          { x: x2, y: y2 },
          { x: x3, y: y3 },
          { x: x4, y: y4 }
        ]
      }]
    },
  }
}
}; 

function resolveAction(action:any, autoplay:any, scene:any, callOnParent:any, functionT:any, seektime:any, tag:any, subActionValue: any, target:any) : ActionMessage {
  let newAction:ActionMessage;
        if(action === ActionType.LoadScene){
          newAction = {
            type: action,
            autoPlay: autoplay? autoplay : true,
            sceneId: scene? scene : "s2"
          }
        }else if( action === ActionType.FunctionCall){
          newAction = {
            type: action,
            callOnParent: callOnParent ? callOnParent : true,
            function: functionT? Function(functionT): Function,
          }
        }else if( action === ActionType.Seek){
          newAction = {
            type: action,
            time: seektime? Number(seektime) : 0
          }
        }else if( action === ActionType.Tag){
          newAction = {
            type: action,
            tag: tag? tag : ""
          }
        }else if( action === TagSubActionType.Url 
          || action === TagSubActionType.Email 
          || action === TagSubActionType.Phone){
            newAction = {
              type: ActionType.Tag,
              tag: tag,
              subAction: {
                value: subActionValue,
                type:action,
                target: action === TagSubActionType.Url && target?target:undefined
              }
            }
          }else{    
              newAction = {
                type: action              
              }             
            }
            return newAction;     
        
};
