import React, { useState } from 'react';
import Overlay from './Overlay/Overlay';
import OverlayProps from './OverlayProps/OverlayProps';

import './styles/OverlayRow.scss';

export interface OverlayRowProps {
    index:number,
    ovHeight:number,
    sceneDuration:number
}

const OverlayRow = (props:OverlayRowProps) => {
    const [ovClasses, updateClasses] = useState('overlay');
    const [activeHeight, updateHeight] = useState(false);
    
    return (
        <div className="overlay-row">
            <OverlayProps 
                index={props.index}
                updateOvClasses={updateClasses}
                cssHeight={activeHeight ? 80 : props.ovHeight}
            />
            <Overlay 
                index={props.index}
                cssHeight={activeHeight ? 80 : props.ovHeight}
                sceneDuration={props.sceneDuration}
                ovClasses={ovClasses}
                updateHeight={updateHeight}
            />
        </div>
    )
};

export default OverlayRow;