import React from 'react';
import { IRootState } from '../../redux';
import { connect } from 'react-redux';
import '../templates/styles/template.scss';
import {
    Dialog,
    DialogContent,
    DialogTitle,
} from '@material-ui/core';
import { Close } from '@material-ui/icons';

import  TemplateVersionModal  from '../templates/components/TemplateVersionModal'

export interface DetailsProps   {
    name: string,
    version: number,
}
export const mapStateToProps = (state:IRootState) => {
    return {
        name: state.campaign.name,
        version: state.campaign.version
    }
}

class CampaignDetails extends React.Component<DetailsProps> {
    state:any;
    constructor(props:any){
        super(props);
        this.state = {
            open: false,
            versions:[]
        }
        this.handleClick = this.handleClick.bind(this);
        this.handleDialogClose = this.handleDialogClose.bind(this);
    }
    handleClick(){
        this.setState(() => ({open: true }))
    }
    handleDialogClose(){
        this.setState(() => ({open: false }))
    }
    render(){
        return (
            <div className={(this.props.name === '' && this.props.version === -1) ? "templateInfo empty" : "templateInfo"}>
                {this.props.name !== '' ?
                    <div className="templateNameContainer">
                        <span className="label noselect">TEMPLATE</span>
                        <span id="templateName" className="details noselect">{this.props.name}</span>
                    </div> 
                : <span>&nbsp;</span>}
                {this.props.version !== -1 ?
                     <>
                        <div className="templateVersionContainer" onClick={this.handleClick}>
                            <span className="label noselect">VERSION</span>
                            <span id="templateVersion" className="details noselect">{this.props.version}</span>
                        </div>
                        <Dialog fullScreen={false} open={this.state.open} onClose={this.handleDialogClose} aria-labelledby="editor-settings-modal-title">
                            <DialogTitle id="editor-settings-modal-title">Versions list   <Close
                                    className="closeBtn"
                                    onClick={ this.handleDialogClose  }
                                >                    
                                </Close>  
                                </DialogTitle> 
                                          
                            <DialogContent>
                            
                               <TemplateVersionModal />
                           
                            </DialogContent>
                        </Dialog>
                    </>
                : <span>&nbsp;</span>}
            </div>
        );
    }
}
export default connect(mapStateToProps)(CampaignDetails);