import { createStyles, Theme, withStyles } from '@material-ui/core';
import { WithStyles } from '@material-ui/styles';
import { Point, TrackerType } from '@videosmart/player-template';
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { TemplateActions, IRootState, EditorSettingsActions } from '../../../../redux';
import { getRelativeCoordinates } from '../../../../utils';
import { Selection } from '../models/Selection';
import { Tracker } from '../models/Tracker';
import { OverlayDrawer } from './OverlayDrawer';
import { VideoDrawer } from './VideoDrawer';

const styles = (theme: Theme) => createStyles({
  canvas: {
    display: 'block',
    backgroundColor: '#2c2c2c',
    height: '100%',
    width: '100%',
    cursor: 'normal',
    '&$dragging': {
      cursor: 'grabbing'
    },
    '&$resizing': {
      cursor: 'pointer'
    }
  },
  dragging: {},
  resizing: {}
});

export interface TrackerStyle {
  color: string;
  shadowColor: string;
  textColor: string;
  opacity: number;
};

const defaultStyle: TrackerStyle = {
  color: '#44CBff',
  shadowColor: '#00000077',
  textColor: '#F4CB48',
  opacity: 0
};

export interface PreviewCanvasProps extends WithStyles<typeof styles> {
  height: number;
  trackers: Tracker[];
  style?: Partial<TrackerStyle>;
  videoSrc?: string;
  width: number;
  overlaysInScene: any;
  template: any;
  currentScene: number;
  handleUpdateTracker: typeof TemplateActions.actionCreators.updateTracker;
  overlayToDelete: typeof EditorSettingsActions.EditorSettingsActions.overlayToDelete;
};

export interface PreviewCanvasState {
  canvasDrag: boolean;
  localTrackers: Tracker[];
  overlayDrag: boolean;
  selection?: Selection;
  videoHeight: number;
  videoWidth: number;
  overlays: any;
};

export interface ICanvasState {
  boundaryX: number;
  boundaryY: number;
  currentFrame: number;
  mousePosition: Point;
  mousePreviousPosition: Point;
  offsetX: number;
  offsetY: number;
  zoomIndex: number;
};

const mapStateToProps = (state:IRootState) => {
  return {    
    overlaysInScene: state.overlaysInScene.current,
    template: state.template.present.json,
    currentScene: state.template.present.currentScene
  }
}

const mapDispatchToProps = (dispatch: Dispatch): Pick<PreviewCanvasProps, 'handleUpdateTracker' | 'overlayToDelete'> => ({  
  handleUpdateTracker: bindActionCreators(TemplateActions.actionCreators.updateTracker, dispatch),
  overlayToDelete: bindActionCreators(EditorSettingsActions.EditorSettingsActions.overlayToDelete, dispatch),
});

const zoomScale = [0.2, 0.35, 0.5, 0.6, 0.7, 0.8, 0.9, 1, 1.25, 1.5, 1.75, 2, 3, 4, 6, 8, 10, 16];

class PreviewCanvas extends React.Component<PreviewCanvasProps, PreviewCanvasState> {

  private readonly _canvas: React.RefObject<HTMLCanvasElement>;

  private readonly _overlayDrawer: OverlayDrawer;  

  private readonly _videoDrawer: VideoDrawer;

  private _ctx?: CanvasRenderingContext2D;

  private _canvasState: ICanvasState;

  constructor(props: PreviewCanvasProps) {
    super(props);

    this._canvas = React.createRef();

    this.state = {
      canvasDrag: false,
      localTrackers: [],
      overlayDrag: false,
      selection: undefined,
      videoHeight: 360,
      videoWidth: 640,
      overlays: []
    };
    
    this._canvasState = {
      boundaryX: 0,
      boundaryY: 0,
      currentFrame: 0,
      mousePreviousPosition: { x: 0, y: 0 },
      mousePosition: { x: 0, y: 0 },
      offsetX: 0,
      offsetY: 0,
      zoomIndex: zoomScale.indexOf(1),
    };
    
    this._overlayDrawer = new OverlayDrawer();
    
    this._videoDrawer = new VideoDrawer({      
      frameRate: 25,
      onFrameUpdate: this.handleFrameUpdate,
      onResolutionChange: this.handleResolutionChange,
      videoSrc: this.props.template.scenes[this.props.currentScene].videoSource.source || '',
    });

    this.draw = this.draw.bind(this);
    this.mouseToFramePosition = this.mouseToFramePosition.bind(this);
    this.calcBoundaries = this.calcBoundaries.bind(this);
    this.setCanvasState = this.setCanvasState.bind(this);
    this.handleFrameUpdate = this.handleFrameUpdate.bind(this);
    this.handleResolutionChange = this.handleResolutionChange.bind(this);
    this.handleMouseDown = this.handleMouseDown.bind(this);
    this.handleMouseUp = this.handleMouseUp.bind(this);
    this.handleMouseWheel = this.handleMouseWheel.bind(this);
    this.handleMouseMove = this.handleMouseMove.bind(this);
    this.handleMouseLeave = this.handleMouseLeave.bind(this);
    this.handleContextMenu = this.handleContextMenu.bind(this);
    this.handleSelectStart = this.handleSelectStart.bind(this);
  }; 

  public componentDidUpdate = (PrevProps:any) => {
    const { boundaryX, boundaryY } = this.calcBoundaries();

    if(PrevProps.currentScene !== this.props.currentScene){
      this._videoDrawer.videoSrc =  this.props.template.scenes[this.props.currentScene].videoSource.source;
    }
    this.setCanvasState(() => ({ boundaryX, boundaryY }));
    this.draw();    
  };

  public componentDidMount = () => {
    const canvas = this._canvas.current;
    if (canvas) {
      this._ctx = canvas.getContext('2d') || undefined;
    }
    this.draw();
  };

  private get canZoomIn() {
    const { zoomIndex } = this._canvasState;
    return zoomIndex < zoomScale.length - 1;
  };

  private get canZoomOut() {
    const { zoomIndex } = this._canvasState;
    return zoomIndex > 0;
  };

  private draw = () => {
    const ctx = this._ctx;
   
    if (ctx) {
      const { height, width } = this.props;
      const { overlayDrag, videoWidth, videoHeight } = this.state;
      const { offsetX, offsetY, mousePosition, zoomIndex } = this._canvasState;
      
      const style = {
        ...defaultStyle,
        ...this.props.style
      };
     
      // Clear canvas
      ctx.save();
      ctx.clearRect(0, 0, width, height);
     
      // Set base transformations
      const scale = zoomScale[zoomIndex];
      ctx.scale(scale, scale);
      ctx.translate((width / scale - videoWidth) / 2, (height / scale - videoHeight) / 2);
      ctx.translate(offsetX, offsetY);
      // Draw background video
      this._videoDrawer.draw(ctx);
     
      // So the previews canvas was getting the values form the overlay in scene but the correct way is getting directly from the template this way we can change scenes and also update the overlays on the scene
      if(this.props.overlaysInScene.length > 0) {       
        if(this.props.template.scenes[this.props.currentScene] &&  this.props.template.scenes[this.props.currentScene].overlays && (this.props.template.scenes[this.props.currentScene].overlays.length > 0)) {
          const trackers = (overlayDrag ? this.state.localTrackers : this.props.trackers);
            trackers.forEach((tracker) => {
              if((this.props.overlaysInScene[tracker.index] !== undefined) &&
                (this.props.overlaysInScene[tracker.index].startPoint<= this._videoDrawer.currentTime)
                 && (this.props.overlaysInScene[tracker.index].endPoint >= this._videoDrawer.currentTime)
              ){
                if(this.props.overlaysInScene.length === this.props.template.scenes[this.props.currentScene].overlays.length){
               if(this.props.overlaysInScene[tracker.index].visible){
                  this._overlayDrawer.draw(ctx, {                      
                    mousePosition,
                    style: style || defaultStyle,
                    tracker,
                    videoWidth,
                    videoHeight
                  });
                }
              }
              }
            }); 
        }
      }

      ctx.restore();
    }
  };

  private mouseToFramePosition = (mousePosition: Point): Point => {
    const { width, height } = this.props;
    const { videoWidth, videoHeight } = this.state;
    const { offsetX, offsetY, zoomIndex } = this._canvasState;
    const scale = zoomScale[zoomIndex];

    return {
      x: mousePosition.x / scale - (width / scale - videoWidth) / 2 - offsetX,
      y: mousePosition.y / scale - (height / scale - videoHeight) / 2 - offsetY
    };
  };

  private calcBoundaries = () => {
    const { videoWidth, videoHeight, } = this.state;
    const { zoomIndex } = this._canvasState;
    const { width, height } = this.props;
    const scale = zoomScale[zoomIndex];

    const boundaryX = (width / scale + videoWidth) / 2 - 32;
    const boundaryY = (height / scale + videoHeight) / 2 - 32;

    return { boundaryX, boundaryY };
  };

  private setCanvasState = (callback: (prevState: ICanvasState) => Partial<ICanvasState>) => {
    this._canvasState = {
      ...this._canvasState,
      ...callback(this._canvasState)
    };
  };

  private handleFrameUpdate = (frame: number) => {
    this.setCanvasState(() => ({ currentFrame: frame }));
    this.draw();
   
  };

  private handleResolutionChange = (videoWidth: number, videoHeight: number) => {
    this.setState(() => ({ videoWidth, videoHeight }));
    this.draw();
    
  };

  private handleMouseDown = (event: React.MouseEvent<HTMLCanvasElement>) => {
    const { videoHeight, videoWidth } = this.state;
    const ctx = this._ctx;
    event.preventDefault();

    if (this._canvas.current) {
      const relativeCoords = getRelativeCoordinates(event.nativeEvent, this._canvas.current)
      const framePos = this.mouseToFramePosition(relativeCoords);

      if (event.button === 0) {
        if (ctx) {
          const selection = this.props.trackers.map((tracker, trackerId) => {
              const { isHovered, cornerId } = this._overlayDrawer.isHovered(ctx, {
                
                mousePosition: framePos,
                tracker,
                videoHeight,
                videoWidth
              });
              if((this.props.overlaysInScene[trackerId].startPoint <= this._videoDrawer.currentTime)  && (this.props.overlaysInScene[trackerId].endPoint >= this._videoDrawer.currentTime))
              {
                if(this.props.overlaysInScene[trackerId].visible){
                  return { isHovered, trackerId, cornerId };
                }else{
                  return { isHovered: false, trackerId, cornerId };
                }
              //}
              }else{
                return { isHovered: false, trackerId, cornerId };
              }
            }).find((tracker) => tracker.isHovered );


          if (selection) {
            if(
              (this.props.overlaysInScene[selection.trackerId].startPoint <= this._videoDrawer.currentTime) 
              && (this.props.overlaysInScene[selection.trackerId].endPoint >= this._videoDrawer.currentTime)
            ){
              if(this.props.overlaysInScene[selection.trackerId].visible){
                this.setState(() => ({
                  overlayDrag: true,
                  selection: {
                    trackerId: selection.trackerId,
                    cornerId: selection.cornerId
                  },
                  localTrackers: this.props.trackers.slice()
                }));
              }
            }
          }
        }

        this.setCanvasState(() => ({ mousePreviousPosition: framePos, }));
      }

      if (event.button === 1) {
        this.setCanvasState(() => ({ mousePreviousPosition: framePos, }));

        this.setState(() => ({ canvasDrag: true }));
      }
    }
  };

  private handleMouseUp = (event: React.MouseEvent<HTMLCanvasElement>) => {
    if (this.state.selection) {
      const { trackerId } = this.state.selection;
      this.props.handleUpdateTracker({
        currentScene: this.props.currentScene,
        overlayId: trackerId,
        points: this.state.localTrackers[trackerId].points
      });
    }

    this.setState(() => ({
      canvasDrag: false,
      overlayDrag: false,
      selection: undefined
    }));
  };

  private handleMouseWheel = (event: React.WheelEvent) => {
    const delta = Math.sign(event.deltaY);

    if (((delta < 0 && this.canZoomIn) || (delta > 0 && this.canZoomOut)) && this._canvas.current) {
      const { offsetX, offsetY, zoomIndex } = this._canvasState;

      const newZoomIndex = zoomIndex - delta;

      const relativeCoords = getRelativeCoordinates(event.nativeEvent, this._canvas.current);
      const framePos = this.mouseToFramePosition(relativeCoords);

      this.setCanvasState(() => ({
        zoomIndex: newZoomIndex
      }));

      const { boundaryX, boundaryY } = this.calcBoundaries();

      const newFramePos = this.mouseToFramePosition(relativeCoords);

      const deltaX = framePos.x - newFramePos.x;
      const deltaY = framePos.y - newFramePos.y;

      const newOffsetX = Math.max(-boundaryX, Math.min(offsetX - deltaX, boundaryX));
      const newOffsetY = Math.max(-boundaryY, Math.min(offsetY - deltaY, boundaryY));

      this.setCanvasState(() => ({
        boundaryX,
        boundaryY,
        offsetX: newOffsetX,
        offsetY: newOffsetY
      }))
    }
    
    this.draw();
   
  };

  private handleMouseMove = (event: React.MouseEvent<HTMLCanvasElement>) => {
    const { canvasDrag, overlayDrag, videoWidth, videoHeight } = this.state;

    if ((canvasDrag || overlayDrag) && this._canvas.current) {
      const { selection } = this.state;
      const { mousePreviousPosition } = this._canvasState;
      const relativeCoords = getRelativeCoordinates(event.nativeEvent, this._canvas.current)
      const framePos = this.mouseToFramePosition(relativeCoords);

      if (canvasDrag) {
        const { boundaryX, boundaryY, offsetX, offsetY } = this._canvasState;

        const deltaX = framePos.x - mousePreviousPosition.x;
        const deltaY = framePos.y - mousePreviousPosition.y;

        const newOffsetX = Math.max(-boundaryX, Math.min(offsetX + deltaX, boundaryX));
        const newOffsetY = Math.max(-boundaryY, Math.min(offsetY + deltaY, boundaryY));

        const framePosAfterTranslate = {
          x: framePos.x - newOffsetX + offsetX,
          y: framePos.y - newOffsetY + offsetY,
        };

        this.setCanvasState(() => ({
          mousePreviousPosition: framePosAfterTranslate,
          offsetX: newOffsetX,
          offsetY: newOffsetY
        }));
      }

      if (overlayDrag && selection) {
        const { cornerId, trackerId } = selection;

        const mousePosition = {
          x: (mousePreviousPosition.x - framePos.x) / videoWidth,
          y: (mousePreviousPosition.y - framePos.y) / videoHeight,
        };

        const tracker = this.state.localTrackers[trackerId];
        const localTrackers = [...this.state.localTrackers];

        // Corner selected
        if (cornerId !== undefined && cornerId > -1) {

          if (tracker.type === TrackerType.ScaleAndTranslate) {
            // Top left corner : by carlos
            if(cornerId === 0){
              localTrackers[trackerId].points[1] = {
                x: tracker.points[1].x,
                y: tracker.points[1].y - mousePosition.y
              };
 
              localTrackers[trackerId].points[3] = {
                x: tracker.points[3].x - mousePosition.x,
                y: tracker.points[3].y,
              };
            }
            // Top right corner
            if (cornerId === 1) {
              
              localTrackers[trackerId].points[0] = {
                x: tracker.points[0].x,
                y: tracker.points[0].y - mousePosition.y
              };
 
              localTrackers[trackerId].points[2] = {
                x: tracker.points[2].x - mousePosition.x,
                y: tracker.points[2].y,
              };
            }
            //Bottom right corner : by Carlos
            if (cornerId === 2) {
              
              localTrackers[trackerId].points[1] = {
                x: tracker.points[1].x - mousePosition.x,
                y: tracker.points[1].y
              };
 
              localTrackers[trackerId].points[3] = {
                x: tracker.points[3].x,
                y: tracker.points[3].y - mousePosition.y,
              };
            }
            // Bottom left corner
            if (cornerId === 3) {
              localTrackers[trackerId].points[0] = {
                x: tracker.points[0].x - mousePosition.x,
                y: tracker.points[0].y
              };
 
              localTrackers[trackerId].points[2] = {
                x: tracker.points[2].x,
                y: tracker.points[2].y - mousePosition.y,
              };
            }
          }


          // Any other corner
          localTrackers[trackerId].points[cornerId] = {
            x: tracker.points[cornerId].x - mousePosition.x,
            y: tracker.points[cornerId].y - mousePosition.y
          };
        } else {
          localTrackers[trackerId] = {
            ...tracker,
            points: tracker.points.map((point) => ({
              x: point.x - mousePosition.x,
              y: point.y - mousePosition.y
            }))
          };
        }

        this.setCanvasState(() => ({
          mousePreviousPosition: framePos
        }));

        this.setState(() => ({ localTrackers }));
      }
    }


    if (this._canvas.current) {
      const relativeCoords = getRelativeCoordinates(event.nativeEvent, this._canvas.current);

      this.setCanvasState(() => ({ mousePosition: relativeCoords }));
    }

    this.draw();
    
  };

  private handleMouseLeave = (event: React.MouseEvent<HTMLCanvasElement>) => {
    this.handleMouseUp(event);
  };

  private handleContextMenu = (event: React.MouseEvent<HTMLCanvasElement>) => {
    event.preventDefault();

    return false;
  };

  private handleSelectStart = (event: React.MouseEvent<HTMLCanvasElement>) => {
    event.preventDefault();

    return false;
  };

  public render = () => {
    const { canvasDrag, overlayDrag } = this.state;
    const { classes, height, width } = this.props;

    const dragging = (canvasDrag || overlayDrag) ? classes.dragging : '';

    return (
      <canvas 
        className={`${classes.canvas} ${dragging}`}
        height={height}
        onContextMenu={this.handleContextMenu}
        onMouseDown={this.handleMouseDown}
        onMouseUp={this.handleMouseUp}
        onMouseMove={this.handleMouseMove}
        onMouseLeave={this.handleMouseLeave}
        onWheel={this.handleMouseWheel}
        onSelect={this.handleSelectStart}
        ref={this._canvas}
        width={width}
      ></canvas>
    );
  };
};


export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(PreviewCanvas));